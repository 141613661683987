.top-control-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: calc(100% - 15px);
    padding: 0 3rem;
    position: fixed;
    top: 106px;
    background-color: #fff;
    z-index: 99;
}

.top-control-panel {
    margin: 16px 0;
    padding: 12px 0;
    display: flex;
    flex-direction: row;

    .reset-panel, .hide-drawer {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;

        img {
            margin-right: .5rem;
        }
    }

    .reset-panel {
        margin-left: 3rem;
        color: black;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        word-wrap: break-word
    }

    .hide-drawer {
        margin-left: 1.5rem;
        color: black;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        word-wrap: break-word
    }

    .filter-panel {
        position: relative;
        display: flex;
        align-items: center;
        .filter-title {
            color: #787878;
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            word-wrap: break-word
        }
        span {
            padding-left: 1px;
            height: fit-content !important;
        }

        .filter-quantity {
            position: absolute;
            top: 4px;
            left: 3.1rem;
            color: #fff;
            width: 22px;
            height: 22px;
            border-radius: 11px;
            background-color: #000;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.table-list-header {
    margin: 16px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: calc(92vw - 448px);

    &.align-end {
        justify-content: flex-end;
    }

    &.align-around {
        justify-content: space-between;
    }

    .dropdown-panel {
        position: relative;

        label {
            position: absolute;
            top: 16px;
            left: 18px;
            height: 0;
            color: #a9a9a9;
            transform-origin: 0rem 1rem;
            transition: transform 0.2s ease-in-out;
            pointer-events: none;
        }

        .sort-by-label.selected {
            transform: translateY(-1em) scale(0.7);
        }
    }

    .p-dropdown {
        margin: 0 !important;
        height: 56px;
        width: 280px;
        padding: 0 18px;

        .p-dropdown-label {
            padding-left: 0;
            padding-right: 0;
            padding-top: 1.5rem;
        }

        .p-dropdown-trigger {
            justify-content: flex-end;
        }
    }

    .back-to-configure {
        cursor: pointer;

        img {
            margin-right: .5rem;
        }

        span {
            color: black;
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            word-wrap: break-word
        }
    }

    .list-category {
    }
}

.inventory-container {
    display: flex;
    flex-direction: row;
    padding: 7rem 3rem 3rem 3rem;
    position: relative;
    .inventory-variant-btn-panel {
        display: flex;
        flex-direction: column;
        /*margin-bottom: 1.5rem;*/
        width: 100%;

        .inventory-variant-btn {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            box-shadow: none;
            background-color: transparent;
            border: 2px solid #E9ECEF;
            border-radius: 4px !important;
            color: #282828;
            margin-top: 1.5rem;
            height: 3.375rem;
            line-height: 1.375rem;
            min-width: 6rem;
            cursor: pointer;
            transition: all .25s linear;
            min-height: 3rem;
            max-height: 5rem;
            // text-transform: uppercase;
            letter-spacing: 1px;
            font-size: 1rem;
            font-weight: 400;

            span {
                color: #707070;
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0.50px;
                word-wrap: break-word
            }

            h6 {
                margin: 0;
                color: black;
                font-size: 16px;
                font-weight: 400;
                text-transform: uppercase;
                line-height: 24px;
                letter-spacing: 1px;
                word-wrap: break-word;
            }
        }

        .inventory-variant-btn.selected {
            border: 2px solid #282828;
            color: #282828;
        }
    }


    .inventory-drawer.show, .inventory-drawer {
        transition: width 0.3s ease-in-out;

        .main-drawer {
            overflow-y: scroll;
            height: 75vh;

            .variant-section, .exterior-section, .interior-section {
                padding-bottom: 36px;

                .section-title {
                    line-height: 22px;
                    letter-spacing: 1px;
                    padding-bottom: 1.5rem;

                    h6 {
                        margin: 0;
                    }
                }

                .accordion-item {
                    width: 350px;
                    border: none;

                    .accordion-button {
                        padding: 0;
                        font-weight: 400;
                    }
                }
            }

            .variant-section {
                .checkbox-panel {
                    margin: 0;
                    display: flex;
                    flex-direction: column;

                    .checkbox-item {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        padding-bottom: .5rem;

                        .p-checkbox {
                            margin-right: .5rem;

                            .p-checkbox-box {
                                border: 2px solid #282828;
                            }
                        }
                    }

                    button {
                        width: 300px;
                        margin-top: 0;
                        margin-bottom: 1rem;

                        &:nth-child(2) {
                            margin: 0;
                        }
                    }
                }
            }

            .options-container {
                display: flex;
                flex-direction: column;
                width: auto;
                padding: 0;

                .color-options-item {
                    gap: 1rem;
                    padding-bottom: .5rem;
                    width: fit-content;

                    &:last-child {
                        padding-bottom: 0;
                    }
                }

                .color-options-round {
                    height: 3rem;
                    width: 3rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img {
                        height: 2.7rem;
                        width: 2.7rem;
                    }
                }

                .options-content {
                    font-size: 14px !important;
                    line-height: 18px;
                    letter-spacing: 1px;
                }
            }
        }
    }

    .inventory-drawer {
        width: 400px;
        position: fixed;
        top: calc(7rem + 106px);
        left: 3rem;
    }

    .inventory-drawer.close {
        // width: 90px;
        width: 0; //Hide the main drawer when close
        .top-control-panel {
            margin: 0;
            display: flex;
            flex-direction: column-reverse;

            .filter-panel, .reset-panel, .hide-drawer {
                margin-left: 0;
                margin-bottom: .5rem;

                img {
                    cursor: pointer;
                }
            }

            .reset-panel, .hide-drawer {
                img {
                    width: 3rem;
                    height: 3rem;
                }
            }

            .filter-panel {
                display: flex;
                align-items: center;
                padding-left: .3rem;
                padding-top: .1rem;

                img {
                    width: 2.6rem;
                    height: 2.6rem;
                }
            }

            .hide-drawer {
                img {
                    rotate: 180deg;
                }
            }
        }

        .main-drawer {
            .variant-section, .exterior-section, .interior-section {
                .section-title {
                    font-size: 12px !important;
                    padding-bottom: 10px !important;
                }

                .accordion-item {
                    width: 90px;
                    border: none;

                    .accordion-button {
                        padding: 0;
                        font-weight: 400;
                        font-size: 12px;

                        &:not(.collapsed),
                        &:not(.collapsed)::after,
                        &::after {
                            background-image: unset !important;
                        }
                    }
                }
            }

            .variant-section {
                .variant-btn-panel {
                    margin: 0;

                    button {
                        width: 3.2rem;
                        height: 3.2rem;
                        margin-top: 0;
                        margin-bottom: .7rem;
                        padding: 0;
                        min-width: 3.2rem !important;
                        border-radius: 1.75rem !important;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        span {
                            padding-top: 2px;
                            font-size: 8px;
                        }

                        &:nth-child(2) {
                            margin: 0;
                        }
                    }
                }
            }

            .variant-section, .exterior-section, .interior-section {
                padding-bottom: 24px;

                .section-title {
                    font-size: 14px;
                    padding-bottom: 1rem;
                    font-family: "CadillacGothic-NarrowRegular";
                }
            }

            .options-container {
                // display: flex;
                // justify-content: center;
                // align-items: center;
                .options-content {
                    display: none !important;
                }
            }
        }
    }

    .inventory-list-container {
        // flex: 1;
        transition: width 0.3s ease-in-out;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        position: absolute;
        right: 0;
        height: 75vh;
        // overflow-y: scroll;
        width: calc(100% - 450px) !important;
        .pre-loader {
            align-self: center;
        }
        .inventory-table-list.row {
            // width: calc(100% + 16px);
            text-align: center;
            width: 100%;
            // margin: 0 auto;
            padding: 0rem 2rem;
            border-radius: 4px !important;
            // border: 1px solid #E9ECEF;
            justify-content: flex-start;
            /*align-items: center;*/  //remove this as NOT Found need to be shown on the top
            /*gap: 24px;*/
            display: inline-flex;

            .notify-me-wrapper {
                width: 100%;

                h2 {
                    color: black;
                    font-size: 24px;
                    font-weight: 400;
                    text-transform: uppercase;
                    line-height: 32px;
                    letter-spacing: 3px;
                    word-wrap: break-word
                }

                .save-dialog-btn {
                    .variant-btn-panel {
                        margin: 0;
                        
                    }
                }
            }

            .filter-reset-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: start;
                align-items: start;

                h1 {
                    font-size: 30px;
                    margin-bottom: 30px;
                }

                p {
                    margin-bottom: 10px;
                }

                .selected-filters-wrapper {
                    display: flex;
                    flex-direction: row;
                }

                .selected-filter-item {
                    cursor: pointer;
                    border: 1px solid #E9ECEF;
                    padding: 8px 10px;
                    margin-right: 10px;


                    .icon {
                        margin-left: 8px;
                    }
                }

                .link-remove-all {
                    margin-top: 10px;
                    cursor: pointer;

                    .icon {
                        margin-right: 8px;
                    }
                }
            }


            .inventory-card {
                padding: 0 16px 32px 16px;
                border-radius: 2px;

                .inventory-card-inner {
                    padding: 1.5rem;
                    border: 1px #E9ECEF solid;
                }

                .inventory-header {
                    display: flex;
                    flex-direction: column;

                    .name-type, .price-payment {
                        h6 {
                            color: black;
                            font-size: 20px;
                            font-weight: 400;
                            text-transform: uppercase;
                            line-height: 32px;
                            letter-spacing: 2px;
                            word-wrap: break-word;

                            &:nth-child(2) {
                                font-family: 'CadillacGothic-NarrowRegular';
                            }
                        }

                        span {
                            color: #707070;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 18px;
                            letter-spacing: 0.50px;
                            word-wrap: break-word
                        }
                    }

                    .name-type {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                        padding-top: 2px;
                    }

                    .price-payment {
                        display: flex;
                        flex-direction: row;
                        align-items: flex-start;

                        .inner-panel {
                            width: 100%;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: space-between;

                            .price-icon-panel {
                                display: flex;
                                flex-direction: row;
                                align-items: center;

                                img {
                                    margin-bottom: .2rem;
                                }
                            }
                        }

                        img {
                            margin-left: 4px;
                            width: 18px;
                            height: 18px;
                        }
                    }
                }

                .carousel-panel {
                    aspect-ratio: 1600/900;
                    margin: 1.5rem 0;

                    .carousel {
                        height: 100%;
                        cursor: pointer;

                        .carousel-inner {
                            height: 100%;

                            .carousel-item {
                                height: 100%;
                                position: relative;

                                img {
                                    height: 100%;
                                    position: absolute;
                                    width: 100%;
                                    left: 50%;
                                    transform: translateX(-50%);
                                }
                            }
                        }

                        .carousel-control-prev, .carousel-control-next {
                            width: auto;
                        }

                        .carousel-indicators {
                            display: none;
                        }

                        .button-prev, .button-next {
                            width: 24px;
                            height: 24px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 12px;
                            background-color: #fff;

                            img {
                                width: 16px;
                                height: 16px;
                            }
                        }

                        .button-prev {
                            margin-left: 8px;
                        }

                        .button-next {
                            justify-content: flex-end;
                            margin-right: 8px;
                        }
                    }
                }

                .vehicle-image-panel {
                    img {
                        width: 100%;
                    }
                }

                .color-panel {
                    span {
                        color: #707070;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 18px;
                        letter-spacing: 0.50px;
                        word-wrap: break-word
                    }

                    .exterior-box, .interior-box {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        padding-bottom: 1.5rem;
                    }

                    .color-options-item {
                        grid-gap: .5rem !important;
                        gap: .5rem !important;

                        .color-options-round {
                            width: 1.8rem;
                            height: 1.8rem;

                            img {
                                width: 1.5rem;
                                height: 1.5rem;
                            }
                        }

                        .options-content {
                            color: black;
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 22px;
                            word-wrap: break-word
                        }
                    }
                }

                .est-delivery-time {
                    border-top: 1px #D2D2D2 solid;
                    color: #505050;
                    font-size: 12px;
                    line-height: 22px;
                    letter-spacing: 1px;
                    text-align: start;
                    padding: 1.5rem 0;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    .specifications-box {
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                    }

                    p {
                        margin: 0;
                        color: #1E7A2B;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 22px;
                        word-wrap: break-word
                    }

                    span {
                        color: black;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 22px;
                        word-wrap: break-word
                    }

                    img {
                        width: 18px;
                        height: 18px;
                        margin-left: 4px;
                    }
                }

                .variant-btn-panel {
                    margin-bottom: 0;

                    .variant-btn {
                        border: 2px #282828 solid;
                        display: flex;
                        align-content: center;
                        justify-content: center;
                        padding: 1rem 1.5rem;
                        margin: 0;

                        h6 {
                            margin: 0;
                            color: black;
                            font-size: 14px;
                            font-weight: 400;
                            text-transform: uppercase;
                            line-height: 22px;
                            letter-spacing: 2px;
                            word-wrap: break-word
                        }
                    }
                }
                // &:nth-child(2n) {
                //     padding-right: 0;
                // }
                // &:nth-child(2n+1) {
                //     padding-left: 0;
                // }
            }
            // .inventory-card.full {
            //     &:nth-child(3n) {
            //         padding-right: 0;
            //     }
            //     &:nth-child(3n+1) {
            //         padding-left: 0;
            //     }
            // }
        }

        .inventory-join-waitlist-panel {
            padding: 1rem 3rem 2rem 2rem;
            .inventory-join-waitlist-card {
                padding: 0 3rem 0 2rem;
                width: 100%; 
                height: 100%; 
                padding-left: 24px; 
                padding-right: 24px; 
                padding-top: 36px; 
                padding-bottom: 36px; 
                background: white; 
                border-radius: 4px; 
                border: 1px #E9ECEF solid; 
                justify-content: flex-start; 
                align-items: center; 
                gap: 24px; 
                display: inline-flex;
                .join-waitlist-context {
                    color: black;
                    font-size: 24px;
                    font-weight: 400;
                    font-family: 'CadillacGothic-Regular';
                    text-transform: uppercase;
                    line-height: 32px;
                    letter-spacing: 3px;
                    word-wrap: break-word
                }
                
                .save-dialog-btn {
                    width: 450px;
                    .variant-btn-panel {
                        border-radius: 4px;
                        max-width: 350px;
                        margin: 0;
                        .variant-btn {
                            color: white;
                            background-color: #000;
                            font-size: 14px;
                            font-weight: 400;
                            font-family: 'CadillacGothic-Regular';
                            text-transform: uppercase;
                            line-height: 22px;
                            letter-spacing: 2px;
                            word-wrap: break-word;
                            border-radius: 4px;
                            border: none;
                            display: flex;
                            justify-content: center;
                        }
                    }
                }
            }
        }
    }

    .inventory-list-container.expand {
        width: 100% !important;
    }
}

.bottom-unavailable-panel {
    // padding: 0 10% 1.5rem 10%;

    .no-storage-panel {
        position: relative;
        padding: 0 0 12px 0;
        border-bottom: 2px solid #282828;

        .or-panel {
            position: absolute;
            height: 1rem;
            bottom: -0.5rem;
            background-color: #fff;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            width: 3rem;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.inventory-unavailable-reminder-container {
    margin-top: 3rem;

    .inventory-unavailable-reminder {
        width: 100%;
        background-color: #282828;
        padding: 1.5rem 2rem;
        border-radius: 4px;

        p, h5 {
            margin-bottom: 0;
        }

        p {
            color: white;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0.50px;
            word-wrap: break-word
        }

        .search-inventory {
            margin-top: 1rem;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            cursor: pointer;

            h5 {
                color: white;
                font-size: 14px;
                font-weight: 700;
                line-height: 18px;
                letter-spacing: 0.50px;
                word-wrap: break-word
            }

            img {
                margin-left: 1rem;
                height: 1rem;
            }
        }
    }
}


.notify-dialog {
    border-radius: 0%;
    max-height: 100vh !important;
    margin: 0 !important;
    width: 500px;
    height: 100%;

    .p-dialog-content {
        padding-left: 7%;
        padding-right: 7%;

        &::-webkit-scrollbar {
            display: none;
        }

        .title-container {
            margin-bottom: 2rem;
            border-bottom: 1px solid #ddd;
            text-align: center;
            padding-bottom: 1rem;

            h1 {
                width: fit-content;
                margin: 0 auto;
            }

            span {
                padding-top: 1rem;
            }
        }

        .input-item {
            width: 100%;
            margin-bottom: 1.5rem;

            .wrapper-box {
                input {
                    height: 3.8rem;
                }
            }
        }

        .global-location-dropdown {
            margin-top: 0;
            margin-bottom: 1.5rem;
        }

        .btn-panel {
            display: flex;
            align-items: center;
            justify-content: center;

            button {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                box-shadow: none;
                background-color: transparent;
                border: 1px solid #000;
                color: #fff;
                background-color: #282828;
                margin-top: 1rem;
                line-height: 1.375rem;
                min-width: 20rem;
                cursor: pointer;
                transition: all .25s linear;
                height: 3.2rem;
                // max-height: 5rem;
                // text-transform: uppercase;
                letter-spacing: 1px;
                font-size: 1rem;
                font-weight: 400;
                padding-left: 16px;
                padding-right: 16px;

                &:hover {
                    border: 1px solid #282828;
                    background-color: #fff;
                    color: #282828;
                }
            }
        }
    }
}

@media screen and (min-width: 1024.1px) and (max-width: 1280px) {
    .inventory-container {
        .inventory-drawer {
            position: fixed;
            top: calc(7rem + 106px);
            left: 1.5rem;
            .inventory-variant-btn-panel {
                button {
                    max-width: 220px;
                }
            }

            .exterior-section.accordion, .interior-section.accordion {
               
                .accordion-item {
                    width: 240px !important;
                }
                
            }
        }

        .inventory-list-container {
            margin: 0 !important;

            .inventory-table-list.row {
            }
            .inventory-card {
                flex-basis: 49%;
            } 
            .inventory-card.full {
                flex-basis: 33%;
            }
            .inventory-card, .inventory-card.full {
                padding-left: 8px !important;
                padding-right: 8px !important;
                .inventory-card-inner {
                    padding: 1rem !important;
                    .inventory-header {
                        .name-type {
                            display: flex;
                            flex-direction: column !important;
                            padding-bottom: .5rem;
                            h6 {
                                line-height: 1.5rem;
                                &:nth-child(1) {
                                    margin: 0;
                                }
                                
                            }
                        }
                    } 
                    .color-panel {
                        .exterior-box, .interior-box {
                            padding-bottom: 1rem;
                        }
                    }
                }
            }
        }
    }
   

}


@media screen and (min-width: 768px) and (max-width: 1024px) {
    .top-control-container {
        top: 130px
    }

    .inventory-container {
        .inventory-drawer {
            width: 260px;
            position: fixed;
            top: calc(7rem + 106px);
            left: 1.5rem;
            .inventory-variant-btn-panel {
                button {
                    max-width: 220px;
                }
            }

            .exterior-section.accordion, .interior-section.accordion {
               
                .accordion-item {
                    width: 240px !important;
                }
                
            }
        }

        .inventory-list-container {
            margin: 0 !important;
            width: calc(100% - 260px) !important;

            .inventory-table-list.row {
                padding-right: 0;
            }
            .inventory-card {
                flex-basis: 49%;
            } 
            .inventory-card.full {
                flex-basis: 33%;
            }
            .inventory-card, .inventory-card.full {
                padding-left: 8px !important;
                padding-right: 8px !important;
                .inventory-card-inner {
                    padding: 1rem !important;
                    .inventory-header {
                        .name-type {
                            display: flex;
                            flex-direction: column !important;
                            padding-bottom: .5rem;
                            h6 {
                                line-height: 1.5rem;
                                &:nth-child(1) {
                                    margin: 0;
                                }
                                
                            }
                        }
                    } 
                    .color-panel {
                        .exterior-box, .interior-box {
                            padding-bottom: 1rem;
                        }
                    }
                }
            }

            .inventory-join-waitlist-panel {
                padding: 2rem ;
                .inventory-join-waitlist-card {
                    display: flex;
                    flex-direction: column;
                    .join-waitlist-context {
      
                    }
                    
                    .save-dialog-btn {
                        width: auto;
                        .variant-btn-panel {
                           
                            .variant-btn {
                                
                            }
                        }
                    }
                }
            }
        }
    }
   

}


@media screen and (max-width: 767.98px) {
    .filter-dialog {
        margin: 0 !important;
        width: 100%;
        height: 100vh;
        .p-dialog-header {
            display: none;
        }
        .p-dialog-content {
            .top-control-panel {
                display: flex;
                flex-direction: column;
            }
            .top-control-container {
                position: relative;
                top: 0;
                display: flex;
                flex-direction: column;
                padding: 0;
                width: 100% !important;
                .top-control-panel {
                    margin: 0;
                    padding-bottom: 0;
                    .reset-panel {
                        margin: 0;
                    }
                    .filter-panel {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        margin: 1rem 0;
                        .filter-quantity {
                            top: 0px;
                        }
                        img {
                            width: 24px;
                            height: 24px;
                        }
                    }
                    .reset-panel {
                        padding: .5rem 0;
                    }
                }
                .table-list-header {
                    width: 100%;
                   
                    .dropdown-panel {
                        width: 100%;
                        .list-category {
                            width: 100%;
                        }
                    }
                }
            }

            
            .inventory-drawer {
                position: relative;
                margin: 0;
                top: 0;
                left: 0;
                width: 100%;
                height: fit-content;
                .main-drawer {
                    overflow: hidden;
                    height: fit-content;
                    .options-container {
                        width: 100%;
                        .color-options-item {
                            // width: 95%;
                        }
                    }

                    .variant-section, .exterior-section {
                        padding-bottom: 3rem;
                    }

                    .interior-section {
                        padding-bottom: 1rem;
                    }
    
                    .variant-section, .exterior-section, .interior-section {
                        
        
                        .section-title {
                            color: #282828;
                            font-size: 16px;
                            font-weight: 400;
                            font-family: 'CadillacGothic-Regular';
                            text-transform: uppercase;
                            line-height: 24px;
                            letter-spacing: 2px;
                            word-wrap: break-word;
                            padding-bottom: 1.5rem;
                        }
                    }

                    .variant-section {
                        .checkbox-panel {
                            .inventory-variant-btn-panel {
                                &:nth-child(1) {
                                    padding-bottom: 10px;
                                }
                                label {
                                    color: black;
                                    font-size: 16px;
                                    font-weight: 400;
                                    line-height: 22px;
                                    word-wrap: break-word
                                }
                            }
                        }
                    }

                    .variant-btn-panel {
                        .variant-btn {
                            margin-top: 1.5rem;
                            background-color: #282828;
                            font-size: 14px;
                            font-weight: 400;
                            text-transform: uppercase;
                            line-height: 22px;
                            letter-spacing: 2px;
                            word-wrap: break-word;
                            display: flex;
                            justify-content: center;
                            h6 {
                                color: white;
                                font-size: 14px;
                                font-family: 'CadillacGothic-Regular';
                                font-weight: 400;
                                text-transform: uppercase;
                                line-height: 22px;
                                letter-spacing: 2px;
                                word-wrap: break-word
                            }
                        }
                    }
                }
            }

            
        }
    }

    .inventory-container {
        padding: 0;
      
        display: flex;
        flex-direction: column;
        position: relative;
        .inventory-list-container {
            // flex: 1;
            margin: 0 !important;
            top: 50px;
            width: 100% !important;
            // display: flex;
            // flex-direction: column;
            .inventory-table-list.row {
                padding: 0 .5rem 1rem 2rem;
                display: flex;
                justify-content: center;
                width: 100%;
                .filter-reset-wrapper {
                    width: 100%;
                    padding: 0;
    
                    h1 {
        
                    }
    
                    p {

                    }
    
                    .selected-filters-wrapper {
           
                    }
    
                    .selected-filter-item {

    
    
                        .icon {
                    
                        }
                    }
    
                    .link-remove-all {
                       
    
                        .icon {
                            
                        }
                    }
                }
            }

            .inventory-join-waitlist-panel {
                padding: 1rem 1rem 6rem 1rem;
                .inventory-join-waitlist-card {
                    display: flex;
                    flex-direction: column;
                    .join-waitlist-context {
      
                    }
                    
                    .save-dialog-btn {
                        width: auto;
                        .variant-btn-panel {
                           
                            .variant-btn {
                                
                            }
                        }
                    }
                }
            }
        }
        .filter-to-exapnd-container {
            display: flex;
            flex-direction: row;
            align-content: center;
            justify-content: end;
            position: sticky;
            background-color: #fff;
            top: 106px;
            width: 100%;
            height: 48px;
            z-index: 99;
            .filter-to-exapnd-box {
                position: relative;
                display: flex;
                align-items: center;
                padding-right: 1rem;
                img {
                    width: 36px;
                    height: 36px;
                }
                .filter-quantity {
                    position: absolute;
                    top: 0;
                    right: .5rem;
                    color: #fff;
                    width: 22px;
                    height: 22px;
                    border-radius: 11px;
                    background-color: #000;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }
}