.container-fluid {
    padding: 0;
    .p-tabview-title {
        color: #282828;
    }
}

.header-container {

}
    
.homepage-container {
    margin: 0 auto;
}

.nav-menu {
    height: 106px;
	&:nth-child(1) {
		position: fixed;
		top: 0;
		width: calc(100vw - 15px);
		z-Index: 1000;
		overflow: hidden; 
        display: flex;
        align-items: center;
	}
}

.d2c-container {
  	position: relative;
    height: 100vh;
    // scroll-snap-type: y mandatory;
    // scroll-behavior: smooth;
    overflow-x: hidden;
    overflow-y: scroll;
	.left-slider-container {
        grid-column: 1;
        position: sticky;
        flex-basis: 70%;
        // position: sticky;
        // top: calc((100vh - 5rem)*.01 + 3.875rem);
        // top: 80px;
        // height: calc((100vh - 3.75rem)*.95);
        top: 186px;
        height: calc(100vh - 188px);
        // width: 70%;
        display: flex;
        flex-direction: column;
        margin: 0 .5rem 0 1rem;
        padding-right: .5rem;
        .carousel.slide {
            width: calc(100% - 1rem);
            height: 100%;
            align-self: center;
            background-color: #f6f6f4;
        }
        .close-panel {
            position: absolute;
            right: 60px;
            top: 30px;
            z-index: 99;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        img {
            width: 100%;
            cursor: pointer;
            // opacity: 1; /* Initial opacity */
            // transition: opacity 0.3s ease; 
        }
        .transition-group-slider {
            width: 100%;
            height: 100%;
            overflow: hidden;
            position: relative;
        }

        .transition-group-variant {
            width: 100%;
            height: 100%;
        }

        .carousel {
            width: 100%;
            height: 100%;
            overflow: hidden;
            .carousel-inner {
                width: 100%;
                height: 100%;
                .carousel-item, .carousel-item.active {
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    text-align: center;
                    position: relative;
                    img {
                        position: absolute;
                        width: 100%;
                        left: 0;
                        height: auto;
                        top: 50%;
                        transform: translateY(-50%);
                        // object-fit: cover;
                        // overflow: hidden;
                    }
                }
            }
            
            // .carousel-inner {
            //     width: 100%;
               
            //     right: 0;
            //     margin-left: auto;
            //     margin-right: auto;
            //     position: absolute;
            //     top: 50%;
            //     left: 50%;
            //     transform: translate(-50%, -50%);
            // }
            // .carousel-item {
            //     width: 100%;
            //     text-align: center;
            //     position: relative;
            //     img {
            //         width: 100%;
            //         height: 100%;
            //         object-fit: cover;
            //         object-position: center;
            //         margin: 0 auto;
        
            //     }
            // }
            .carousel-indicators {
                button {
                    background-color: #ccc;
                    height: 12px;
                    width: 12px;
                    border-radius: 50% !important;
                    margin: 0 1.125rem;
                }
                button.active {
                    background-color: #060606;
                }
            }
            .carousel-control-prev, .carousel-control-next {
                width: 10%;
            }
            .carousel-control-prev-icon, .carousel-control-next-icon {
                filter: invert(100%) hue-rotate(0deg) brightness(0.02);
                
            }
            .button-prev, .button-next {
                width: 40px;
                height: 44px;
                display: flex;
                align-items: center;
                &:hover {
                    // background-color: #fff;
                }
                img {
                    width: 2rem;
                    height: 2rem;
                }
            }
            .button-next {
                justify-content: flex-end;
            }
        }

        .counter-container {
            height: 100%;
            width: 100%;
            padding: 20%;
            display: flex;
            flex-direction: column;
            .counter-item {
                flex: 1 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                
                .counter-title {
                    color: var(--global-checkout-desc-font-color);
                    font-size: 16px;
                    font-family: 'CadillacGothic-Regular';
                }
                .counter-box {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    color: var(--global-exterior-interior-font-color);
                    .counter {
                        font-family: 'CadillacGothic-Regular';
                        font-size: 32px;
                        animation: count 1.5s linear forwards;
                    }
                    .suffix {
                        padding-left: .3rem;
                        font-size: 26px;
                    }
                }
            }
        }

        .top-panel {
            // flex-basis: var(--row-0-flex-basis);
            flex: 1 1 var(--row-0-flex-basis);
            height: var(--row-0-flex-basis);
            // max-height: var(--row-0-flex-basis);
            display: flex;
            flex-direction: row;
            align-items: center;
            transition: height .8s ease-in-out;
            // transition: all .5s cubic-bezier(0.18, 0.89, 0.32, 1.28);
            margin-bottom: .5rem;
            .cadillac-full-0 {
                flex: 1 1 var(--tile-0-flex-basis);
                max-width: var(--tile-0-flex-basis);
            }
            .cadillac-front-1 {
                flex: 1 1 var(--tile-1-flex-basis);
                max-width: var(--tile-1-flex-basis);
            }
        }
        .bottom-panel {
            // flex-basis: var(--row-1-flex-basis);
            flex: 1 1 var(--row-1-flex-basis);
            height: var(--row-1-flex-basis);
            // max-height: var(--row-1-flex-basis);
            display: flex;
            flex-direction: row;
            transition: height .8s ease-in-out;
            margin-top: .5rem;
            .cadillac-back-2 {
                flex: 1 1 var(--tile-2-flex-basis);
                max-width: var(--tile-2-flex-basis);
            }
            .cadillac-side-3 {
                flex: 1 1 var(--tile-3-flex-basis);
                max-width: var(--tile-3-flex-basis);
            }
        }
        .top-panel.collapse, .bottom-panel.expand {
          margin-top: 0;
          margin-bottom: 0;
        }
        .cadillac-full-0, .cadillac-front-1, .cadillac-back-2, .cadillac-side-3 {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            transition: all .8s ease-in-out;
            visibility: visible;
            margin: 0 0.5rem;
            background-color: #f6f6f4;
            overflow: hidden;
        }
        .expand {
            flex-basis: 100%;
            height: 100%;
        }
        .collapse {
            flex-basis: 0;
            height: 0;
        }
    }

	section {
		// scroll-snap-align: start;
		// scroll-snap-stop: always;
		padding-bottom: 160px;

        		// &:nth-child(2) {
		// 	// position: absolute;
		// 	width: 100%;
        //     height: 100%;
		// 	// height: 300vh;
        //     height: fit-content;
		// 	transition: opacity 0.4s ease-in-out;
		// 	opacity: 1;
		// 	.animate-item {
		// 		position: sticky;
		// 	}
		// }
		// height: 60vh;

		&:not(7) {
			padding-bottom: 200px;
		}
		&:last-child {
			min-height: 100vh;
		}
	}

    section.global {
        padding-top: 106px;
        padding-bottom: 180px;
    }
}

.auth-container{
    display: flex;
    justify-content:center;
    align-items:center;
    margin-top: 10%;
}

@media screen and (min-width: 1024.1px) and (max-width: 1300px) {
    .d2c-container {
        .left-slider-container {
            .counter-container {
                padding: 2rem;
                .counter-item {
                    .counter-title {
                        font-size: 14px !important;
                    }
    
                    .counter {
                        font-size: 20px !important;
                    }
    
                    .suffix {
                        font-size: 18px !important;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    // .d2c-container::-webkit-scrollbar {
    // 	display: none;
    // }
    // /* Hide scrollbar for IE, Edge and Firefox */
    // .d2c-container {
    // 	-ms-overflow-style: none;  /* IE and Edge */
    // 	scrollbar-width: none;     /* Firefox */
    // 	overflow: auto; /* Enables scrolling */
    // 	max-height: 100vh; /* Limit container height */
    // 	overflow-y: auto;  /* Enable vertical scrolling */
    // }
    // .d2c-container {
    // 	padding-right: 0;
    // }

    .d2c-container {
        .left-slider-container {
            margin-left: .5rem;
            top: 224px;
            height: calc(100vh - 224px);
        }
    }

    .nav-menu {
        height: 140px;
    }

    section.global {
        padding-top: 140px !important;
        padding-bottom: 260px;
    }

    .left-slider-container {
        .counter-container {
            .counter-item {
                .counter-title {
                    font-size: 10px !important;
                }

                .counter {
                    font-size: 16px !important;
                }

                .suffix {
                    font-size: 14px !important;
                }
            }
        }
    }
}

@media screen and (max-width: 767.98px) {
	.d2c-container::-webkit-scrollbar {
		display: none;
	}

    section.global {
        // padding-top: 140px !important;
        padding-bottom: 120px !important;
    }
	
	/* Hide scrollbar for IE, Edge and Firefox */
	.d2c-container {
		-ms-overflow-style: none;  /* IE and Edge */
		scrollbar-width: none;     /* Firefox */
		overflow: auto; /* Enables scrolling */
		max-height: 100vh; /* Limit container height */
		overflow-y: auto;  /* Enable vertical scrolling */
        padding-right: 0;
        section {
            // scroll-snap-align: start;
            // scroll-snap-stop: always;
            padding-bottom: 90px ;
        }
	}

	.nav-menu {
		&:nth-child(1) {
			width: 100vw;
		}
	}

    .mobile-variant-header-container {
        .carousel {
            .carousel-inner {
                background-color: #F2F2F2;
                .carousel-item, .carousel-item.active {
                    height: 26vh;
                    position: relative;
                    img {
                        width: 100%;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
            .carousel-indicators {
                display: none;
            }
        }
        .counter-container {
            display: flex;
            flex-direction: row;
            width: 100%; height: 100%;
            padding: 20px 24px; 
            background: #F2F2F2; 
            gap: 10px;
            margin: .5rem 0 1.5rem 0;
            .counter-item {
                flex-basis: 33.3%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .counter-title, .counter-box {
                    padding-left: .5rem;
                }
                .counter-title {
                    color: #282828;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 18px;
                    letter-spacing: 0.50px;
                    word-wrap: break-word
                }
                .counter-box {
                    .counter {
                        color: black;
                        font-size: 20px;
                        font-weight: 400;
                        line-height: 26px;
                        word-wrap: break-word
                    }
                }
            }
        }
    }

    .mobile-main-section-images-container {
        .mobile-main-section-images-inner {
            .carousel {
                .carousel-indicators {
                    display: none;
                }
            }
        }
    }
}


