.privacy-container {
    padding-top: 3rem;
    padding-bottom: 3rem;
    max-width: 1280px;
    width: 95%;

    .privacy-header {
        padding-bottom: 4rem;

        h1 {
            margin-bottom: 1rem;
        }

        span {
            color: var(--global-dark-red-color);
        }
    }

    .input-switch-container {
        .row {
            display: flex;
            justify-content: space-between;
            align-items: stretch;

            .input-swtich-panel {
                &:nth-child(1) {
                    padding-right: 5%;
                }

                &:nth-child(2) {
                    padding-left: 5%;
                }
            }
        }

        .input-switch-box {
            padding-bottom: 1.5rem;
            border-bottom: 1px solid #d2d2d2;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;

            h5 {
                font-size: 16px;
                font-weight: 600;
                margin-bottom: 1.5rem;
            }

            p {
                font-size: 14px;
            }

            .item-title-desc {
                padding-bottom: 1rem;
            }

            .input-swtich-item {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 1.5rem;
            }
        }
    }

    .personl-data-container {
        padding-top: 4rem;

        .variant-btn-panel {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;

            .variant-btn {
                width: 300px;
                display: flex;
                justify-content: center;
                background-color: #282828;
                color: #fff;
            }
        }
        .txt-error, .text-success {
            text-align: right;
        }
    }
}

.communcation-history-container {
    padding-top: 3rem;
    padding-bottom: 3rem;
    max-width: 1280px;
    width: 100%;

    .communcation-history-header {
        padding-bottom: 1rem;
        display: flex;
        justify-content: start;

        h1 {
            margin-bottom: 1rem;
        }

        span {
            color: var(--global-dark-red-color);
        }
    }

    .communcation-wrapper {
        width: 100%;
        overflow-x: auto;
    }


    .communcation-history-content {
        width: 100%;


        .communcation-history-content-header {
            border-bottom: 2px solid #e3e5e3;
        }
    }

}

@media screen and (max-width: 767.98px) {
    .communcation-history-content {
        width: 800px;
    }

    .privacy-container {
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 6rem;
        .personl-data-container {
            .variant-btn-panel {
                width: 100%;
                .variant-btn {
                    width: 100%;
                }
            }
        }
    }
}