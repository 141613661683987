.exterior-container {
    color: #282828;
    text-align: left;
    letter-spacing: 1px;
    position: relative;
    // h3 {
    //     color: #282828;
    //     text-align: left;
    //     letter-spacing: 1px;
    //     padding-bottom: 3rem;
    //     font-size: 24px;
    //     margin: 0;
    // }
    .exterior-options-panel {
        display: flex;
        flex-direction: row;
        // justify-content: flex-start;
        flex-wrap: wrap;
        width: 100%;
        padding: 1.5rem 0 .5rem 0;
    }

    .title-panel {
        color: black;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 1px;
        word-wrap: break-word;
        min-height: 45px;
        p {
            margin: 0;
        }
        span {
            color: #707070;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 1px;
            word-wrap: break-word
        }
    }
    
    .extra-paid {
        height: 1.5rem;
        font-size: 14px;
        color: var(--global-checkout-desc-font-color);
    }
    
    .options-container {
        .options-subheader {
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            font: normal normal 400 1.125rem/1.375rem CadillacGothic-Regular,CadillacGothic-NarrowRegular,CadillacGothic-Wide,Arial,NanumSquare,sans-serif;
            margin: 2rem 0 1.5rem;
            background-color: #fff;
            z-index: 1;
            span {
                font-style: normal;
                font-size: 1rem;
                font-weight: 400;
                letter-spacing: 1px;
                color: #282828;
                text-transform: uppercase;
                text-align: left;
            }
        }
    }
}

.desc-content {
    color: #707070;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    span {
        color: black;
        letter-spacing: 0.50px;
        word-wrap: break-word
    }
}

//Control the color selection panel
.color-options-item {
    display: inline-flex;
    align-items: center;
    gap: 1.5rem;
    cursor: pointer;
    flex-basis: 16.66%;
    padding-bottom: 1rem;
    .color-options-round {
        height: 3.3rem;
        width: 3.3rem; 
        border-radius: 60px;
        border: 2px solid transparent; /* Set the initial border */
        transform: rotate(45deg);
        img {
            transition: padding .25s ease-in-out;
            height: 3.1rem;
            width: 3.1rem;
            border-radius: 50px;
            transform: rotate(-45deg);
        }
    }
    .options-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 2px;
        color: #282828;
        text-align: start;
    }
}
.color-options-item.active {
    .color-options-round {
        border: 2px solid #282828; 
        animation: borderAnimation .5s linear 1;
        @keyframes borderAnimation {
            0% {
              border-top-color: #282828;
              border-right-color: transparent;
              border-bottom-color: transparent;
              border-left-color: transparent;
            }
            25% {
              border-top-color: #282828;
              border-right-color: #282828;
              border-bottom-color: transparent;
              border-left-color: transparent;
            }
            50% {
              border-top-color: #282828;
              border-right-color: #282828;
              border-bottom-color: #282828;
              border-left-color: transparent;
            }
            75% {
              border-top-color: #282828;
              border-right-color: #282828;
              border-bottom-color: #282828;
              border-left-color: #282828;
            }
            100% {
              border-top-color: #282828;
              border-right-color: #282828;
              border-bottom-color: #282828;
              border-left-color: #282828;
            }
          }
        img {
            padding: 0.3125rem;
        }
    }
}