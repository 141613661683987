.why-collecting-container {
    padding-top: 1rem;
    .collecting-content {
        font-size: 12px;
        color: #818181;
        cursor: pointer;
        letter-spacing: 1.2px;
    }
    .why-collecting-dialog {

    }
}


.change-username-inner-container {
    a {
        cursor: pointer;
    }
    .username {
        // padding-right: 1rem;
        font-weight: 600;
    }
}

.change-username-dialog {

    .p-dialog-content {
        display: flex;
        flex-direction: row;
        .input-item {
            margin: 0;
        }
        .variant-btn {
            background-color: #282828;
            margin-left: 2rem;
        }
    }
}



/* Spinner component */
.loader {
    aspect-ratio: 1;
    border-radius: 50%;
    margin: 0 auto;
    background: 
      radial-gradient(farthest-side,#282828 80%,#0000) top/8px 8px no-repeat,
      conic-gradient(#0000 20%,#282828);
    -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 4px),#000 0);
    animation: Spinner 1s infinite linear;
}
@keyframes Spinner{ 
    100%{transform: rotate(1turn)}
}
