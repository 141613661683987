.coming-soon-container {
    height: 55vh;
    .coming-soon-panel {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        width: 100%;;
        .coming-soon-box {
            display: flex;
            flex-direction: column;
            width: 670px;
            .coming-soon-top {
                font-size: 18px;
            }
            .coming-soon-title {
                font-size: 58px;
                padding-bottom: 1rem;
            }
            .coming-soon-content {
                font-size: 22px;
                padding-bottom: 1.5rem;
            }
            .coming-soon-btn {
                width: 280px;
                color: black;
                background-color: white;
                border: 1px solid black;
            }
        }
    }
}

@media screen and (max-width: 767.98px) {
    .coming-soon-container {
        height: 80vh;
        .coming-soon-panel {
            .coming-soon-box {
                .coming-soon-top {
                }
                .coming-soon-title {
                    font-size: 28px;
                    padding-bottom: 1rem;
                }
                .coming-soon-content {
                    font-size: 18px;
                    padding-bottom: 1.5rem;
                }
                .coming-soon-btn {
                    width: 280px;
                    color: black;
                    background-color: white;
                    border: 1px solid black;
                }
            }
        }
    }
}