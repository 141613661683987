.purchasing-procedure-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 4rem;

    h1, p {
        color: #FCFCFC;
        margin: 0;
    }

    .success-contact-title {
        padding: 5rem 2rem 1.5rem 3rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #000000;

        h1 {
            color: white;
            font-size: 36px;
            font-weight: 400;
            text-transform: uppercase;
            font-family: 'CadillacGothic-WideRegular';
            line-height: 72px;
            letter-spacing: 3px;
            word-wrap: break-word;
        }

        p {
            margin-top: 10px;
            font-size: 26px;
        }
    }

    .successful-header {
        position: relative;
        width: 100%;
        // background-color: #282828;
        .successful-header-wrapper {
            display: flex;
            flex-direction: column;
        }

        .success-title {
            padding: 5rem 2rem 1.5rem 3rem;
            width: 100%;
            position: relative;
            z-index: 23;

            h1 {
                color: white;
                font-size: 48px;
                font-weight: 400;
                text-transform: uppercase;
                font-family: 'CadillacGothic-WideRegular';
                line-height: 52px;
                letter-spacing: 3px;
                word-wrap: break-word
            }
        }




        .success-info {
            padding: 0rem 2rem 2rem 3rem;
            z-index: 23;
            position: relative;
            width: 50%;

            h4 {
                color: white;
                font-size: 16px;
                font-weight: 400;
                // text-transform: uppercase;
                line-height: 24px;
                letter-spacing: 2px;
                word-wrap: break-word;
                margin-bottom: 1.5rem;
            }

            p {
                font-weight: 400;
                font-family: 'CadillacGothic-Regular';
                line-height: 22px;
                letter-spacing: 1px;
                word-wrap: break-word;
                color: white;
                font-size: 16px;
            }
        }

        .success-finance-wrapper {
            text-align: center;
            position: absolute;
            right: 10%;
            bottom: 2%;
            background-color: #000000;
            color: white;
            padding: 20px 100px;
            cursor: pointer;
            text-decoration:none;
        }


        .overlap-panel {
            // position: absolute;
            background-color: #282828;
            width: 100%;
            height: 30vh;
            z-index: 0;
        }

        .success-steps {
            position: relative;
            background-color: #FCFCFC;
            border: 1px solid #E9ECEF;
            // padding: 40px;
            max-width: 400px;
            border-radius: 4px;
            // border: 1px solid #D2D2D2;
            z-index: 23;
            left: 3rem;
            min-height: fit-content !important;

            img {
                position: relative !important;
                width: 100% !important;
                top: 0 !important;
                left: 0
            }

            h5 {
                font-size: 24px;
                line-height: 32px;
                letter-spacing: 1px;
                padding-bottom: 2rem;
            }

            h6 {
                // padding-bottom: .5rem;
                margin: 0;
            }

            .variant-btn-panel {
                margin: 0 !important;

                .variant-btn {
                    margin: 0 !important;
                    background-color: #282828;
                    width: 100%;
                    border: none;
                    padding: 1rem 1.5rem;
                    font-family: 'CadillacGothic-Regular';
                    color: white;
                    font-size: 14px;
                    font-weight: 400;
                    text-transform: uppercase;
                    line-height: 22px;
                    letter-spacing: 2px;
                    word-wrap: break-word;

                    &:hover, &:focus:hover, &:active {
                        background-color: #282828;
                        box-shadow: none;
                    }

                    border: none;
                    box-shadow: none;
                }
            }

            .success-steps-title {
                padding: 20px 20px 0 20px;

                h4 {
                    color: black;
                    font-size: 16px;
                    font-weight: 400;
                    text-transform: uppercase;
                    line-height: 24px;
                    letter-spacing: 2px;
                    word-wrap: break-word;
                    margin-bottom: 1.5rem;
                }

                p {
                    color: #3A3A3A;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 22px;
                    word-wrap: break-word;
                    margin-bottom: 1.5rem;
                }
            }
        }

        .black-bg-panel {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            aspect-ratio: 1440/500;
            background-color: #000000;
            z-index: 0;
        }

        .purchased-vehicle {
            position: absolute;
            right: 2rem;
        }

        .purchased-vehicle.order-placed {
            width: 60%;
            top: 15vh;
            z-index: 99;
            img {
                width: 100%;
            }
            .variant-btn-panel {
                display: flex;
                .variant-btn {
                    width: 70%;
                    margin: 0 auto;
                    border: none;
                    background-color: #282828;
                    color: #fff;
                    font-family: 'CadillacGothic-Regular';
                    height: 1rem;
                    padding: 1rem 1.5rem;
                    display: flex;
                    justify-content: center;
                }
            }
            // background-color: #fff;
        }

        .purchased-vehicle.waitlisted {
            width: 60%;
            top: 15vh;
            z-index: 99;
        }
    }

    .procedure-container {
        padding: 7rem 3rem 1rem 3rem;
        align-self: flex-start;

        .procedure-title {
            // Waitlisted
            color: black;
            font-size: 40px;
            font-family: 'CadillacGothic-Regular';
            font-weight: 400;
            text-transform: uppercase;
            line-height: 48px;
            letter-spacing: 3px;
            word-wrap: break-word;
            padding-bottom: 24px;
        }

        .procedure-subtitle {
            color: #3A3A3A;
            font-size: 20px;
            font-weight: 400;
            line-height: 26px;
            word-wrap: break-word
        }
    }
}

.success-disclaimer-container {
    padding: 2rem 3rem;
}

.success-disclaimer-container.my-order {
    padding: 2rem 0 0 0;
}

.purchasing-details, .selection-details {
    padding-top: 36px;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    width: 95%;
    margin: 0;

    .finance-delivery-container {
        display: flex;
        flex-direction: column;
    }

    .selection-panel, .payment-summary-panel {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .selection-panel {
        flex-basis: 40%;
    }

    .payment-summary-panel, .finance-delivery-container {
        flex-basis: 30%;
    }

    .payment-summary-panel {
        img {
            cursor: pointer;
        }
    }

    .selection-panel, .payment-summary-panel, .finance-panel, .delivery-panel {
        padding: 1.5rem 20px;
        border-radius: 4px;
        border: 1px #E9ECEF solid;
        margin-bottom: 1rem;

        .section-title {
            color: black;
            font-size: 16px;
            font-weight: 400;
            text-transform: uppercase;
            font-family: 'CadillacGothic-Regular';
            line-height: 24px;
            letter-spacing: 2px;
            word-wrap: break-word;
            padding-bottom: 1rem;
        }

        .details-item {
            padding-bottom: 1rem;

            .details-subtitle {
                color: #707070;
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0.50px;
                word-wrap: break-word;
            }

            .details-desc {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                p {
                    margin: 0;
                    color: black;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 22px;
                    word-wrap: break-word
                }

                span {
                    color: #707070;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 22px;
                    word-wrap: break-word
                }
            }

        }

        .details-total-item {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .details-subtitle {
                font-size: 16px;
                font-weight: 600;
                line-height: 18px;
                letter-spacing: 0.50px;
                word-wrap: break-word;
            }

            .details-desc {
                span {
                    /* color: #707070;*/
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 22px;
                    word-wrap: break-word
                }
            }
        }

        .details-gst {
            padding-bottom: 1rem;
            color: #707070;
            margin: 0;
            font-size: 12px;
            font-weight: 400;
            line-height: 22px;
            word-wrap: break-word;
            border-bottom: 1px solid #ddd;
        }

        .download-btn {
            padding: 1rem 0;

            img {
            }

            .download-text {
                color: black;
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0.50px;
                word-wrap: break-word;
                padding-left: .5rem;
            }
        }

        .section-bottom {
            margin-top: 12px;
            padding-top: 1rem;
            border-top: 1px #E9ECEF solid;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            h6 {
                color: black;
                font-size: 20px;
                font-weight: 400;
                line-height: 26px;
                word-wrap: break-word;
                margin-bottom: 0;
            }
        }
    }
    // .selection-panel, .finance-panel {
    //     margin-right: 3rem;
    // }

    .selection-panel {
        flex-basis: 38%;
    }

    .finance-panel {
        flex-basis: 28%;
    }

    .delivery-panel {
        flex-basis: 28%;
    }
}

// .purchasing-details {
//     margin: 0 3rem;
// }
@media screen and (min-width: 1440px) {
  
    .purchasing-procedure-container {
        .successful-header {
            .black-bg-panel {
                aspect-ratio: 1440/400;
            }

            .purchased-vehicle.order-placed {
                position: absolute;
                top: 12vh;
                right: 0rem;
                margin: 0 auto;
                z-index: 999;
            }
        }
    }
    
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .purchasing-procedure-container {
        .successful-header {
            .success-info, .success-title {
                padding-left: 2rem;
            }

            .success-title {
                padding-top: 5vh;
            }

            .success-info {
                width: 55%;
            }

            .success-steps {
                max-width: 300px;
                left: 2rem;
            }

            .black-bg-panel {
                aspect-ratio: 1440/700;
            }

            .purchased-vehicle.order-placed {
                position: absolute;
                top: 20vh;
                right: 0rem;
                width: 58%;
                margin: 0 auto;
                z-index: 999;
            }

            .purchased-vehicle.waitlisted {
                width: 55%;
                top: 12vh;
                right: 0;
            }
        }

        .procedure-container {
            padding: 5rem 2rem 1rem 2rem;
        }
    }

    .purchasing-details, .selection-details {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding-top: 2rem;
        width: calc(100% - 4rem);

        .selection-panel {
            flex-basis: 100%;
            margin-bottom: 1.5rem;
        }

        .finance-panel {
            flex-basis: 39%;
            margin-bottom: 1.5rem;
        }

        .delivery-panel, .payment-summary-panel, .finance-delivery-container {
            flex-basis: 59%;
            margin-bottom: 1.5rem;
        }
    }
}


@media screen and (max-width: 767.98px) {
    .purchasing-procedure-container {
        .successful-header {
            .success-title {
                padding: 4rem 1rem 1.5rem 1rem;

                h1 {
                    color: white;
                    font-size: 32px;
                    font-weight: 400;
                    text-transform: uppercase;
                    line-height: 34px;
                    letter-spacing: 3px;
                    word-wrap: break-word;
                    text-align: center;
                }
            }

            .success-info {
                width: 100%;
                text-align: center;
                padding: 0rem 2rem 2rem 2rem;
                h4 {
                    color: white;
                    font-size: 16px;
                    font-weight: 400;
                    text-transform: uppercase;
                    line-height: 24px;
                    letter-spacing: 2px;
                    word-wrap: break-word
                }

                p {
                    color: white;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 22px;
                    word-wrap: break-word
                }
            }

            .success-steps {
                // max-width: 300px;
                // padding: 2rem 0;
                margin-top: 1rem;
                width: calc(100% - 3rem);
                left: 50%;
                transform: translateX(-50%);
            }

            .black-bg-panel {
                aspect-ratio: 1440/1680;
            }

            .black-bg-panel.waitlisted {
                aspect-ratio: 1440/1440;
            }

            .purchased-vehicle.order-placed {
                position: relative;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                margin: 0;
                width: 90%;
                z-index: 999;
            }

            .purchased-vehicle.waitlisted {
                position: relative;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                margin: 0;
                width: 90%;
                z-index: 999;
            }
        }

        .procedure-container {
            padding: 3rem 1.5rem 1.5rem 1.5rem;

            .procedure-title {
                color: black;
                font-size: 24px;
                font-weight: 400;
                text-transform: uppercase;
                line-height: 32px;
                letter-spacing: 3px;
                word-wrap: break-word
            }

            .procedure-subtitle {
                color: #282828;
                font-size: 20px;
                font-weight: 400;
                line-height: 26px;
                word-wrap: break-word
            }
        }
    }

    .purchasing-details, .selection-details {
        display: flex;
        flex-direction: column;
        padding-top: 2rem;
        width: calc(100% - 3rem);

        .selection-panel, .finance-panel, .payment-summary-panel, .finance-delivery-container {
            flex-basis: 100%;
            margin-bottom: 1.5rem;
        }
    }
}
