.header-logo {
    margin-right: 80px;
    position: relative;
    top: 5px;
    left: 10px;

    img {
        //height: 48px;
    }
}


.header {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    // border-bottom: 1px solid #e1dede;
    background-color: #f6f6f6;
    .header-top-container {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-bottom: 15px;
        .header-top {
            // max-width: 1440px;
            width: 100%;
            max-height: 100px;
        }

        .header-top-lg {
            display: flex;
        }

        .header-top-sm {
            display: none;
        }
    }

    .header-banner-container {
        display: flex;
        justify-content: center;
        width: 100%;

        .header-banner {
            // max-width: 1440px;
            width: 100%;
        }
    }
}


@media screen and (max-width: 767.98px) {
    .header {
        position: fixed;
        height: 54px;
    }
}

/*Menu*/
.global-menu-container, .main-menu-container {
    position: sticky;
    top: 0;
    z-index: 1000;
}
.inventory-menu-container {
    position: fixed;
}
.global-menu-container, .main-menu-container, .inventory-menu-container {
    // width: 100%;
    // margin: 0 auto;
    // // height: 145px;
    // background-color: #f6f6f6;
    // // position: absolute;
    // // top: 0;
    // position: relative;
    // z-index: 3;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    top: 0;
    background-color: #fff;
    z-index: 1000;
    .main-menu-panel, .global-menu-panel {
        display: flex;
        flex-direction: column;
        // background-color: #fff;
        width: 100%;
        .main-menu, .global-menu {
            // max-width: 1440px;
            width: 100%;
            height: 100%;
            margin: 0 auto;
            padding: 0 2rem;
            // background-color: #2c2c2c;
            .logo-container, .side-container {
                display: flex;
                flex-direction: row;
                // .menu-item {
                //     height: 80px;
                // }
                .certainty-panel {
                    margin-left: 3rem;
                    color: #282828;
                    padding: 0;
                    cursor: pointer;
                    font-family: 'CadillacGothic-Regular' !important;
                    // border-left: 1px solid white;
                    // padding-left: .8rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 12px;
                    font-weight: 500;
                    text-transform: uppercase;
                    line-height: 18px;
                    letter-spacing: 1.6px;
                    word-wrap: break-word;
                    &:hover {
                        border-bottom: 2px solid #282828;
                    }
                }
                .certainty-panel.global-dropdown {
                    font-family: 'CadillacGothic-WideMedium' !important;
                    font-weight: 500 !important;
                }
            }
            .logo-container, .side-container {
                button {
                    border: none;
                    background-color: #fff;
                    border-radius: 0% !important;
                    position: relative;
                    transition: border-bottom-color 0.3s ease-in-out;
                }
                // button:hover {
                //     border-bottom: 2px solid #282828;
                // }
                .certainty-panel::after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: .1rem;
                    background-color: transparent;
                    transform: scaleX(0);
                    transition: transform 0.3s ease-in-out;
                    border-bottom: 2px solid #282828;
                }
                .certainty-panel.active {
                    // color: var(--global-exterior-interior-font-color);
                    color: #282828;
                    font-weight: 600;
                    &:hover {
                        border-bottom: none;
                    }
                }
                .certainty-panel.active::after {
                    background-color: var(--global-exterior-interior-font-color);
                    transform: scaleX(1);
                }
                .certainty-panel.black {
                    // color: #282828;
                    color: black;
                }
            }
            .side-container {
                // padding-left: 1rem;
                h6 {
                    margin: 0;
                }
            }
            .menu-items {
                padding-left: 20px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                position: relative;
                .left-panel {
                    .menu-item {
                        font-weight: bold;
                    }
                }
                .right-panel {
                    display: flex;
                    flex-direction: row;
                    .menu-item {
                        padding-left: 2rem;
                        font-size: 14px;
                    }
                }
                a {
                    text-decoration: none;
                    color: #fff;
                    h6 {
                        margin: 0;
                        &:lang(kr) {
                            font-size: 22px;
                        }
                        &:lang(en) {
                            font-size: 22px;
                        }
                    }
                }
               
                .menu-logo {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    cursor: pointer;
                }
            }
            
               
            // .menu-items:last-child {
            //     padding-right: 0;
            // }
            // &:first-child {
            //     padding-top: 1.1rem;
            //     padding-bottom: 1rem;
            // }
            // &:last-child {           //Former Demo needs it, new project comment this part
            //     height: 65px;
            //     padding-top: .2rem;
            //     padding-bottom: .2rem;
            //     background-color: #262626;
            //     .menu-items {
            //         margin: 0 auto;
            //         max-width: 1920px;
            //         padding: 0 3%;
            //     }
            //     // max-width: 1512px;       
            // }
        }

        .global-menu {
            background-color: #FCFCFC;
            &:first-child {
                // padding-top: 1.1rem;
                // padding-bottom: 1rem;
                // border-bottom: 1px solid #b3b3b3; 
            }
        }

        .menu-item {
            // margin-right: 0;
            // color: #000;
            color: #fff;
            font-weight: 500;
            // margin-top: 5px;
            cursor: pointer;
            padding: 5px;
            height: 45px;
            text-align:center;
            text-decoration:none;
            display: flex;
            flex-direction: column;
            justify-content: center;
            &:last-child {
                margin-top: 0;
            }

            .logo {
                &:lang(en) {
                    height: 32px;
                }
            }

            .icon {
                height: 28px;
                padding-right: 10px;
            }

            &.login {
            }

            &.signin {
                border: 1px solid #0071CD;
                border-radius: 8px;
            }

            // &:hover {
            //     text-decoration: none;
            //     font-weight: 900;
            // }
        }

        .menu-item:first-child {
            padding-left: 0;
        }

        .menu-items {
            padding-left: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .menu-items:first-child {
            padding-left: 0;
        }

        .menu-items:last-child {
            padding-right: 0;
        }

        .global-drawer-container {
            display: flex;
            align-items: center;
            .hamburger-icon {
                cursor: pointer;
            }
        }

        .search-wrapper-container {
            display: flex;
            flex-direction: row;
            .search-wrapper {
                // border-bottom: 1px solid #fff;
                /*padding-left: 80px;*/
                display: flex;
                flex-direction: row;
                align-items: center;
                .input-keywords {
                    width: 210px;
                    background-color: transparent !important;
                    color: #fff !important;
                    border: none !important;
                    padding-top: 0;
                    &::placeholder {
                        color: #e7e0e0;
                    }
                    &:focus {
                        border: none;
                        box-shadow: none;
                    }
                }
    
                .search-icon {
                    color: #fff;
                }

                &:first-child {
                    margin-right: 3rem;
                }
            }
            .sign-in-up-panel {
                display: flex;
                flex-direction: row;
                color: #fff;
                margin-left: auto;
                .sign-in-btn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                }
                .join-us-btn {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    width: 80px;
                    cursor: pointer;
                    text-decoration: underline;
                }
            }

            .online-wrapper {
                cursor: pointer;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: center;
                width: auto;
                // padding-right: 1.2rem;
                .icon-user {
                    // background-color: #fff;
                    // border-radius: 50%;
                    width: 30px;
                    height: 30px;
                    // margin-right: .2rem;
                    filter: brightness(0) invert(1);
                } 
                .icon-user-unsigned {
                    width: 42px;
                    height: 42px;
                    padding-bottom: 2px;
                }
                span {
                    height: 15px;
                }
                // .icon-arrow {
                //     margin-left: .2rem;
                //     width: 16px;
                //     height: 16px;
                // }
            }

            .main-online-wrapper {
                cursor: pointer;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                span {
                    font-size: 12px;
                    color: #282828;
                    padding-left: 1rem;
                }
            }
        }
    }

    .main-menu-panel {
        .main-menu {
            .menu-items {
                justify-content: center !important;
                height: 80px;
            }
            .logo-container, .side-container {
                
                .menu-item {
                    height: 80px;
                }
                .certainty-panel {
                    height: 40px;
                }
            }
        }
    }

    .global-menu-panel {
        height: 106px;
        .global-menu {
            .menu-item {
                height: 87px;
            }
            .certainty-panel {
                height: 105px;
                background-color: #fcfcfc !important;
            }
        }
    }

    //Animation
    .global-menu-panel.down {
        animation: .4s ease-out 0s 1 subMenuInToTop;
    }

    .global-menu-panel.up {
        animation: .4s ease-in 0s 1 subMenuInFromTop;
    }

    @keyframes subMenuInToTop {
        0% {
          transform: translateY(0);
        }
        100% {
          transform: translateY(-100%);
        }
    }

    @keyframes subMenuInFromTop {
        0% {
          transform: translateY(-100%);
        }
        100% {
          transform: translateY(0);
        }
    }
}

.drawer-dialog {
    // background-color: #fcfcfc;
    // display: grid;
    // margin: 0;
    // padding: 0 20px 20px 24px;
    // position: relative;
    width: 340px;
    height: 100vh;
    .p-dialog-header {
        // background-color: #fcfcfc;
        background-color: #fcfcfc;
        display: grid;
        margin: 0;
        padding: 0 1.5rem 3rem 1.5rem;
        position: relative;
        border-radius: 4px 4px 0 0;
    }
    .dialog-btn-panel {
        height: 3rem;
        button {
            border-radius: 4px;
            &:hover {
                background-color: #282828;
            }
        }
    }
    .p-dialog-titlebar-close {
        position: absolute;
        top: 0;
        right: 0;
        background-color: #282828;
        border: 0;
        display: block;
        height: 48px;
        padding: 18.5px 16px;
        width: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            color: #fff;
            width: 20px;
            height: 20px;
        }
    }
    .drawer-section-panel {
        padding-bottom: 1rem;
    }
 
    .drawer-item-card {
        width: fit-content;
        cursor: pointer;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        color: #282828;
        font-size: 13px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0.65px;
        word-wrap: break-word;
        img {
            width: 25px;
            height: 25px;
        }
        span {
            // margin-left: .5rem;
            font-family: 'CadillacGothic-Regular';
            transition: transform 0.4s ease-in-out;
        } 
    }
    
    .drawer-discover-title, .drawer-discover-card, .drawer-ownership-title, .drawer-ownership-card {
        width: fit-content;
        color: #282828;
        display: flex;
        align-items: center;
        font-size: 13px;
        line-height: 25px;
        letter-spacing: 0.60px;
        word-wrap: break-word
    }
    .drawer-discover-title, .drawer-ownership-title {
        margin-bottom: 20px;
        font-weight: 600;   
        font-family: 'CadillacGothic-Regular';
    }
    .drawer-discover-card, .drawer-ownership-card {
        cursor: pointer;
        margin-bottom: 1.2rem;
        span {
            // margin-left: .5rem;
            font-family: 'CadillacGothic-Regular';
            transition: transform 0.4s ease-in-out;
        } 
    }
    .drawer-item-card, .drawer-discover-card, .drawer-ownership-card {
        .left-blue-bar {
            width: 2px;
            height: 25px;
            background-color: #787878;
            opacity: 0;
            transition: all 0.4s ease-in-out; 
        }
        &:hover {
            .left-blue-bar {
                transform: translateX(-0.5rem);
                opacity: 1;
            }
            span {
                transform: translateX(.6rem);
            }
        }
    }
}


@media screen and (min-width: 768px) and (max-width: 1024px) {
    .global-menu-container, .main-menu-container, .inventory-menu-container {
        .main-menu-panel, .global-menu-panel {
            .main-menu, .global-menu {
                padding: .5rem 1.5rem 0 1.5rem;
                .menu-items {
                    padding-right: 0;
                    height: 56px;
                    display: flex;
                    align-items: flex-end;
                }
                .side-container {
                    padding-left: 0;
                    height: 42px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    .certainty-panel {
                        height: 100%;
                        margin: 0 2rem;
                    }
                }
                .logo-container {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-around;
                    width: 100%;
                    .certainty-panel {
                        margin: 0 !important;
                        font-size: .75rem;
                        font-weight: 500;
                        letter-spacing: .1rem;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        flex-wrap: nowrap;
                        width: fit-content;
                        margin: 0 1.5rem 0 0;
                        white-space: nowrap;
                    }
                 
                }
            }
        }

        .global-menu-panel {
            height: 140px;
            .menu-items {
                margin-top: 1.5rem;
            }
        }
    }

    .global-menu-container {
        height: 140px !important;
    }

    .global-container {
        height: calc(100vh - 140px);
    }

    .drawer-dialog {
        background-color: #fff;
        .p-dialog-header {
            padding-bottom: 1rem;
        }
        .p-dialog-content {
            padding-top: 2rem;
            border: none !important;
        }
    }
}


@media screen and (max-width: 767.98px) {
    .global-menu-container, .main-menu-container, .inventory-menu-container {
        .main-menu-panel, .global-menu-panel {
            .main-menu, .global-menu {
                padding: .5rem 1.5rem 0 1.5rem;
                .menu-items {
                    padding-right: 0;
                    height: 56px;
                }
                .side-container {
                    padding-left: 0;
                    height: 42px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    .certainty-panel {
                        height: 100%;
                        margin: 0 2rem;
                    }
                }
                .logo-container {
                   
                    .certainty-panel {
                        &:last-child {
                            min-width: 110px;
                            margin-left: 1rem !important;
                        }
                    }
                 
                }
            }
            .main-menu {
                .menu-items {
                    display: flex;
                    align-items: flex-end;
                }
            }
        }
    }

    .drawer-dialog {
        width: 100% !important;
        background-color: #fff;
        .p-dialog-header {
            padding-bottom: 1rem;
        }
        .p-dialog-content {
            padding-top: 2rem;
            border: none !important;
        }
    }
}