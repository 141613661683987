body {
  margin: 0;
  background-color: #f7f8fa;
}

.container {
  font-family: 'FuturaPT';
  font-size: 16px;
  color: #5c5a5a;
}
/*
.header {
  padding: 40px 20px;
}
*/