.driveaway-price-box {
    cursor: pointer;
    span {
        color: black;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        word-wrap: break-word
    }
    img {
        width: 16px;
        height: 16px;
        margin-left: 6px;
    }
}


.driveaway-price-dialog {
    width: 80%;
    height: 95vh;
    position: absolute;
    bottom: 0;
    margin-bottom: 0 !important;
    .title-panel {
        h1 {
            margin-bottom: 2rem;
        }
        h6 {
            color: #707070;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0.50px;
            word-wrap: break-word;
            margin-bottom: 2rem;
            font-family: 'CadillacGothic-NarrowRegular';
        }
    }

    .p-dialog-header {
        border-radius: 1rem 1rem 0 0 ;
    }
    .p-dialog-content {
        padding-left: 5%;
        padding-right: 5%;

    }
    .driveaway-price-btn-panel {
        display: flex;
        flex-direction: row;
        margin: 1rem 0;
        width: 100%;
        // background-color: rgb(221, 221, 221); 
        .driveaway-price-btn {
            flex-basis: 50%;
            box-shadow: none;
            background-color: transparent;
            color: #282828;
            // background-color: rgb(221, 221, 221);
            border: 1px #E9ECEF solid;
            height: 3rem;
            line-height: 1.375rem;
            min-width: 6rem;
            cursor: pointer;
            transition: all .25s linear;
            min-height: 3rem;
            max-height: 5rem;
            // text-transform: uppercase;
            letter-spacing: 1px;
            font-size: 1rem;
            font-weight: 400;
            padding-left: 16px;
            padding-right: 16px;
            &:hover {
                border: none;
                background-color: #ddd;
            }
        }
        .driveaway-price-btn.selected {
            background-color: #fff;
            color: #282828;
            border: 2px black solid;
        }
    }
    .dropdown-container {
        .dropdown-panel {
            position: relative;
        }
        .p-dropdown {
            margin-top: 8px;
        }
        label {
            position: absolute;
            top: 28px;
            left: 36px;
            height: 0;
            color: #a9a9a9;
            transform-origin: 0rem 1rem;
            transition: transform 0.2s ease-in-out;
            pointer-events: none;
        }
        .select-state-label.selected, .registration-type-label.selected, .term-label.selected, .balloon-payment-label.selected, .payment-frequency-label.selected {
            transform: translateY(-1em) scale(0.7);
        }
        .p-dropdown-label {
            padding-top: 1.5rem;
        }
        span {
            color: #060606;
        }
    }
  
    .driveaway-price-container {
        .input-item {
            width: 100% !important;
            height: 3.8rem;
            margin: 0;
            .wrapper-box {
                position: relative;
                i {
                    position: absolute;
                    top: 2.55rem;
                    left: 1.5rem;
                }
                .p-inputtext {
                    padding-left: 1.5rem;
                    margin-top: 8px;
                    height: 3.8rem;
                }
                label {
                    left: 1.5rem;
                    top: 1.5rem;
                }
                input {
                    border: none;
                }
            }
            .wrapper-box.p-input-icon-left {
                i {
                    font-style: normal;
                }
                .p-inputtext {
                    padding-left: 2.5rem;
                }
            }
            .wrapper-box.disabled {
                .p-inputtext {
                    // background-color: rgb(179, 179, 179);
                    background-color: transparent;
                }
            }
        }

        .driveaway-price-card {
            margin-bottom: 1rem;
    
            .card-title {
                color: #707070;
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0.50px;
                word-wrap: break-word
            }
    
            .details-desc {
                display: flex;
                justify-content: space-between;
                flex-direction: row;
                span {
                    color: black;
                }
            }
    
            .driveaway-price-row-item {
                display: flex;
                justify-content: space-between;
                flex-direction: row;
                span {
                    color: black;
                    /*                font-size: 16px;
                    font-weight: 400;
                    line-height: 22px;
                    word-wrap: break-word*/
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 26px;
                    word-wrap: break-word
                }
    
                .price {
                    color: #505050;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 22px;
                    word-wrap: break-word
                }
    
                .total-price {
                    color: #060606;
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 26px;
                    word-wrap: break-word
                }
            }
    
            .driveaway-price-row-item.bold {
                font-weight: 600;
    
                span {
                    color: #060606;
                    font-family: 'CadillacGothic-Regular';
                    letter-spacing: 1px;
                    font-size: 16px;
                    line-height: 22px;
                    word-wrap: break-word
                }

                .special-title {
                    font-weight: 600 !important;
                }
            }
        }
    }
    .driveaway-price-bottom-info {
        margin-top: 1.5rem;
        p {
            color: #505050;
        }
    }
}

.driveaway-price-panel {
    height: fit-content;
    border-bottom: 1px solid #ddd;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 1rem;

    .driveaway-price-row-item, .driveaway-price-row-item.bold, .driveaway-price-row-item.extra, .driveaway-price-row-item.extra.bold {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .driveaway-price-card {
        margin-bottom: 1rem;

        .card-title {
            color: #707070;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0.50px;
            word-wrap: break-word
        }

        .details-desc {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            span {
                color: black;
            }
        }

        .driveaway-price-row-item {
            span {
                color: black;
                /*                font-size: 16px;
                font-weight: 400;
                line-height: 22px;
                word-wrap: break-word*/
                font-size: 16px;
                font-weight: 400;
                line-height: 26px;
                word-wrap: break-word
            }

            .price {
                color: #505050;
                font-size: 14px;
                font-weight: 400;
                line-height: 22px;
                word-wrap: break-word
            }

            .total-price {
                color: #060606;
                font-size: 20px;
                font-weight: 400;
                line-height: 26px;
                word-wrap: break-word
            }
        }

        .driveaway-price-row-item.bold {
            font-weight: 600;

            span {
                color: #060606;
                font-family: 'CadillacGothic-Regular';
                letter-spacing: 1px;
                font-size: 16px;
                line-height: 22px;
                word-wrap: break-word
            }

            .special-title, .total-price {
                font-weight: 600 !important;
            }
        }
    }

    .driveaway-price-row-item.extra {
        color: #505050;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 1px;
        word-wrap: break-word;
        margin-bottom: 1rem;

        .price {
            color: #505050;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0.50px;
            word-wrap: break-word
        }
    }

    .driveaway-price-row-item.extra.bold {
        color: #060606;
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 1px;
        word-wrap: break-word;

        .special-title {
            font-family: 'CadillacGothic-Regular';
        }

        .total-price {
            color: #060606;
            font-size: 20px;
            font-weight: 400;
            line-height: 26px;
            word-wrap: break-word
        }
    }
}


@media screen and (min-width: 768px) and (max-width: 1024px) {
    
    .total-amount-container {
        .inner-div {
            .driveaway-price-box {
                flex-basis: 50%;
                h6 {
                    font-size: 16px !important;
                }
            }
            .total-price {
                font-size: 16px !important;
            }
        }
    }
}


@media screen and (max-width: 767.98px) {
    .driveaway-price-dialog {
        width: 100%;
        height: 100vh;
        .title-panel {
            h1 {
                margin-bottom: 2rem;
            }
            h6 {
               
            }
        }
    
        .p-dialog-header {
            border-radius: 0 ;
        }
        .p-dialog-content {
            padding-left: 5%;
            padding-right: 5%;
    
        }
        .driveaway-price-container {
            .driveaway-price-panel {
                .driveaway-price-card {
                    span {
                        max-width: 60%;
                    }
                }
            }
        }
    }
}