//Get rid of Scrollbar but scrolldown still in operate 
// body::-webkit-scrollbar {
//     display: none;
// }

.mobile-footer-container { 
    background-color: #0072ce;
    justify-content: center;
    box-sizing: border-box;
    display: flex;
    color: #fff;
    flex-direction: column;
    .mobile-footer {
        padding: 30px 0 10px 0;
        // max-width: 1440px;
        width: 100%;
        box-sizing: border-box;
        color: #fff;
        .mobile-footer-box {
            display: flex;
            flex-direction: column;
            .mobile-footer-links {
                .footer-logo-container {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    width: 100%;
                    .logoGM {
                        // margin-left: 10px;
                        height: 45px;
                        img {
                            height: 100%;
                        }
                        padding-right: .8rem;
                    }
                    .logoACDelco {
                        height: 38px;
                        // border-left: 2px solid #fff;
                        // padding: 4px 0 4px .9rem;
                        padding: 4px 0;
                        img {
                            height: 100%;
                        }
                    }
                }
                &:first-child {
                    padding-bottom: 1rem;
                }
                &:last-child {
                    border: none;
                    border-top: 1px solid #fff;
                }
                
                .footer-header {
                    // display: flex;
                    // flex-direction: row;
                }
                .mobile-footer-section {
                    display: flex;
                    flex-direction: row;
                    // margin-left: 5px;
                    padding: 1rem 0 .7rem 0;
                    border-bottom: 1px solid #fff;
                    &:first-child {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        padding-bottom: 0;
                        border: none;
                    }
                    // .page-box {
                    //     flex-basis: 50%;
                    // }
                    // .service-box {
                    //     flex-basis: 50%;
                    // }
                    // &:last-child {
                    //     padding-bottom: 0;
                    //     border: none;
                    // }
                    p {
                        margin-bottom: .4rem;
                    }

                    .items-panel {
                        flex-basis: 50%;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                    }
                   
                    .footer-header {
                        font-weight: 700;
                        font-size: .9rem;
                    }
                    .footer-link {
                        width: fit-content;
                        a {
                            font-size: .8rem;
                            color: #fff;
                            // color: #000;
                            cursor:pointer;
                            text-decoration:none;
                        }
                    }
                }

                .footer-contactUs {
                    margin-left: 5px;
                    padding-top: 1rem;
                    display: inline-flex;
                    flex-direction: column;
                    width: 100%;
                    font-size: .9rem;
                    .footer-link {
                        padding-right: .5rem;
                        display: inline-flex;
                        flex-direction: column;
                        width: 63%;
                        h6 {
                            font-weight: 800;
                            white-space: nowrap;
                            font-size: .9rem;
                        }
                        p {
                            margin-bottom: .6rem;
                            // padding-left: .5rem;
                            font-size: .8rem;
                            color: #fff;
                        }
                        .footer-context-redirect {
                            margin: 0;
                            color: red;
                            text-decoration: none !important;
                        }
                        a {
                            font-size: .8rem;
                            text-decoration: underline;
                        }
                    }
                    
                }
            }
        }
    }
    .copyright-container {
        display: inline-flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        color: #fff;
        border-top: 1px solid #fff;
        padding-top: .7rem;
        padding-bottom: 1rem;
        background-color: #282828;
        .copyright-box {
            display: flex;
            flex-direction: row;
            padding-top: .3rem;
            &:last-child {
                p {
                    &:last-child {
                        padding-left: .2rem;
                        font-weight: bold;
                        text-decoration: underline;
                    }
                }
            }
            .copyright-item {
                padding: 0 .3rem;
                font-size: 12px;
                display: flex;
                flex-direction: row;
                color: #afadad;
                cursor:pointer;
                text-decoration:none;
                &:hover {
                    text-decoration:underline;
                } 
                p {
                    margin: 0;
                    width: fit-content;
                    color: #afadad;
                }
                a {
                    
                }
                &:nth-child(2) {
                    a {
                        padding-left: 7px;
                    }
                }
               
                // &:last-child {
                //     padding: .5rem 0;
                //     flex-basis: 100%;
                //     display: inline-flex;
                //     flex-direction: row;
                //     justify-content: center;
                //     font-size: 8px;
                //     p {
                //         &:last-child {
                //             padding-left: .2rem;
                //             font-weight: bold;
                //             text-decoration: underline;
                //         }
                //     }
                // }
            }
        }
    }
}



// .mobile-footer-container { 
//     background-color: #ECF6FF;
//     box-sizing: border-box;
//     display: flex;
//     width: 100%;

//     .footer {
//         padding: 1.2rem 0 0 0;
//         width: 100%;
//         box-sizing: border-box;
//     }

//     .mobile-footer-logo {
//         margin-bottom: 10px;
//         margin-left: 10px;
//         height: 30px;
//     }

//     .mobile-footer-contactUs {
//         margin-left: 15px;

//         .contact-email {
//             color: #000;
//             text-decoration: underline;
//         }
//     }

//     .toggle-normal {
//         font-weight: 450;
//         font-size: 17.5px;
//         color: #2d7bf0;
//         height: 50px; 
//         background-color: #ECF6FF;
//         border: 0;   
//     }
    
//     .toggle-clicked {
//         font-size: 17.5px;
//         color: #2d7bf0;
//         height: 50px;  
//         font-weight: 800;                   
//         box-shadow: none;
//         background-color: #ECF6FF;
//         border: 0;
//     }

//     .accordion {
//         width: 85%; 
//         margin: 0 auto;
//         .card {
//             background-color: #ECF6FF;
//             border: 0;
//             .card-header {
//                 background-color: #ECF6FF;
//                 border-bottom: 0;
//                 padding-top: .2rem;
//             }
//             .card-body {
//                 padding: 0 1rem;
//                 .mobile-footer-link {
//                     margin: 0 0 8px 16px;
//                     font-size: 14px;
//                     a {
//                         font-size: 14px;
//                         color: #000;
//                         text-decoration: none;
//                     }
//                 }
//             }
//         }

//         .card:nth-child(5) {
//             margin-bottom: 1rem;
//         }

//         div {
//             h2 {
//                 .accordion-button {
//                     background-color: #ECF6FF;
//                     border: 0;
//                 }
//                 .accordion-button:not(.collapsed) {
//                     color: #000;  
//                     box-shadow: none; 
//                 }
//             }
//         }     
//         .accordion-body {
//             display: flex;
//             flex-direction: column;
//             background-color: #ECF6FF;
//             padding: 0 0 .25rem 2.2rem;
//             .mobile-footer-link {
//                 margin-bottom: 8px;
//                 font-size: 14px;
//                 a {
//                     font-size: 14px;
//                     color: #000;
//                     text-decoration: none;
//                 }
//             }
//         }
//     }

//     .accordion-link {
//         margin: 0px 0px 6px 0;
//         font-size: 14px;
//         color: #000;
//     } 
    
//     .mobile-footer-header {
//         font-weight: 800;
//         font-size: 18px;
//         height: 50px;
//     }

//     .mobile-footer-links {
//         padding-top: 1.5rem;
//         text-align: center;
//     }

//     hr {
//         margin-top: 1rem;
//         margin-bottom: 1rem;
//         border: 0;
//         border-top: 2px solid rgba(0, 0, 0, 0.1);
//     }

//     p.copyright {
//         text-align: center;
//         font-size: .9rem;
//     }
// }

 /*    .mobile-footer-main {
        color: #fff;
        font-size: 14px;

        .links {
            margin-top: 20px;
        }
    }*/
    /*    .payment-options {
        text-align: center;
        color: #fff;

        .payment-options-title {
            margin-bottom: 10px;
        }

        .payment-logo {
            margin-right: 15px;

            &.paypal,
            &.afterpay {
                margin-right: 0;
            }
        }
    }*/
