.error-page-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: #000000;
    color: white !important;
    padding: 4rem 0;
    text-align: center;
    width: 100%;
    /*max-width: 95%;*/


    .title {
        font-size: 60px;
    }

    .error-desc {
        font-size: 30px;
    }
}
