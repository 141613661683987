$paddingValue: 2%;

.global-dialog {
    border-radius: 4px !important;
    .dropdown-container {
        width: 100%;
        padding-bottom: 2rem;
    }
    .p-dialog-header {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 4rem 0 1.5rem 0;
        border-radius: 4px 4px 0 0;
        .p-dialog-title {
            text-align: center;
            font-family: 'CadillacGothic-Regular';
            color: black;
            font-size: 24px;
            font-weight: 400;
            text-transform: uppercase;
            line-height: 32px;
            letter-spacing: 3px;
            word-wrap: break-word;
            // color: var(--global-exterior-interior-font-color);
        }
        .p-dialog-header-icons {
            display: none;
        }
    }
    .p-dialog-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 4rem;
        overflow: hidden !important;
        // padding: 0 4rem 3rem 4rem;  //For initial use
        padding: 0 1rem 3rem 1rem; //For temp use
        border-radius: 0 0 4px 4px;
        // .p-dropdown {
          
        // }
        .global-location-btn {
            background-color: #282828;
            padding: 1rem;
            // width: 350px;
            width: 100%;
            margin-bottom: 1.5rem;
            border: 2px solid #282828;
            border-radius: 4px;
            font-size: 16px;
            letter-spacing: 1px;
            font-family: 'CadillacGothic-Regular';
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            box-shadow: none;
            span {
                color: white;
                font-size: 14px;
                font-weight: 400;
                font-family: "CadillacGothic-Regular";
                text-transform: uppercase;
                line-height: 22px;
                letter-spacing: 2px;
                word-wrap: break-word
            }
            img {
                height: 2rem;
                // transform: rotate(180deg);
            }
            // &:hover {
            //     background-color: #FCFCFC;
            //     border: 2px solid #282828;
            //     color: #282828;
            //     img {
            //         // src: url(`${getCMSAssets()}/home/arrow-black.svg`);
            //     }
            // }
        }

        .temp-location-container {
            width: fit-content;
            display: flex;
            flex-wrap: nowrap;
            .temp-au-container, .temp-nz-container  {
                
                padding-left: 3rem;
                padding-right: 3rem;
                margin-top: 1.5rem;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 400px !important;
                .dropdown-container {

                    .country-title {
                        font-size: 16px;
                        font-family: 'CadillacGothic-WideRegular';
                        letter-spacing: 3px;
                        text-align: center;
                    }
                    .coming-soon {
                        font-size: 12px;
                        font-family: 'CadillacGothic-Bold';
                        letter-spacing: 2px;
                        text-align: center;
                        color: rgb(216, 33, 33);
                        padding-top: 6px;
                    }   
                    .reminder-box {
                        text-align: center;
                    }
                  
                    .new-zealand-info-panel {
                        text-align: center;
                        max-width: 300px;
                    }
                }
                .dropdown-container {
                    width: 100%;
                }
                .global-location-dropdown {
                    width: 300px !important;
                }
            }
            .temp-au-container {
                border-right: 1px solid black;
            }
            .temp-nz-container {
                border-left: 1px solid black;
                .dropdown-container {
                   
                }
            }
        }
    }
}

.p-dropdown {
    box-shadow: none !important;
    border: none;
    // border-bottom: 2px solid rgb(227, 225, 225);
    width: 100%;
    height: 3.8rem;
    margin-top: 1.5rem;
    background-color: #F6F6F4;
    // border-bottom: 2px solid #d2d2d2 !important;
    border-radius: 4px;
    padding: 0 16px;
    // &:hover {
    //     border-bottom: 2px solid #282828 !important;
    // }
    &:nth-child(1) {
        margin-top: 0;
    }
    .p-dropdown-label {
        display: flex;
        align-items: center;
        font-family: 'CadillacGothic-Regular';
        color: #282828;
    }
    .p-dropdown-trigger[aria-expanded="true"] {
        color: #282828
    }
}

.p-dropdown-panel {
    background-color: #f6f6f6;
    margin-top: 1rem;
    .p-dropdown-items-wrapper {
        .p-dropdown-items {
            padding: 0;
            .p-dropdown-item {
                color: black;
                font-size: 16px;
                font-weight: 400;
                line-height: 22px;
                word-wrap: break-word;
                // &:hover {
                //     border-bottom: 2px solid #282828;
                //     background: #f6f6f6 !important;
                // }
            }
            .p-dropdown-item.p-highlight {
                background: none;
            }
        }
    }
}

.p-dialog-mask.p-component-overlay {
    background-color: rgba(0,0,0,0.7) !important;
    backdrop-filter: blur(15px); /* Apply a blur effect to the background */
    -webkit-backdrop-filter: blur(15px);
    display: flex;
    align-items: center;
}

// .p-component-overlay-enter {
//     animation: p-component-overlay-enter-animation 150ms forwards;
//     @keyframes p-component-overlay-enter-animation {
//         0% {
//           transform: translateY(100%); /* Start from the bottom */
//           opacity: 0;
//         }
//         100% {
//           transform: translateY(0); /* Move to the center */
//           opacity: 1;
//         }
//     }
// }

// .p-component-overlay-exit  {
//     animation: p-component-overlay-exit-animation 150ms forwards;

//     @keyframes p-component-overlay-exit-animation {
//         0% {
//         transform: translateY(0); /* Start from the center */
//         opacity: 1;
//         }
//         100% {
//         transform: translateY(100%); /* Close to the bottom */
//         opacity: 0;
//         }
//     }
// }

.global-container, .global-options-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .pre-loader {
        background-color: #fcfcfc;
        width: 100%;
        height: calc(100% - 106px);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .global-content-page {
        position: sticky;
        top: 106px;
        // top: 0;
        // bottom: 0;
        padding: 0;
        // height: 100%;
        background-color: #f6f6f6;
        z-index: 0;
        width: calc(100% - 5rem);
        .global-content-container {
            height: calc(100vh - 106px);
            top: 16px;
            text-align: center;
            position: relative;
            // .video-panel {
            //     position: absolute;
            //     height: calc(100vh - 150px);
            //     width: 100%;
            //     top: 0;
            //     display: flex;
            //     align-items: center;
            //     justify-content: center;
                // bottom: calc((120dvh - var(--width)/16*9)/2);
                video {
                    // --width: clamp(70vw,calc(100vw - 6rem),calc(80dvh / 9 * 16));
                    --width: clamp(70vw,calc(80vw - 6rem),calc(80dvh / 9 * 16));
                    width: var(--width);
                    max-block-size: -webkit-fill-available;
                    position: -webkit-sticky;
                    position: sticky;
                    height: calc(95vh - 120px);
                    bottom: calc((200dvh - var(--width)/16*9)/2);
                    box-sizing: border-box;
                    border: none;
                }
            // }
        }
        .global-content-box {
            z-index: 4;
            vertical-align: top;
            width: 100%;
            text-align: center;
            margin: 0 auto;
            position: -webkit-sticky;
            position: absolute;
            bottom: 65dvh;
            color: #282828;
            letter-spacing: 1px;
            padding-top: 1rem;
            top: 0;
            .design-your-box {
                margin: 0 auto;
                width: fit-content;
                span {
                    font-family: 'CadillacGothic-Regular';
                }
            }
            .content-design {
                font-family: 'CadillacGothic-Regular';
            }
            .content-brand-name {
                font-family: 'CadillacGothic-WideRegular';
                font-size: 50px;
                padding: min(10px, 16px) 0;
            }
            .content-desc {
                font-family: 'CadillacGothic-Regular';
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                img {
                    width: 16px;
                    height: 16px;
                    margin-left: 10px;
                    cursor: pointer;
                }
            }
            .estimate-time {
                font-family: 'CadillacGothic-Regular';
            }
            .learn-more {
                cursor: pointer;
                text-decoration: underline;
            }
        }
        // .global-video-box {
        //     width: 100%;
        //     height: fit-content;
        // }
       
        .global-bottom-scroll {
            position: -webkit-sticky;
            position: sticky;
            bottom: 0.5rem;
            width: 100%;
            padding: 0.625rem;
            z-index: 3;
            text-align: center;
            visibility: visible;
            .important-info-box {
                position: absolute;
                top: -1rem;
                left: 50%;
                transform: translateX(-50%);
                color: #707070;
                font-size: 12px;
                font-weight: 400;
               
                line-height: 22px;
                letter-spacing: 1px;
                word-wrap: break-word;
               
                .important-info-link {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
            img {
                margin: auto;
                height: 2.5rem;
                width: 2px;
            }
            .scroll-to-start {
                font-family: 'CadillacGothic-Regular';
                font-size: 18px;
                cursor: pointer;
            }
        }
        .global-bottom-panel {
            height: 3.5rem;
            width: 100%;
            background-color: #fff;
            position: -webkit-sticky;
            position: sticky;
            bottom: 0;
            z-index: 2;
            visibility: visible;
        }
    }
    
    .new-vehicle-inventory-container {
        padding-top: 3rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .new-vehicle-inventory-title {
            padding: 3rem 0;
            h1 {
                color: black;
                font-size: 40px;
                font-weight: 400;
                text-transform: uppercase;
                line-height: 48px;
                letter-spacing: 3px;
                margin-bottom: 1.5rem;
            }
            p {
                color: #3A3A3A;
                font-size: 16px;
                font-weight: 400;
                line-height: 26px;
                margin-bottom: 1.5rem;
                text-align: center;
            }
        }
        .customize-panel {
            padding: 4rem 3rem;
            width: 50%;
            // min-width: 700px;
            min-width: 1000px;
            // background-color: #f2f2f2;
            border: 2px solid #f2f2f2;
            border-radius: 4px;
            h5 {
                font-size: 24px;
                font-weight: 400;
                text-transform: uppercase;
                line-height: 32px;
                letter-spacing: 3px;
            }
            h4 {
                margin-bottom: 2rem;
                font-weight: 400;
            }
            .input-item {
                height: 56px;
            }
            .wrapper-box {
                input {
                    border-bottom: none;
                    
                    &:hover {
                        border-bottom: none;
                    }
                }
                label {
                    top: 1.1rem;
                }
            }
           
            .checkout-btn-panel {
                margin-top: 1.5rem;
                button {
                    background-color: #282828;
                    border: none;
                    height: 56px;
                    &:hover {
                        h6 {
                            color: #fff !important;
                        }
                    }
                }
            }
            .variant-btn-panel {
                display: flex;
                flex-direction: row;
                margin: 0;
                button {
                    width: 200px;
                    display: flex;
                    justify-content: center;
                    margin-top: 1.5rem;
                    &:nth-child(1) {
                        margin-right: 1rem;
                        background-color: #282828;
                        span {
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}

.global-container {
    height: calc(100vh - 106px);
}

.global-container.device-ratio-177 {
    height: calc(100vh - 106px);
    .global-content-page {
        .global-content-container {
            video {
                --width: clamp(80vw, calc(80vw - 6rem), calc(80dvh / 9* 16));
                height: calc(85vh - 120px);
                top: 23vh;
            }
        }
        .global-content-box {
            padding-top: .5rem;
            .content-brand-name { 
                font-size: 42px;
                padding: 2px 0;
            }
            .content-desc {
                p {
                    margin: 0;
                }
            }
        }
    }
}

.global-container.device-ratio-160 {
    height: calc(100vh - 106px);
    .global-content-page {
        .global-content-container {
            video {
                --width: clamp(80vw, calc(80vw - 6rem), calc(80dvh / 9* 16));
                height: calc(95vh - 120px);
            }
        }
        .global-content-box {
            padding-top: .5rem;
            .content-brand-name { 
                font-size: 42px;
                padding: .5rem 0;
            }
            .content-desc {
                p {
                    margin: 0;
                }
            }
        }
    }
}

.global-popup-container {
    width: fit-content;
    margin: 0 auto;
    .calculate-finance-btn {
        margin-top: 1rem;
        padding: .5rem 1rem;
        min-width: 240px;
        background-color: #282828;
        border: none;
        h6 {
            color: white;
            font-size: 12px;
            font-weight: 400;
            text-transform: uppercase;
            line-height: 22px;
            letter-spacing: 2px;
            word-wrap: break-word;
            margin: 0;
            gap: 8px;
        }
    }
}

.design-your-dialog {
    width: 80%;
    .p-dialog-header {
        // padding-top: 3rem !important;
    }
    .p-dialog-header-icons {
        .p-dialog-titlebar-icon {
            outline: none;
            &:focus {
                border: none;
                box-shadow: none;
            }
        }
        .p-dialog-header-icon {
            display: none;
        }
    }
    .design-your-container {
        text-align: center;
        // overflow-y: scroll;
        // height: 85vh;


        .design-your-initial-page {
            padding-top: 15vh;
            // padding-bottom: 32vh;
        }
        .design-your-second-page {
            padding-top: 15vh;
            border-bottom: 1px solid #fff;
        }
        .design-your-footer {
            text-align: left;
            color: #fff;
            padding: 0.5rem 5rem 0 5rem;
            p {
                text-align: left;
            }
        }
        .scroll-reminder {
            opacity: 1;
            transition: opacity .4s ease-in-out;
        }
        .scroll-reminder.hide {
            opacity: 0;
        }
        .panel-item {
            padding-bottom: 2.5rem;
            h5 {
                font-size: 18px;
                margin: 0;
            }
            p {
                font-size: 14px;
                font-family: 'CadillacGothic-Regular';
            }
        }
        h4, h5, h6, p {
            text-align: center;
            color: #fff;
        }
        h4 {
            font-size: 24px;
            font-weight: 400;
            text-transform: uppercase;
            letter-spacing: 3px;
            word-wrap: break-word;
            padding-top: 0;
            padding-bottom: 1.5rem;
            font-family: 'CadillacGothic-WideRegular';
        }
        h5, h6, p {
            font-family: 'CadillacGothic-Regular';
        }
        h5 {
            font-size: 16px;
        }
        h6 {
            font-size: 14px;
        }
    }
    .p-dialog-header, .p-dialog-content {
        background-color: transparent;
        width: 100%;
    }
}

.important-info-dialog {
    width: 70%;
}
.important-info-dialog, .design-your-dialog  {
    height: 95vh !important;
    min-width: 80%;
    position: absolute;
    bottom: 0 !important;
    margin-bottom: 0 !important;
    border-radius: 1rem 1rem 0 0 !important;
    .p-dialog-header {
        padding: 2rem;
        border-radius: 1rem 1rem 0 0;
    }
    .p-dialog-content {
        color: #282828;
        padding: 0 3rem 3rem 3rem;
    }
    h1 {
        color: black;
        font-size: 40px;
        font-weight: 400;
        text-transform: uppercase;
        line-height: 48px;
        letter-spacing: 3px;
        word-wrap: break-word;
    }
    p {
        color: #3A3A3A;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        word-wrap: break-word;
    }
    .info-paragraph {
        a {
            text-decoration: underline;
            cursor: pointer;
        }
    }
}

.chat-icon-panel {
    position: fixed;
    bottom: 2.5rem;
    left: 3rem;
    z-index: 500;
    img {
        width: 40px;
        height: 40px;
        cursor: pointer;
    }
}


@media screen and (min-width: 768px) and (max-width: 1024px) {
    .global-dialog { 
        background: #fff;
    }
    .global-container {
        height: calc(100vh - 140px);
        .global-content-page {
            .global-content-container {
                video {
                    --width: clamp(80vw, calc(80vw - 6rem), calc(80dvh / 9* 16));
                }
            }
            .global-content-box {
                padding-top: 2rem;
                .content-brand-name { 
                    font-size: 32px;
                }
            }
        }
    }
}


@media screen and (max-width: 767.98px) {
    .global-dialog { 
        background: #fff;
        margin: 0 !important; //For temp use
        .p-dialog-header {
            .p-dialog-title {
                max-width: 80%;
            }
        }

        .p-dialog-content {
            .temp-location-container {
                width: fit-content;
                display: flex;
                flex-direction: column;
                flex-wrap: wrap !important;
                .temp-au-container, .temp-nz-container  {
                    
                    padding-left: 0;
                    padding-right: 0;
                    margin-top: 1.5rem;
                    display: flex;
                    flex-direction: column;
                    
                    justify-content: space-between;
                    width: 280px !important;
                    .dropdown-container {
    
                        .country-title {
      
                        }
                        .coming-soon {
                           
                        }   
                        .reminder-box {
    
                        }
                      
                        .new-zealand-info-panel {
                            text-align: center;
                            max-width: 200px;
                        }
                    }
                    .dropdown-container {
                        width: 100%;
                    }
                    .global-location-dropdown {
                        width: 100% !important;
                    }
                }
                .temp-au-container {
                    border-right: none;
                }
                .temp-nz-container {
                    border-left: none;
                    .dropdown-container {
                        .new-zealand-info-panel {
                            width: 100% !important;
                            max-width: 100%;
                        }
                    }
                    
                }
            }
        }
      
    }

    .global-container {
        height: calc(100vh - 140px);
        .global-content-page {
            width: calc(100% - 3rem);
            .global-content-container {
                position: relative;
                height: calc(100vh - 140px);
                video {
                    --width: clamp(85vw, calc(80vw - 6rem), calc(80dvh / 9* 16));
                    height: 30vh;
                    position: absolute;
                    bottom: 18vh;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
            .global-content-box {
                padding-top: 1rem;
                .content-brand-name { 
                    font-size: 32px;
                }
                .content-desc {
                    display: flex;
                    flex-direction: column;
                    p {
                        margin: 0;
                    }
                }
            }

            .global-content-box.android {
                padding-top: .5rem;
                .content-brand-name { 
                    font-size: 22px;
                    line-height: 1.5rem;
                }
                .content-desc {
                    display: flex;
                    flex-direction: column;
                    p {
                        margin: 0;
                    }
                }
            }

            .important-info-box {
                display: flex;
                flex-direction: column;
                .important-info-link {
                    flex-basis: 100%;
                }
            }
        }

      
    }

   .design-your-dialog {
        width: 100%;
        height: 100% !important;
        
        .design-your-container {
            .design-your-initial-page {
                padding-top: 10vh;
                // padding-bottom: 32vh;
            }
            .design-your-footer {
                text-align: left;
                color: #fff;
                padding: 0.5rem 0 0 0;
                p {
                    text-align: left;
                }
            }
        }
        .p-dialog-header, .p-dialog-content {
            background-color: transparent;
            width: 100%;
        }
    }

    .important-info-dialog {
        width: 100%;
        height: 100% !important;
        .p-dialog-header {
            border-radius: 0;
        }
        .p-dialog-content {
            h1 {
                font-size: 36px;
            }
        }
    }
}
