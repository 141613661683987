@media screen and (max-width: 767.98px){
    .mobile-nav-top-items {
        width: 100%;
        display: flex;
        flex-direction: row;
        // justify-content: space-between;
        color: #fff;
        .navbar-brand {
            margin: 0;
            padding-left: .5rem;
        }
        .right-nav-position {
            padding: 0.2rem 0rem 0 1rem;
            display: inline-flex;
            flex-direction: row;
        }
   
        
        //    .navbar-toggler {
        //         padding: 0;
        //         margin-left: 0.7rem;
        //         box-shadow: 0 0 0 0.1rem;
        //         color: #fff;
        //         h2 {
        //             background-color: #fff;
        //         }
        //     }
        //     .navbar-toggler:focus {
        //         box-shadow: 0 0 0 0.15rem;
        //     }
        .right-icons-container {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
        }

        .mobile-cart {
            padding-top: .1rem;
        }
    
        .sign-in-up-item {
            // margin-left: auto;
            margin-right: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: .3rem;
            .icon-user {
                // background-color: #fff;
                // border-radius: 50%;
                height: 26px;
                width: 26px;
                filter: brightness(0) invert(1);
            } 
        }

        .online-wrapper {
            // margin-left: auto;
            margin-right: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: .3rem;
            cursor: pointer;
            .icon-user {
                width: 30px;
                height: 30px;
                padding: 2px 2px 2px 2px;
                // background-color: #fff;
                // border-radius: 50%;
                // border: 1px solid #fff;
                // filter: brightness(0) invert(1);
            } 
            span {
                font-size: 12px;
            }
        }

        .sign-in-up-panel {
            display: flex;
            flex-direction: row;
            color: #fff;
            margin-left: auto;
            .sign-in-btn {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                cursor: pointer;
            }
            .join-us-btn {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 80px;
                cursor: pointer;
                text-decoration: underline;
            }
        }
    }

    .menu-item {
        /*display: inline-block;*/
        margin-right: 0;
        color: #0072CE;
        font-size: 13px;
        cursor: pointer;
        padding: 0.5rem 0.9rem 0 .2rem;
        height: 2rem;
          
        .logo {
            height: 2rem;
            padding: 0 0 0.2rem 0;
        }
    
        .icon {
            height: 82px;
            padding-right: 10px;
            color: #fff;
        }

        h2 {
            color: #fff;
            font-size: 1.1rem;
            font-weight: bold;
            margin: 0;
        }

        span {
            color: #fff;
        }
    
        &.login {
        }
    
        &.signin {
            border: 1px solid #0071CD;
            border-radius: 8px;
        }

        &:hover {
            text-decoration: none;
            font-weight: 900;
        }
    }

    .navbar-collapse {
        display: flex;
        flex-direction: column;
        .distance-to-divider {
            margin: 0 0 0.5rem 0;
        }
        .login-register-bar {
            width: 100%;
            // display: flex;
            // flex-direction: row;
            // justify-content: space-around;
            // padding: 0 4rem 0 4rem;
            border-top: 1px solid #e1dede;
            .login-panel {
                height: 2.3rem;
                padding-top: 0.3rem;
                border-right: 1px solid #e1dede;
            }
            .signin-panel {
                height: 2.3rem;
                padding-top: 0.3rem;
            }      
        }
    }

    .mobile-menu-collapse-panel {
        top: 0;
        right: 100%;
        height: 100vh;
        width: 100vw;
        background-color: #0072CE;
        z-index: 999999;
        transition: all .4s ease-in-out;
        position: absolute;
        .collapse-header-panel {
            display: flex;
            flex-direction: row;
            // justify-content: space-between;
            flex-wrap: nowrap;
            padding: 0.6rem 0 0 0;
        }

        .menu-options-panel {
            display: flex;
            flex-direction: column;
            padding: 0rem 0 5rem 1rem;
            overflow-x:hidden;
            overflow-y: scroll;
            max-height: 100%;
            // min-height: fit-content;
            .menu-item {
                font-size: 1.1rem;
                font-weight: bold;
                padding: .5rem 1rem 0 1rem;
                margin: .2rem 0;
                text-decoration: none;
            }
            .accordion-container {
                width: 100%;
                padding-right: .2rem;
                
                .accordion-header {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: space-between;
                    padding-right: .85rem;
                    align-items: center;
                   
                    span {
                        height: 2rem;
                        flex-grow: 1;
                        display: inline-flex;
                        flex-direction: row;
                        justify-content: flex-end;
                        .arrow {
                            height: 100%;
                            transform: 0;
                            transition: all .3s;
                        }
                        .arrow.show {
                            transform: rotate(180deg);
                            transition: all .3s;
                        } 
                    }
                }
                .contentAbout, .contentServices, .contentParts  {
                    background-color: #0072CE;
                    overflow: hidden;
                    height: 0;
                    width: 100%;
                    transition: height .5s;
                    padding: 0 1.5rem 0 1.5rem;
                    .inner-menu-box {
                        padding-top: .5rem;
                        .menu-sub-item {
                            width: fit-content;
                        }
                    }
                    a {
                        text-decoration: none;
                        color: #fff;
                    }
                    p {
                        margin-bottom: .7rem;
                        color: #fff;
                    }
                }
                .contentAbout.show  {
                    background-color: #0072CE;
                    transition: height 0.5s;
                    margin: 0;
                    width: 100%;
                    height: 9.2rem;
                }
                .contentServices.show  {
                    background-color: #0072CE;
                    transition: height 0.5s;
                    margin: 0;
                    width: 100%;
                    height: 15.7rem;
                }
                .contentParts.show  {
                    background-color: #0072CE;
                    transition: height 0.5s;
                    margin: 0;
                    width: 100%;
                    height: 28rem;
                }
                .inner-menu-box {
                    padding-top: .5rem;
                    display: inline-block;
                    a {
                        width: auto;
                        min-width: fit-content;
                    }
                }
            }

            .search-container {
                padding: 1rem 1.2rem 1rem .85rem;
                .search-wrapper {
                    border-bottom: 1px solid #fff;
                    /*padding-left: 80px;*/
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    .input-keywords {
                        background-color: transparent !important;
                        color: #fff !important;
                        border: none !important;
                        padding-top: 0;
                        &:focus {
                            border: none;
                            box-shadow: none;
                        }
                    }
            
                    .search-icon {
                        color: #fff;
                    }
                }
            }
           

            // .accordion {
            //     padding-right: 1.5rem;
            //     background-color: #000;
            //     .accordion-item {
            //         border: transparent;
            //         background-color: #000;
            //         .accordion-header {
            //             .accordion-button {
            //                 padding: 0;
            //                 background-color: #000;
            //                 color: #fff;
            //                 border: transparent;
            //                 &:focus {
            //                     box-shadow: none;
            //                 }
            //                 &:not(.collapse) {
            //                     box-shadow: none;
            //                 }
            //             }
            //             .accordion-button:not(.collapsed)::after {
            //                 filter: brightness(0) invert(1);
            //                 height: 1.5rem;
            //             }
            //             .accordion-button::after {
            //                 filter: brightness(0) invert(1);
            //                 height: 1.5rem;
            //             }
            //         }
            //         .accordion-body {
            //             background-color: #000;
            //             padding-top: .5rem;
            //             padding-bottom: 0;
            //             a {
            //                 text-decoration: none;
            //                 p {
            //                     color: #fff;
            //                 }
            //             }
            //         }
            //     }
            // }
        }
    }
    .mobile-menu-collapse-panel.active {
        top: 0;
        right: 0;
        width: 100vw;
    }
}

.navbar {
    padding: 0.6rem 0 0 0;
}

/* Burger Icon */
.menu-btn-box {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    transition: all .5s ease-in-out;
    .menu-btn-burger {
        width: 1.4rem;
        height: .16rem;
        background-color: #fff;
        box-shadow: 0 2px 5px rgba(255, 101,47, .2);
        transition: all .5s ease-in-out;
    }
    .menu-btn-burger::before,
    .menu-btn-burger::after {
        content: '';
        position: absolute;
        width: 1.4rem;
        height: .16rem;
        background-color: #fff;
        box-shadow: 0 2px 5px rgba(255, 101,47, .2);
        transition: all .5s ease-in-out;
        transform: translateY(3px);
    }
    .menu-btn-burger::before {
        transform: translateY(-6.5px);
    }
    .menu-btn-burger::after {
        transform: translateY(6.5px);
    }
}

.menu-btn-box-open {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 3rem;
    height: 2rem;
    cursor: pointer;
    margin-top: .1rem;
    margin-left: .7rem;
    // transition: all .5s ease-in-out;
    .menu-btn-burger {
        background-color: transparent;
    }
    .menu-btn-burger::before,
    .menu-btn-burger::after {
        content: '';
        position: absolute;
        width: 1.4rem;
        height: .16rem;
        background-color: #fff;
        box-shadow: 0 2px 5px rgba(255, 101,47, .2);
        transition: all .5s ease-in-out;
        transform: translateY(3px);
    }
    .menu-btn-burger::before {
        transform: rotate(45deg) translate(8px, -8px);
    }
    .menu-btn-burger::after {
        transform: rotate(-45deg) translate(8px, 8px);
    }
}


/* ANIMATION */
.menu-btn-box.open .menu-btn-burger {
    transform: translateX(-12px);
    background-color: transparent;
    box-shadow: none;
}
.menu-btn-box.open .menu-btn-burger::before {
    transform: rotate(45deg) translate(8px, -8px);
}
.menu-btn-box.open .menu-btn-burger::after {
    transform: rotate(-45deg) translate(8px, 8px);
}

