.test-drive-container {
    padding: 4rem 0;
    text-align: center;
    max-width: 95%;

    .test-drive-title-box {
        padding-bottom: 3rem;

        .test-drive-title {
            padding-bottom: 1.5rem;

            h1 {
                color: black;
                font-size: 48px;
                font-family: 'CadillacGothic-WideRegular';
                font-weight: 400;
                text-transform: uppercase;
                line-height: 52px;
                letter-spacing: 3px;
                word-wrap: break-word;
            }
        }

        .test-drive-subtitle {
            padding-bottom: 1.5rem;

            p {
                margin: 0;
                color: #3A3A3A;
                font-size: 16px;
                font-weight: 400;
                line-height: 22px;
                word-wrap: break-word
            }
        }
    }

    .test-drive-img {
        img {
            width: 100%;
        }
    }

    .test-dirve-selection-container {
        max-width: 1000px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 5rem 2rem 4rem 2rem;
        margin: 0 auto;

        .personal-info-container, .location-container, .select-country-container, .model-trim-container, .date-time-container {
            width: 100%;
            padding: 2rem;
            margin-bottom: 1.5rem;
            border: 1px #E9ECEF solid;

            .left-panel {

                h6 {
                    display: flex;
                    flex-direction: column;
                    text-align: left;
                    margin: 0;
                    color: #060606;
                    font-size: 24px;
                    font-weight: 400;
                    text-transform: uppercase;
                    line-height: 32px;
                    letter-spacing: 3px;
                    word-wrap: break-word
                }
            }

            .input-container {
                /*                display: flex;
                flex-direction: row;
                flex-wrap: wrap;*/
                padding-bottom: 10px;
                text-align: left;

                .input-item {
                    width: 100%;
                    margin-top: 0;
                    /*margin-bottom: 1rem;*/
                    height: 56px !important;

                    .wrapper-box {

                        input {
                            border: none;
                            background-color: #F6F6F4;

                            &:hover {
                                border: none;
                            }

                            &:-webkit-autofill {
                                -webkit-box-shadow: 0 0 0 50px #f6f6f4 inset;
                            }
                        }

                        .car-brand-input {
                            background-color: #fff;
                            padding: 1rem 1.5rem;
                            border: 1px #E9ECEF solid;

                            &:hover {
                                border: 1px #E9ECEF solid;
                            }
                        }
                    }

                    &:nth-child(1), &:nth-child(2) {
                        flex-basis: 50%;
                    }

                    &:nth-child(1) {
                        // padding-right: .5rem;
                    }

                    &:nth-child(2) {
                        padding-left: .5rem;
                    }

                    &:last-child {
                        margin: 0;
                    }
                }

                .input-item.no-margin {
                    margin: 0;
                }

                label {
                    top: 1rem;
                }
            }

            .p-dropdown, .p-calendar {
                height: 56px !important;
            }

            .p-dropdown-label {
                padding-left: 0;
                border: none;
                font-family: var(--global-font-family-medium);

                &:hover {
                    border: none;
                }
            }

            .p-calendar {
                border: none !important;

                &:hover {
                    border: none;
                }

                input, button {
                    background-color: #F6F6F4;
                }

                input {
                    padding-top: 1.1rem;
                }
            }
        }

        .checkbox-privacy-container {
            .checkbox-container {
                border-bottom: none;
            }
            .privacy-container {
                width: 100%;
                border-top: 1px solid #d2d2d2;
            }
        }

        .personal-info-container {
        }

        .select-country-container, .location-container, .date-time-container {
            .dropdown-panel {
                position: relative;

                label {
                    position: absolute;
                    top: 15px;
                    height: 0;
                    color: #a9a9a9;
                    transform-origin: 0rem 1rem;
                    transition: transform 0.2s ease-in-out;
                    pointer-events: none;
                }

                .location-option-label.selected, .time-picked-label.selected {
                    transform: translateY(-.85em) scale(0.7);
                }
            }

            .p-dropdown-label {
                padding-top: 1.5rem;
                color: #282828;
            }
        }

        .location-container {
            label {
                left: 28px;
            }
        }

        .date-time-container {
            label {
                left: 16px;
            }
        }

        .select-country-container {
            .dropdown-panel {
                position: relative;

                label {
                    position: absolute;
                    top: 15px;
                    left: 26px;
                    height: 0;
                    color: #a9a9a9;
                    transform-origin: 0rem 1rem;
                    transition: transform 0.2s ease-in-out;
                    pointer-events: none;
                }

                .location-option-label.selected {
                    transform: translateY(-.85em) scale(0.7);
                }
            }
        }

        .model-trim-container {

            .dropdown-panel.choose-country {
                .p-dropdown-label {
                    padding-top: 1.5rem;
                    color: #282828;
                }
            }

            
           

            
            .input-item {
                margin: 0;
            }

            input {
                background-color: #fff;
                border: 1px solid #d2d2d2;
            }

            .select-trim-dropdown {
                flex-basis: 49%;
                margin-top: 0;
                height: 64px;
                background-color: #fff;
                border: 1px solid #d2d2d2;
                margin: 0;
            }
        }

        .date-time-container {
            .p-dropdown {
                margin-top: 1rem;
            }
        }

        .checkbox-privacy-container {
            padding: 2rem 3rem;
        }

        .checkbox-container, .privacy-container {
            text-align: left;
        }

        .checkbox-container {
            padding: 0rem 0 1.5rem 0;
            border-bottom: 1px solid #d2d2d2;

            .checkbox-item {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin-bottom: 1.5rem;

                .checkbox-content {
                    display: flex;
                    align-items: center;
                }

                small {
                    padding-left: 2.3rem;
                    padding-top: 4px;
                }
            }

            label {
                margin-left: 1rem;
                font-size: 14px;
            }
        }

        .privacy-container {
            padding: 3rem 0 1rem 0;

            p {
                color: #3A3A3A;
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0.50px;
                word-wrap: break-word;

                a {
                    cursor: pointer;
                    font-weight: bold;
                    text-decoration: underline;
                }

                &:nth-child(2) {
                    margin-bottom: .5rem;
                }
            }
        }

        .variant-btn-panel {
            width: 260px;
            margin: 0;
            padding: 2rem 0;

            button {
                padding: 1rem 1.5rem;
                background: #282828;
                
                border-radius: 4px;
                gap: 8px;
                margin: 0;
                display: flex;
                align-items: center;
                justify-content: center;

                h6 {
                    margin: 0;
                    color: #fff;
                    font-size: 14px;
                    font-weight: 400;
                    text-transform: uppercase;
                    line-height: 22px;
                    letter-spacing: 2px;
                    word-wrap: break-word
                }
            }
        }
    }



    @media screen and (max-width: 768px) {
        .test-drive-container {
            padding: 10rem 0;
        }

        .test-dirve-selection-container {
            padding: 1rem 0rem 1rem 0rem;

            .personal-info-container, .location-container, .select-country-container, .model-trim-container, .date-time-container {
                padding: 0.5em;

                .left-panel {

                    h6 {
                        font-size: 16px;
                    }
                }
            }
        }

        .test-drive-title-box {

            .test-drive-title {
                h1 {
                    font-size: 30px;
                }
            }
        }

        .dropdown-panel {
            margin-top: 10px;
        }

        .test-dirve-selection-container {

            .checkbox-privacy-container {
                padding: 0.5rem;
            }
        }
    }
}

.reschedule-panel-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1340px;
    padding: 1rem 2rem;
    background: #F6F6F4;
    gap: 24px;
    margin-top: 3rem;

    .current-schedule-info-panel {
        display: flex;
        flex-direction: row;

        .current-schedule-info-item {
            padding: 0 1rem;
            border-left: 2px solid #282828;

            &:first-child {
                padding-left: 0;
                border-left: none;

                img {
                    padding-right: .5rem;
                }
            }
        }
    }

    .reschedule-control-panel {
        display: flex;
        flex-direction: row;
        align-items: center;

        .variant-btn-panel {
            margin: 0;

            .variant-btn {
                margin: 0;
                background-color: #282828;
                font-size: 14px;
                font-weight: 400;
                text-transform: uppercase;
                line-height: 22px;
                letter-spacing: 2px;
                word-wrap: break-word;
                padding: 1rem 1.5rem;
                text-align: center;
                border: none;

                span {
                    color: #282828;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 22px;
                    word-wrap: break-word
                }

                h6 {
                    margin: 0;
                    color: #F6F6F4;
                    font-size: 14px;
                    font-weight: 400;
                    text-transform: uppercase;
                    line-height: 22px;
                    letter-spacing: 2px;
                    word-wrap: break-word;
                    min-width: 240px;
                }
            }
        }

        p {
            margin: 0;
            color: #505050;
            font-size: 16px;
            font-weight: 400;
            text-decoration: underline;
            line-height: 22px;
            word-wrap: break-word;
            padding-right: 1rem;
            width: fit-content;
            cursor: pointer;
        }
    }
}


.test-drive-success-dialog {
    width: 70%;
    height: 725px;
    position: relative;
    .p-dialog-header {
        display: none;
    }
    .p-dialog-content {
        background: linear-gradient(0deg, #F6F6F4 0%, #F6F6F4 100%);
        padding: 0;
    }
    .test-drive-success-container {
        display: flex;
        flex-direction: row;
        height: 100%;
        .successful-details-panel {
            flex-basis: 50%;
            padding: 3rem;
            
            .successful-details-title {
                text-align: center;
                padding-bottom: 36px;
                height: fit-content;
                h1 {
                    color: black;
                    font-size: 36px;
                    font-weight: 400;
                    font-family: 'CadillacGothic-WideRegular';
                    text-transform: uppercase;
                    line-height: 52px;
                    letter-spacing: 1px;
                    word-wrap: break-word;
                    margin-bottom: 1rem;
                }
                p {
                    color: #3A3A3A;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 22px;
                    word-wrap: break-word;
                    margin: 0;
                }
            }
            .successful-details-content {
                padding: 1.5rem 1rem;
                border: 1px solid #E9ECEF;
                height: fit-content;
                .booking-item {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: .5rem 0;
                    margin-bottom: 1rem;
                    .item-name {
                        flex-basis: 50%;
                        color: #707070;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 18px;
                        letter-spacing: 0.50px;
                        word-wrap: break-word
                    }
                    .item-value {
                        flex-basis: 50%;
                        color: black;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 22px;
                        word-wrap: break-word
                    }
                }
                .booking-item.last-item {
                    margin: 0;
                }
            }
        }
        .successful-img-panel {
            flex-basis: 50%;
            overflow: hidden;
            display: flex;
            justify-content: center;
            img {
                height: 101%;
                object-fit: cover;
                // object-position: center;
                transform: scaleX(-1);
            }
        }
    }

    .cross-box {
        position: absolute;
        right: 20px;
        top: 20px;
        width: 26px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: #F6F6F4;
        cursor: pointer;
    }
    .close-icon {
        
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {

}

@media screen and (max-width: 767.98px) {
    .test-drive-success-dialog {
        width: 90%;
        height: fit-content;
        position: relative;
        .p-dialog-header {
            display: none;
        }
        .p-dialog-content {
            background: linear-gradient(0deg, #F6F6F4 0%, #F6F6F4 100%);
            padding: 0;
        }
        .test-drive-success-container {
            display: flex;
            flex-direction: row;
            height: 100%;
            width: 100%;
            .successful-details-panel {
                width: 100%;
                padding: 1.5rem;
                flex-basis: 100%;
                .successful-details-title {
                    text-align: center;
                    padding-bottom: 1.5rem;
                    height: fit-content;
                    h1 {
                        color: black;
                        font-size: 22px;
                        font-weight: 400;
                        font-family: 'CadillacGothic-WideRegular';
                        text-transform: uppercase;
                        line-height: 32px;
                    }
                    p {
                        color: #3A3A3A;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 22px;
                        word-wrap: break-word;
                        margin: 0;
                    }
                }
                .successful-details-content {
                    padding: 1.5rem 1rem;
                    border: 1px solid #E9ECEF;
                    height: fit-content;
                    .booking-item {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        padding: .5rem 0;
                        margin-bottom: 1rem;
                        .item-name {
                            flex-basis: 50%;
                            color: #707070;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 18px;
                            letter-spacing: 0.50px;
                            word-wrap: break-word
                        }
                        .item-value {
                            font-size: 16px;
                            max-width: 50%;
                        }
                    }
                    .booking-item.last-item {
                        margin: 0;
                    }
                }
            }
            .successful-img-panel {
                flex-basis: 50%;
                overflow: hidden;
                display: flex;
                justify-content: center;
                img {
                    height: 101%;
                    object-fit: cover;
                    // object-position: center;
                    transform: scaleX(-1);
                }
            }
        }
    
        .cross-box {
            position: absolute;
            right: 20px;
            top: 20px;
            width: 26px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background-color: #F6F6F4;
            cursor: pointer;
        }
        .close-icon {
            
        }
    }
}




