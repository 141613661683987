.maintenance-page {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9); /* Semi-transparent black */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure the overlay is above all content */
    /* Text content styling */
    .overlay-content {
        text-align: center;
        color: white;
        padding: 0 1%;
        /*font-family: Arial, sans-serif;*/
        img {
            /*        width: 200px;*/
            width: 50%;
            max-width: 500px;
            height: auto;
            margin-bottom: 30px;
            /*filter: invert(100%);*/
        }

        h1 {
            font-size: 3rem;
            margin-top: 2rem;
            margin-bottom: 2rem;
            color: white;
        }

        p {
            font-size: 1.25rem;
            margin-bottom: 30px;
            color: white;
        }
        /* Button styling */
        button {
            font-size: 1rem;
            padding: 15px 30px; /* Increased padding for more spacing */
            background-color: transparent;
            color: white;
            border: 2px solid white; /* White border */
            cursor: pointer;
            transition: background-color 0.3s ease, border-color 0.3s ease;
            margin-top: 20px; /* Added spacing above the button */
        }
        /* Button hover effect */
        button:hover {
            background-color: white;
            color: black;
            border-color: white;
        }
    }
    /* Responsive typography */
    @media (max-width: 768px) {
        .overlay-content h1 {
            font-size: 2rem;
        }

        .overlay-content p {
            font-size: 1rem;
        }

        .overlay-content button {
            font-size: 0.9rem;
            padding: 12px 25px; /* Adjust padding for smaller screens */
        }
    }
}
