.profiles-container {
    padding-top: 3rem;
    padding-bottom: 3rem;
    max-width: 1280px;
    width: 95%;

    .profiles-header {
        h2 {
        }
    }

    .profiles-userinfo-container {
        /*padding-top: 3rem;*/
        padding-bottom: 2rem;

        .userinfo-header {
            background-color: #f1f1f1;
            padding: 20px 20px 16px 20px;
            border-top: 12px solid var(--global-dark-red-color);
            margin-bottom: 1.5rem;

            h6 {
                font-weight: 600;
            }

            ul {
                padding-top: .5rem;
                line-height: 2.5rem;
                margin: 0;
                padding-left: 1.5rem;

                li {
                    color: var(--global-dark-red-color);
                }
            }
        }
    }

    .form-table {
        padding-top: 1.5rem;
    }

    .why-collect {
    }

    .change-username-container, .contact-phone-container, .primary-address-container, .other-address-container, .identification-container {
        padding-bottom: 4rem;
        // max-width: 980px;
        .profile-section-title {
            font-size: 24px;
            padding-bottom: 1rem;
        }

        .section-desc {
            margin: 0px;
            font-size: 14px;
            min-height:63px;
        }
    }

    .desc-input-wrapper {
        display: flex;
        flex-direction: column;
    }



    .contact-phone-container, .primary-address-container {
        .phone-list-container, .primary-address-list-container {
            padding-bottom: .5rem;
        }

        .row {
            width: 97%;
        }

        .phone-item-box, .primary-address-item-box {
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
            justify-content: space-between;

            .form-table {
                padding-top: .75rem;
            }

            button {
                // margin-top: 1.5rem;
                height: 40px;
            }

            .input-item {
                // padding-top: .5rem;
                margin-bottom: 1rem;
            }

            small {
                top: 3.9rem;
            }
        }

        .primary-address-item-box {
            padding-bottom: .5rem;
            flex-direction: row;
        }

        .phone-item-box {
            flex-direction: column;
        }
    }

    .other-address-container {
        .section-header {
            padding: 3rem;
            background-color: rgb(192, 190, 190);
        }
    }

    .input-item {
        width: 100%;
        // height: 4.5rem;
        .wrapper-box {
            position: relative;

            .p-calendar {
                border-bottom: none;

                input {
                    border-bottom: none;

                    &:hover {
                        border-bottom: none;
                    }
                }

                .p-inputtext {
                    border: none !important;
                }
            }

            label {
                position: absolute;
                top: 1.3rem;
                left: 1rem;
                height: 0;
                color: #a9a9a9;
                transform-origin: 0 0;
                transition: transform 0.2s ease-in-out;
                pointer-events: none;
            }
        }
    }
    // label {
    //     top: 1.5rem !important;
    // }

    .delete-icon {
        width: 30px;
        height: 30px;
        cursor: pointer;
    }

    .add-btn {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        width: fit-content;

        img {
            width: 28px;
            height: 28px;
            margin-right: 1rem;
        }

        a {
            text-decoration: underline;
        }
    }

    .global-location-dropdown {
        height: 64px;
    }



    .btn-update-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        .btn-update {
            width: 300px;
            display: flex;
            justify-content: center;
            background-color: #282828;
            color: #fff;
            border: 2px solid #E9ECEF;
        }
    }
}


@media screen and (max-width: 767.98px) {
    .profiles-container {
        padding-left: 1rem;
        padding-right: 1rem;

        .btn-update-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding-bottom: 4rem;
            .btn-update {
                width: 100%;
                padding: 1rem 1.5rem;
            }
        }

        .contact-phone-container {
            padding-bottom: 3rem;
        }
    }
}