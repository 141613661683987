.my-orders-container {
    padding: 3rem 5%;

    .my-orders-title {
        text-align: center;
        padding-bottom: 3rem;

        h2 {
            margin: 0;
            color: black;
            font-size: 40px;
            font-weight: 400;
            text-transform: uppercase;
            line-height: 48px;
            letter-spacing: 3px;
            word-wrap: break-word;
            padding-bottom: 1.5rem;
        }
    }

    .orders-container {
        .order-item {
            margin-bottom: 2rem;

            .order-item-inner {
                padding: 1.5rem;
                border: 1px #E9ECEF solid;
                border-radius: 4px;

                .order-item-header {
                    position: relative;

                    .order-item-title {
                        h6 {
                            color: black;
                            font-size: 20px;
                            font-weight: 400;
                            text-transform: uppercase;
                            line-height: 32px;
                            letter-spacing: 2px;
                            word-wrap: break-word;
                            margin: 0;
                        }

                        p {
                            color: #707070;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 18px;
                            letter-spacing: 0.50px;
                            word-wrap: break-word;
                            padding: .5rem 0;
                            margin: 0;
                        }
                    }

                    .action-reminder-box {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        padding-top: .5rem;
                        height: 1rem;

                        p {
                            margin: 0;
                            color: #B50000;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 18px;
                            letter-spacing: 0.50px;
                            word-wrap: break-word;
                        }

                        img {
                            width: 12px;
                            height: 12px;
                            margin-left: .5rem;
                        }
                    }
                }

                .variant-btn-panel {
                    margin-bottom: 0;

                    .variant-btn {
                        background: #060606 !important;
                        border-radius: 4px;
                        border: 2px #060606 solid;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 8px;

                        &:nth-child(1) {
                            margin-right: 1rem;
                        }

                        h6 {
                            margin: 0;
                            color: white;
                            font-size: 14px;
                            font-weight: 400;
                            text-transform: uppercase;
                            line-height: 22px;
                            letter-spacing: 2px;
                            word-wrap: break-word
                        }
                    }
                    // .variant-btn.save {
                    //     background: white;
                    //     border: 1px solid #060606;
                    //     margin-right: 1rem;
                    //     h6 {
                    //         color: #060606;
                    //     }
                    // }

                    .variant-btn.view-details {
                        background: #fff !important;

                        h6 {
                            color: black !important;
                        }
                    }
                }

                .carousel-panel {
                    aspect-ratio: 2100/1200;
                    margin: 1.5rem 0;

                    .carousel {
                        height: 100%;
                        // cursor: pointer;

                        .carousel-inner {
                            height: 100%;

                            .carousel-item {
                                height: 100%;
                                position: relative;

                                img {
                                    height: 100%;
                                    position: absolute;
                                    left: 50%;
                                    transform: translateX(-50%);
                                }
                            }
                        }

                        .carousel-control-prev, .carousel-control-next {
                            width: auto;
                            display: none;
                        }

                        .carousel-indicators {
                            display: none;
                        }

                        .button-prev, .button-next {
                            // width: 20px;
                            // height: 20px;
                            // display: flex;
                            // align-items: center;
                            // img {
                            //     width: 20px;
                            //     height: 20px;
                            // }
                            width: 24px;
                            height: 24px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 12px;
                            background-color: #fff;

                            img {
                                width: 16px;
                                height: 16px;
                            }
                        }

                        .button-prev {
                            margin-left: 8px;
                        }

                        .button-next {
                            justify-content: flex-end;
                            margin-right: 8px;
                        }
                        // .button-next {
                        //     justify-content: flex-end;
                        // }
                    }
                    // img {
                    //     width: 100%;
                    // }
                }

                .order-status {
                    color: black;
                    font-size: 16px;
                    font-weight: 400;
                    font-family: "CadillacGothic-Regular";
                    text-transform: uppercase;
                    line-height: 24px;
                    letter-spacing: 2px;
                    word-wrap: break-word;
                    padding: .5rem 0;
                }
            }
        }
    }
}


.order-details-container {
    padding: 3rem 5%;

    .selection-details {
        width: 100%;
    }
    .order-details-header {
        padding-bottom: .5rem;
        border-bottom: 1px solid #d2d2d2;
        margin-bottom: 1rem;

        .header-panel {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-content: center;
            padding-bottom: 1rem;

            h1 {
                color: black;
                font-size: 40px;
                font-weight: 400;
                text-transform: uppercase;
                line-height: 48px;
                letter-spacing: 3px;
                word-wrap: break-word;
                margin: 0;
            }

            .variant-btn-panel {
                width: 300px;
                margin: 0;

                .variant-btn {
                    background-color: #282828;
                    color: #fff;
                    margin: 0;
                    display: flex;
                    justify-content: center;

                    h6 {
                        margin: 0;
                        color: #F6F6F4;
                        font-size: 14px;
                        font-weight: 400;
                        text-transform: uppercase;
                        line-height: 22px;
                        letter-spacing: 2px;
                        word-wrap: break-word
                    }
                }
            }
        }

        .header-management-panel {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .order-ID-box {
                display: flex;
                align-items: center;
            }

            .right-options-panel {
                display: flex;
                flex-direction: row;

                img {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }

    .details-carousel-content {
        .details-left-carousel-container {
            position: sticky;
            top: 0;
        }

        .details-right-steps-container {
            overflow-y: auto;
            max-height: 68vh;

            &::-webkit-scrollbar {
                width: 0px;
                background: transparent;
            }
        }

        .carousel {
            width: 100%;
            height: 68vh;

            .carousel-inner {
                height: 100%;

                .carousel-item {
                    text-align: center;
                    height: 100%;
                    background-color: #f5f5f5;
                    position: relative;

                    img {
                        position: absolute;
                        top: 50%;
                        width: 100%;
                        left: 0;
                        transform: translateY(-50%);
                        // height: 100%;
                        // margin: 0 auto;
                    }
                }
            }

            .carousel-control-prev {
                justify-content: flex-start;
            }

            .carousel-control-next {
                justify-content: flex-end;
            }

            .button-prev, .button-next {
                img {
                    width: 50px;
                    height: 50px;
                }
            }
        }

        .detail-panel {
            background-color: #f7f7f7;
            padding: 2rem 2rem;

            .detail-item {
                span {
                    font-size: 14px;
                    color: #505050;
                }
            }

            .more-info {
                color: #282828;
                padding-top: .5rem;

                a {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }
    }

    .order-tracking-container {
        padding: 2rem 0;

        h4 {
            padding-bottom: 1.2rem;
        }

        .steps-container {
            margin: 0;
        }
    }

    .provide-details-container {
        padding: 0 2rem 3rem 2rem;

        .subtitle-box {
            padding: 1rem 0;

            p {
                margin: 0;
            }
        }

        .remind-info {
            max-width: 650px;
        }

    }

    .btn-panel {
        padding: 0 2rem 3rem 2rem;
        border-bottom: 1px solid #d2d2d2;

        .variant-btn-panel {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;

            .variant-btn {
                width: 200px !important;
                display: flex;
                justify-content: center;

                &:nth-child(2) {
                    color: #d2d2d2;
                    background-color: #282828;
                    margin-left: 1.5rem;
                }
            }
        }
    }

    .questions-container {
        // margin-top: 2rem;
        padding: 5rem 0 3rem 0;

        .row {
            justify-content: space-between;
        }

        .questions-title-panel {
            h2 {
                padding-bottom: .5rem;
            }

            p {
                width: 300px !important;
            }
        }

        .questions-accordion-panel {
            .questions-accordion {
                .card {
                    border: none;
                }

                .card-header {
                    background-color: #fff;

                    button {
                        display: flex;
                        align-content: center;

                        &:collapse::after {
                        }
                    }
                    span {
                        color: black;
                    }
                }

                .qa-card {
                    p {
                        margin-bottom: 12px;
                    }
                    padding-bottom: 12px;
                }
            }

            .question-accordion-header-btn {
                width: 100%;
                height: 2.5rem;
                padding: 0;
                border: none;
                background-color: transparent;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                img {
                    width: 20px;
                    height: 20px;
                }

                span {
                    padding-left: 1rem;
                    padding-top: .2rem;
                }
            }
        }
    }
}

.order-details-loading-container{
    display:flex;
    justify-content:center;
    align-items:center;
    margin-top:15%;
}
.download-dropdown-container {
    margin-left: 1rem;

    a {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    .download-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        position: relative;
        width: 300px;
        padding-bottom: .5rem;
        cursor: pointer;

        button {
            width: 100%;
            border: none;
            background-color: none;
            background-color: transparent;
            display: flex;
            justify-content: space-between;
            span {
                text-align: left;
            }
        }

        .break-word {
            width: 200px;
            font-size: 14px;
            word-wrap: break-all !important;
            overflow-wrap: break-word !important;
            text-decoration: none;
            color: #282828;
        }

        .download-icon {
            width: 20px;
            height: 20px;
        }
    }

    .dropdown-menu.show {
        padding-top: .7rem;
    }

    .dropdown-item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-bottom: .5rem;

        img {
            width: 25px;
            height: 25px;
            margin-right: .5rem;
        }

        &:nth-child(2), &:nth-child(3) {
            span {
                padding-top: .2rem;
            }
        }

        &:hover {
            text-decoration: none;
        }

        &:last-child {
            span {
                // color: red;
            }
        }
    }

    .dropdown-toggle {
        display: flex;
        align-items: center;
        background-color: #fff;
        border: none;
        color: #282828;

        img {
            width: 25px;
            height: 25px;
            margin-right: 4px;
        }

        span {
            padding-top: .2rem;
        }
    }

    .btn-secondary.dropdown-toggle {
        background-color: #fff;
        border: none;
        color: #282828;

        &:focus {
            box-shadow: none;
        }
    }
}


.request-cancel-dialog {
    .p-dialog-header {
        .p-dialog-title {
            font-family: 'CadillacGothic-Regular';
            color: black;
            font-size: 16px;
            font-weight: 400;
            text-transform: uppercase;
            line-height: 24px;
            letter-spacing: 2px;
            word-wrap: break-word
        }
    }

    .p-dialog-content {

    }

    .request-cancel-container {
        p {
            margin: 0;
            color: #282828;
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            word-wrap: break-word
        }
        .variant-btn-panel {
            margin-bottom: 0;
            .variant-btn {
                display: flex;
                justify-content: center;
                padding: 1rem 1.5rem !important;    
                span {
                    font-family: 'CadillacGothic-Regular';
                    font-size: 14px;
                    font-weight: 400;
                    text-transform: uppercase;
                    line-height: 22px;
                    letter-spacing: 2px;
                    word-wrap: break-word;
                }    
                &:nth-child(1) {
                    background-color: #282828;
                    span {
                        color: #fff;
                    }
                }
/*                &:nth-child(1) {
                    border: 1px solid #282828;
                    margin-bottom: 0;
                    span {
                        color: #282828;
                    }
                }*/
            }
        }

        .error-text {
            padding-top: 1.5rem;
            color: #C70000;
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            word-wrap: break-word
        }
    }
    .success-text {
        color: #1E7A2B;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        word-wrap: break-word
    }
}


.purchasing-steps-container {
    padding: 1.5rem 0rem;
    border: 1px solid #d2d2d2;
    border-radius: .5rem;

    .purchasing-steps-title {
        margin-bottom: 3rem;
        padding: 0 1.5rem;

        h5 {
        }

        span {
            font-size: 14px;
            color: green;
        }
    }


    .variant-btn-panel {
        margin-bottom: 0;

        .variant-btn {
            background-color: #282828;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            height: 3.5rem;

            &:hover {
                border: 1px solid #282828;
            }
        }
    }
}

.pipeline-btn-panel {
    margin-bottom: 0;
    margin-top: 10px;
    width: 100%;

    .pipeline-btn {
        width: 100%;
        background-color: #282828;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        height: 3.5rem;

        h6 {
            margin: 0px;
        }

        &:hover {
            border: 1px solid #282828;
        }
        span {
            font-family: 'CadillacGothic-Regular';
        }
    }
}

.purchasing-steps-accordion {
    height: 100%;
    margin: 0 !important;
    .card {
        border: none;
        padding: 0 1.5rem 2rem 1.5rem;
        position: relative;
        border-radius: 0px !important;

        .steps-line {
            position: absolute;
            top: 18px;
            left: 45.15px;
            width: 1px;
            height: 100%;
            border: 1px dashed #d2d2d2;
            z-index: 1;
        }
    }

    .card-header {
        background-color: #fff;
        border: none;
        padding: 0;

        h1, h4 {
            color: #282828;
            padding: 1.5rem 1rem 0 1rem;
        }

        h1 {
            font-size: 20px;
        }

        h4 {
            font-size: 16px;
            font-weight: 400;
        }
    }

    .card-body {
        position: relative;
        padding: 1rem 0 0 4.3rem;

        span, p {
            color: #3A3A3A;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0.50px;
            word-wrap: break-word;
        }

        .download-contract-wrapper {
            margin-top: 1.5rem;
            width: 100%;

            a {
                color: #282828;
            }
        }


        .sign-contract-box {
            width: 100%;
            height: 100%;
            margin-top: 1.5rem;
            padding: 16px;
            background: #F6F6F4;
            border-radius: 4px;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 8px;
            display: inline-flex;

            img {
                margin-top: 3px;
                width: 16px;
                height: 16px
            }
        }

        .variant-btn-panel {

            .variant-btn {
                margin-top: 1.5rem;
                background-color: #282828;
                font-size: 14px;
                font-weight: 400;
                text-transform: uppercase;
                line-height: 22px;
                letter-spacing: 2px;
                word-wrap: break-word;
                display: flex;
                justify-content: center;

                h6 {
                    color: #F6F6F4;
                    font-size: 14px;
                    font-weight: 400;
                    text-transform: uppercase;
                    line-height: 22px;
                    letter-spacing: 2px;
                    word-wrap: break-word;
                    margin: 0;
                }
            }
        }
    }

    .purchasing-steps-accordion-header-btn {
        width: 100%;
        height: fit-content;
        min-height: 44px;
        padding: 0 !important;
        border: none;
        background-color: transparent;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;

        .img-panel {
            width: 44px;
            min-height: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            // left: 1rem;
            background-color: #fff;
            z-index: 9;

            img {
                width: 20px;
                height: 20px;
            }

            .unfinished-dot {
                width: 8px;
                height: 8px;
                background-color: #d2d2d2;
                border-radius: 2px;
                transition: height 0.4s ease-in-out;
            }

            .current-step-circle {
                width: 44px;
                height: 44px;
                border-radius: 22px;
                background-color: #282828;
                display: flex;
                justify-content: center;
                align-items: center;
                opacity: 1;
                transition: opacity 0.4s ease-in-out;

                .inner-circle {
                    width: 22px;
                    height: 22px;
                    border: 2px solid #fff;
                    border-radius: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .central-dot {
                        width: 8px;
                        height: 8px;
                        background-color: #fff;
                        border-radius: 4px;
                    }
                }
            }
        }

        span {
            padding-left: 68px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            height: 40px;

            p, h6 {
                margin: 0;
            }

            p {
                color: #3A3A3A;
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0.50px;
                word-wrap: break-word;
            }

            h6 {
                color: black;
                font-size: 16px;
                font-family: 'CadillacGothic-NarrowRegular';
                font-weight: 400;
                line-height: 22px;
                word-wrap: break-word
            }

            .unfinished-step {
                color: #707070;
                font-size: 16px;
                font-weight: 400;
                line-height: 22px;
                word-wrap: break-word;
                padding-bottom: 0 !important;
            }

            .current-step, .finished-step {
                color: black;
                font-size: 16px;
                font-weight: 400;
                line-height: 22px;
                word-wrap: break-word;
                padding-bottom: 0 !important;
                text-align: left;
            }
        }
    }
}


.order-details-dialog {
    min-width: 80%;
    height: 95vh;
    position: absolute;
    bottom: 0 !important;
    margin-bottom: 0 !important;
    border-radius: 1rem !important;

    .order-details-dialog-container {
        overflow-y: scroll;
        height: 78vh;
        padding-bottom: 2rem;

        h1 {
            color: black;
            font-size: 40px;
            font-weight: 400;
            text-transform: uppercase;
            line-height: 48px;
            letter-spacing: 3px;
            word-wrap: break-word;
            margin-bottom: 2rem;
        }

        .provide-details-message {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            padding-right: 3rem;
        }
    }

    .p-dialog-title {
        color: black;
        font-size: 24px !important;
        font-weight: 400 !important;
        text-transform: uppercase;
        line-height: 22px;
        letter-spacing: 2px;
        word-wrap: break-word;
        text-align: center;
    }

    .p-dialog-header {
        padding-bottom: 3rem;
        border-radius: 1rem 1rem 0 0;
    }

    .provide-info-container, .personal-info-container, .provide-address-container {
        padding-left: 2rem;
        padding-right: 2rem;

        .provide-info-item {
            padding: 0rem 1rem 1rem 1rem !important;

            .provide-info-inner {
                // padding-bottom: 12px;
                // border-bottom: 1px solid #d2d2d2;
                .collect-title {
                    color: black;
                    font-size: 14px;
                    font-weight: 400;
                    text-transform: capitalize;
                    line-height: 18px;
                    letter-spacing: 0.20px;
                    word-wrap: break-word;
                    margin-bottom: .5rem;
                }

                .collect-reason {
                    font-size: 12px;
                    color: #505050;
                    cursor: pointer;
                }
            }
        }

        .checkbox-panel {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 4px 0 1rem 4px;

            span {
                margin-left: 1rem;
                font-size: 14px;
            }
        }

        .p-checkbox-icon {
            margin: 0 !important;
        }

        .input-item {
            width: 100%;
            margin-top: 0rem;
            height: 54px;
            background: #F6F6F4;
            border-radius: 4px !important;

            .wrapper-box {
                input {
                    color: #757575;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 22px;
                    word-wrap: break-word;
                    border: none !important;

                    &:focus, &:hover {
                        border: none !important;
                        // border: 1px #757575 solid !important;
                    }
                }

                label {
                    top: 1rem;
                }

                .p-dropdown {
                    height: 100%;
                }

                .p-dropdown-label {
                    border: none;
                    padding-top: 6px;
                    padding-left: 0;

                    &:hover {
                        border: none;
                    }
                }

                .p-calendar {
                    input {
                        border: none;
                    }
                }

                .p-inputwrapper {
                    height: 54px;
                }
            }
        }

        .upload-file-wrapper {
            display: none;
        }

        .uplad-file-input {
            caret-color: #f6f6f4;
        }
    }

    .review-contract-dialog-container {
        height: 100%;

        .review-contract-container {
            height: 95vh;
        }
    }

    h3 {
        color: black;
        font-size: 24px !important;
        font-weight: 400 !important;
        text-transform: uppercase;
        line-height: 22px;
        letter-spacing: 2px;
        word-wrap: break-word;
        padding-top: 2rem;
        padding-left: 4px;
        margin-bottom: 1rem !important;
    }

    .provide-address-container {
        padding-left: 2rem;
        padding-right: 2rem;

        .provide-address-checkbox {
        }

        .provide-address-item {
            padding: 0rem 1rem .5rem 1rem;

            .provide-address-inner {
                // .input-item {
                //     width: 100%;
                //     .wrapper-box {
                //         input {
                //         }
                //         .p-dropdown {
                //             height: 64px;
                //             .p-dropdown-label {
                //                 border-bottom: none !important;
                //                 padding: 0;
                //             }
                //         }
                //     }
                // }
            }
        }
    }

    .bank-details-container {
        padding-bottom: 2rem;

        .arrange-title {
            padding-bottom: 1rem;
        }

        p {
            margin-bottom: 0;
        }
    }

    .detail-section {
        padding-bottom: 2rem;

        h6 {
            font-weight: 600;
            margin: 0;
        }

        span {
        }
    }


    .btn-panel {
        padding-left: 2rem;
        padding-right: 2rem;

        .variant-btn-panel {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;

            .variant-btn {
                width: 300px;
                display: flex;
                align-items: center;
                justify-content: center;

                span {
                    font-family: "CadillacGothic-Regular";
                }

                &.btnSubmitProvideDetails {
                    margin-right: 1rem;
                    background-color: #282828;
                    color: #fff;
                }

                &:nth-child(2) {
                    margin-right: 1rem;
                    background-color: #282828;
                    color: #fff;
                }
            }
        }
    }

    .date-picked-label.selected {
        transform: translateY(-.85em) scale(0.7);
    }

    .upload-image {
        width: 100%;
    }
}

.review-contract-dialog {
    min-width: 80%;
    height: 95vh;
    position: absolute;
    bottom: 0 !important;
    margin-bottom: 0 !important;
    border-radius: 1rem !important;

    .p-dialog-header {
        height: 60px;
    }

    .review-contract-dialog-container {
        height: 100%;

        .review-contract-container {
            height: 80%;
        }
    }
    // .input-item {
    //     width: 100%;
    //     margin-top: 0rem;
    //     height: 54px;
    //     background: #F6F6F4;
    //     .wrapper-box {
    //         input {
    //             color: #757575;
    //             font-size: 16px;
    //             font-weight: 400;
    //             line-height: 22px;
    //             word-wrap: break-word;
    //             &:focus {
    //                 border: 1px #757575 solid !important;
    //             }
    //         }
    //         label {
    //             top: 1rem;
    //         }
    //         .p-dropdown {
    //             height: 100%;
    //         }
    //         .p-dropdown-label {
    //             border: none;
    //             padding-top: 6px;
    //             padding-left: 0;
    //             &:hover {
    //                 border: none;
    //             }
    //         }
    //         .p-calendar {
    //             input {
    //                 border: none;
    //             }
    //         }
    //         .p-inputwrapper {
    //             height: 54px;
    //         }
    //     }
    // }

    .fullname-wrapper {
        margin: 20px 0px 5px 0px;
    }

    .consent-wrapper {
        margin: 5px 0px;
    }

    .btn-panel {
        padding-left: 2rem;
        padding-right: 2rem;

        .variant-btn-panel {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;

            .variant-btn {
                width: 300px;
                display: flex;
                align-items: center;
                justify-content: center;

                &:nth-child(1) {
                    margin-right: 1rem;
                    background-color: #282828;
                    color: #fff;
                }
            }
        }
    }

    .date-picked-label.selected {
        transform: translateY(-.85em) scale(0.7);
    }
}

.schedule-steps-dialog {
    min-width: 80%;
    height: 95vh;
    position: absolute;
    bottom: 0 !important;
    margin-bottom: 0 !important;
    border-radius: 1rem !important;
    .p-dialog-header {
        padding-bottom: 3rem;
        border-radius: 1rem 1rem 0 0;
    }
}

.schedule-steps-dialog-container {
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;

    .p-dialog-title {
        font-size: 40px;
    }

    .schedule-steps-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 2rem;

        .steps-panel {
            display: flex;
            flex-direction: row;
            width: 50%;
            align-items: center;
            justify-content: space-between;

            .step-item {
                display: flex;
                flex-direction: row;
                align-items: center;
                cursor: pointer;

                img {
                    width: 30px;
                    height: 30px;
                }

                span {
                    margin-left: 10px;
                }

                .current-step-circle-panel {
                    width: 30px;
                    height: 30px;
                    border-radius: 15px;
                    border: 2px solid #282828;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .inner-circle {
                        width: 20px;
                        height: 20px;
                        border-radius: 10px;
                        background-color: #282828;
                    }
                }

                .next-step-circle-panel {
                    width: 30px;
                    height: 30px;
                    border-radius: 15px;
                    border: 2px solid #d2d2d2;
                }
            }

            .step-line-panel {
                flex: 1 1;
                padding: 0 1rem;

                .step-line {
                    height: 1px;
                    background-color: #282828;
                }
            }
        }

        .variant-btn-panel {
            width: 200px;
            margin: 0;

            .variant-btn {
                display: flex;
                justify-content: center;
                margin: 0;
            }
        }
    }
    .service-centre-items {
        max-height: 560px;
        overflow-y: auto;
        margin-bottom: .8rem;
        border: 1px solid #282828;
        cursor: pointer;

        .delivery-address {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
    }

    .service-centre-items, .need-support-container {
        padding: 1rem;

        h6 {
            font-weight: 600;
        }

        p {
            font-size: 14px;
            margin-bottom: .5rem;
        }

        span {
            font-size: 14px;
        }
    }

    .schedule-options-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding-bottom: 1rem;

        .schedule-options-item {
            flex-basis: 25%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: .5rem 1rem;

            .schedule-options-inner {
                border: 1px solid #bbbaba;
                padding: .5rem 1rem;
                cursor: pointer;

                &.selected {
                    border: 2px solid #282828;
                }
            }
        }
    }

    .pickup-location-container {
        .pickup-location-map {
            padding: 1rem;
            border: 1px solid #d2d2d2;
            margin-bottom: 1rem;

            .pickup-location-map-box {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding-top: .5rem;

                .address-panel {
                    font-size: 18px;
                    font-weight: 600;
                    max-width: 300px;
                }

                .map-panel {
                    width: 500px;
                    height: 150px;
                }
            }
        }

        .pickup-location-schedule {
            padding: 1rem;
            border: 1px solid #d2d2d2;

            .date-and-time-box {
                .date-and-time {
                    height: 150px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    p {
                        margin-bottom: 0.2rem;
                        font-size: 18px;
                        font-weight: 600;
                    }
                }
            }
        }
    }

    .need-support-container {
        background-color: #ebebeb;
    }
}

.finance-application-dialog {
    max-width: 500px;
    .p-dialog-header {
        text-align: center;
        padding-top: 1rem;
        padding-bottom: 1rem;
        position: relative;
        .p-dialog-header-icons {
            position: absolute;
            right: 1.5rem;
        }
    }
    .title-panel {
        padding-bottom: 1rem;
    }
    .variant-btn-panel {
        width: 100%;
        .variant-btn {
            margin: 0 auto;
            width: 300px;
            background-color: #060606;
            border: none;
            color: #fff;
            display: flex;
            justify-content: center;
        }
    }
}


@media screen and (min-width: 768px) and (max-width: 1024px) {

}


@media screen and (max-width: 767.98px) {
    .request-cancel-dialog {
        width: 90%;
    }

    .finance-application-dialog {
        max-width: 90%;
        .title-panel {
            padding-bottom: 1rem;
        }
        .variant-btn-panel {
            width: 100%;
            .variant-btn {
                margin: 0 auto;
                width: 100%;
                max-width: 300px;
                font-family: 'CadillaGothic-Regular';
                background-color: #060606;
                border: none;
                color: #fff;
                display: flex;
                justify-content: center;
            }
        }
    }

    .order-details-container {
        .details-carousel-content {
            .details-left-carousel-container {
                position: relative;
                // top: 0;
            }
    
            .details-right-steps-container {
                overflow-y: auto;
                max-height: 68vh;
    
                &::-webkit-scrollbar {
                    width: 0px;
                    background: transparent;
                }
            }
    
            .carousel {
                width: 100%;
                height: 24vh;
            }

            .header-management-panel {

                .order-ID-box {
                    padding: 1rem 0 .5rem 0;
                    color: black;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 22px;
                    word-wrap: break-word;
                    text-align: center;
                }
    
                .right-options-panel {
                    padding: 1rem .5rem;
                    img {
                        width: 20px;
                        height: 20px;
                    }
                }

                .order-steps-container {
                    .order-steps-inner-container {
                        width: 100%; height: 100%; padding-left: 16px; padding-right: 16px; padding-top: 24px; padding-bottom: 24px; border: 1px #E2E2E2 solid; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 32px; display: inline-flex;
                        .order-panel {
                            align-self: stretch; height: 190px; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 24px; display: flex;
                            .order-date-panel {
                                align-self: stretch; height: 70px; padding-bottom: 24px; border-bottom: 1px #E2E2E2 solid; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 4px; display: flex;
                                .order-id {
                                    width: 287px; color: black; font-size: 16px;  font-weight: 400; text-transform: uppercase; line-height: 24px; letter-spacing: 2px; word-wrap: break-word;
                                }
                                .order-estimate-date {
                                    width: 295px; color: #0A7305; font-size: 14px; font-weight: 400; line-height: 18px; letter-spacing: 0.50px; word-wrap: break-word
                                }
                            }
                            .order-status-panel {
                                align-self: stretch; height: 48px; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 16px; display: flex;
                                .order-status-box {
                                    align-self: stretch; height: 44px; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 4px; display: flex;
                                    .ordered-date {
                                        color: #787878; font-size: 14px; font-weight: 400; line-height: 18px; letter-spacing: 0.50px; word-wrap: break-word
                                    }
                                    .order-status {
                                        color: black; font-size: 16px; font-weight: 400; line-height: 22px; word-wrap: break-word
                                    }
                                }
                                .order-placed-info {
                                    align-self: stretch; color: #282828; font-size: 14px; font-weight: 400; line-height: 18px; letter-spacing: 0.50px; word-wrap: break-word
                                }
                            }  
                        }
                    }
                    .steps-panel {
                        justify-content: flex-start; align-items: center; gap: 8px; display: inline-flex
                    }
                }
            }
        }
    }

    .mobile-steps-dialog {
        margin: 0 !important;
        width: 100% !important;
        .p-dialog-header {
            // display: none;
            border: none;
        }
        .p-dialog-content {
            border: none !important;
            padding-left: .5rem;
            padding-right: .5rem;
            .close-panel {
                padding-bottom: 1rem;
                display: flex;
                justify-content: flex-end;
                img {
                    width: 36px;
                    height: 36px;
                    margin-right: 1rem;
                }
            }
        }
       
    }

    .download-dropdown-container {
        margin-left: 0rem;
        .accordion-item {
            border-bottom: none !important;
            .accordion-header {
                .accordion-button {
                    .download-icon {
                        width: 20px;
                        height: 20px;
                    }
                    span {
                        color: black;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 22px;
                        word-wrap: break-word
                    }
                }
            }
            .accordion-collapse {

            }
            .accordion-body {
                padding: 0 !important;
                border: 1px solid #d2d2d2;
                border-radius: 4px;
                button {
                    padding: 1rem !important;
                }
            }
        }

        
        .download-item {  
            width: 100%;
            button {
                span {
                    text-align: left;
                }
            }
        }
    }

    .order-details-dialog {
        width: 100%;
        height: 100vh;
        .p-dialog-header {
            border-radius: 0;
            padding-bottom: 1.5rem;
            position: relative;
            .p-dialog-title {
                font-family: 'CadillacGothic-Regular';
                height: 2rem;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .p-dialog-header-icons {
                position: absolute;
                right: 1rem;
            }
        }
        .p-dialog-content {
            color: black;
            .order-details-dialog-container {
                padding-top: 2rem;
                height: 100vh;
                &::-webkit-scrollbar {
                    display: none;
                }
            }
            .provide-info-container, .personal-info-container, .provide-address-container , .btn-panel, .provide-details-message.text-error {
                padding-left: 0;
                padding-right: 0;
            }
            .btn-panel {
                padding-top: 2rem;
                .variant-btn-panel {
                    .variant-btn {
                        margin: 0;
                    }
                }
            }
        }
        h3 {
            font-size: 22px !important;
            padding-bottom: .5rem;
        }
        
    }

    .review-contract-dialog {
        min-width: 100%;
        height: 100vh;
        .p-dialog-header {
            padding-top: 1.5rem;
            padding-bottom: 3.5rem;
        }
        .p-dialog-content {
            .review-contract-dialog-container {
                .review-contract-container {
                    height: 72%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .variant-btn-panel {
                        width: 200px;
                        .variant-btn {
                            margin-top: 1.5rem;
                            background-color: #282828;
                            font-size: 14px;
                            font-weight: 400;
                            text-transform: uppercase;
                            line-height: 22px;
                            letter-spacing: 2px;
                            word-wrap: break-word;
                            display: flex;
                            justify-content: center;
                            border-radius: 4px !important;
                            a {
                                color: white;
                                font-size: 14px;
                                font-family: 'CadillacGothic-Regular';
                                font-weight: 400;
                                text-transform: uppercase;
                                line-height: 22px;
                                letter-spacing: 2px;
                                word-wrap: break-word;
                                text-decoration: unset;
                            }
                        }
                    }
                }
            }
        }
    }

    .schedule-steps-dialog {
        width: 100%;
        height: 100vh;
        .p-dialog-header {
            border-radius: 0;
            text-align: center;
            position: relative;
            padding-top: 1rem;
            padding-bottom: 1.5rem;
            // .p-dialog-header-icons {
            //     position: absolute;
            //     right: 1rem;
            // }
        }
        .p-dialog-content {
            padding-left: 0rem;
            padding-right: 0rem;
            padding-bottom: 3rem;
        }
        .schedule-steps-dialog-container {
            width: 100%;
            padding-left: 0rem;
            padding-right: 0rem;
        
            .p-dialog-title {
                font-size: 40px;
            }

            .pickup-location-container {
                padding-left: 1rem;
                padding-right: 1rem;
                .pickup-location-panel {
                    .pickup-location-map-box {
                        display: flex;
                        flex-direction: column-reverse;
                        .map-panel {
                            width: 100%;
                        }
                        .address-panel {
                            width: 100%;
                            max-width: none;
                            margin-top: 1rem;
                            p {
                                color: #787878;
                                font-size: 14px;
                                font-weight: 400;
                                line-height: 18px;
                                letter-spacing: 0.50px;
                                word-wrap: break-word;
                                margin: 1rem 0 .2rem 0;
                            }
                            span {
                                color: black;
                                font-size: 16px;
                                font-weight: 400;
                                line-height: 22px;
                                word-wrap: break-word;
                            }
                        }
                    }
                }
                .pickup-need-help-panel {

                }
            }
        
            .schedule-steps-container {
                padding-bottom: 0;
                .p-tabview {
                    width: 100%;
                    position: absolute;
                    top: 71px;
                    z-index: 99;
                    .p-tabview-panels {
                        padding: 0;
                    }
                }
            }

            .row {
                padding-top: 56px;
                .col-lg-4 {
                    padding: 0 1rem !important;
                }
            }

            .p-unselectable-text {
                min-width: 33%;
                display: flex;
                justify-content: center;
            }
        }
    }
    
    .select-location-popup-container {
        display: flex;
        flex-direction: column-reverse;
        padding-left: 1rem;
        padding-right: 1rem;
        .select-location-popup-context {
            margin-top: 1rem;
        }
        .select-location-popup-map {
            padding-top: 1rem;
        }
    }

    .select-date-time-container {
        display: flex;
        flex-direction: column !important;
        padding-left: 1rem;
        padding-right: 1rem;
        .select-calendar {
            height: 40vh;
        }
        .select-date-time-context {

        }
    }

    .pipeline-btn-panel {
        width: calc(100% - 2.2rem) !important;
        // margin-left: auto;
        // margin-right: 1.1rem;
        // margin-top: 2rem;
        margin: 2rem auto 0;
    }
}