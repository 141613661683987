.mainpage-container {
    position: relative;
    height: 100vh;
    // .main-menu-container {
    //     position: sticky;
    //     top: 0;
    // }
}

.main-page-container {
    // padding-top: 116px;
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: 70% 30%;
    grid-template-rows: auto;
    row-gap: 20px;
    padding-bottom: 1rem;
    .right-sections-container {
        grid-column: 2;
    }
}

.main-page-section-title {
    color: black;
    font-size: 24px;
    font-weight: 400;
    text-transform: uppercase;
    line-height: 32px;
    letter-spacing: 3px;
    word-wrap: break-word;
    margin: 0;
    padding-bottom: 3rem;
}

.main-page-section-title.order-review {
    padding-bottom: 1.5rem !important;
    
}

.nav_item {
    // grid-column: 2;
    // width: 30%;
    width: 100%;
    margin-left: auto;
    margin-right: 0;
    align-items: center;
    padding-left: 3%;
    padding-right: 3%;
    scroll-margin-top: 186px;
    // scroll-snap-align: none;
    // min-height: calc(100vh - 186px);
    position: relative;
    // scroll-snap-align: unset;
    height: fit-content;
}

// .snap {
//     scroll-snap-align: start;
// }

.nav_item.order-review {
    min-height: auto !important;
}

.nav_item.checkoutForm {
    // margin-top: 185px;
    padding-bottom: 60px;
    // position: absolute;
    // top: 186px;
    // right: 0;
}

.nav_item.checkout-btn-panel {
    width: 30%;
    margin-left: auto;
    margin-right: 0;
    align-items: center;
    padding-left: 3%;
    padding-right: 3%;
    min-height: auto;
    position: relative;
    // scroll-snap-align: start;
    height: fit-content;
    .checkout-btn {
        width: 100%;
        border: none;
        box-shadow: none;
        &:hover, &:focus, &:active {
            background-color: black;  
        }
    }
}

.total-amount-container {
    grid-column: 2;
    width: 100%;
    // height: 8rem;
    position: sticky;
    bottom: 0;
    z-index: 9;
    background-color: #fff;
    // margin: 0 1rem 0 1rem;
    margin-left: auto;
    padding: 10px 1rem;
    border-top: 1px solid #ccc;
    .total-amount-box {
        display: flex;
        flex-direction: column;
        padding-bottom: .5rem;
        .price-panel, .finance-panel, .delivery-panel {
            padding-bottom: 0px;
            .inner-div {
                display: flex;
                flex-wrap: nowrap;
                justify-content: space-between;
                align-items: center;
                width: 100%;
            }
        }
        .price-panel {
            .inner-div {
                h6 {
                    letter-spacing: 0;
                }
                h6, span {
                    color: black;
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 26px;
                    word-wrap: break-word;
                    margin: 0 !important;
                }
            }
            
            p {
                font-size: 12px;
            }
        }
        .finance-panel, .delivery-panel {
            h6, span {
                margin: 0rem;
                color: #707070;
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0.50px;
                word-wrap: break-word
            }
            p {
                margin: 0;
                color: #707070;
                width: fit-content;
            }
        }

        .finance-panel, .delivery-panel {
            h6, span, p {
                font-size: 12px;
                color: #707070 !important;
            }
        }
        // .delivery-panel {
        //     display: flex;
        //     flex-direction: row;
        //     justify-content: flex-end;
        // }
    }
    .delivery-pricing-details {
        color: #3A3A3A;
        font-size: 14px;
        font-weight: 400;
        text-decoration: underline;
        line-height: 18px;
        letter-spacing: 0.50px;
        word-wrap: break-word;
        cursor: pointer;
    }
}

.main-layout-container {
    display: flex;
    flex-direction: row;
    position: relative;
    // padding-top: 385px;
    align-items: stretch;
   
    .right-section-container {
        // flex-basis: 30%;
        // margin-left: auto;
        // margin-right: 0;
        // max-height: 100vh;
        // top: calc((100vh - 5rem)*.01 + 3.875rem);
        // .nav_item {
        //     &:nth-child(1) {
        //         padding-top: 30px;
        //     }
        // }
    }
}

.nav-menu {
    grid-column: span 2; /* Menu spans across both columns */
    position: sticky;
    width: 100%;
    top: 26px;
    z-index: 3;
    transition: transform 0.3s ease-in-out; 
    background-color: #fff;
}
.nav-menu.collapsed {
    height: 94px;
    transform: translateY(100%);
}

.transition-group-full {
    width: 100%;
    height: 100%;
    position: relative;
    .img-panel, .interior-enlarged, .interior {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;                
        position: relative;
        .image {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
        > img + img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            cursor: pointer;
            position: absolute;
            left: 0;
            opacity: 0;
            z-index: 0;
            transition: opacity .75s ease-in-out;
        }

        &.active > img + img {
            opacity: 1;
            transition: opacity .75s ease-in-out;
            z-index: 1;
        }
    }
    .interior-enlarged, .interior {
        .image {
            object-fit: cover;
            height: 100%;
        }
        > img + img {
            object-fit: cover;
        }
    }
    .interior-enlarged {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            width: 100%;
            height: auto;
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .nav-menu.collapsed {
        top: 54px;
        height: 84px;
        transform: translateY(100%);
    }

    .nav_item {
        scroll-margin-top: 224px;
    }

    .nav_item.checkoutForm {
        margin-top: 32px;
        .back-to-configuration {
            
        }
    }
}

@media screen and (max-width: 767.98px) {
    .nav-menu.collapsed {
        top: 30px;
        height: 64px !important;
        transform: translateY(100%);
        .main-menu {
            padding-top: 0;
        }
    }

    .logo-container {
        overflow-x: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
        .certainty-panel {
            &:last-child {
                width: 105px;
            }
        }
    }

    .nav_item { 
        padding-left: 1rem;
        padding-right: 1rem;
        scroll-margin-top: 280px;
        &:nth-child(1) {
            scroll-margin-top: 156px;
        }
        &:nth-child(2) {
            scroll-margin-top: 260px;
        }
    }
    .nav_item.checkoutForm {
        margin-top: 90px;
        .back-to-configuration {
            
        }
    }

    .menu-items {
        .certainty-panel {
            min-width: 100px;
            margin-left: 1.5rem !important;
        }
    }
    
    .main-page-container {
        position: relative;
        .right-sections-container {
            grid-column: span 2; //set main sections as full width
        }

        .mobile-main-section-images-container {
            grid-column: span 2; //set carousel as full width
            position: fixed;
            top: 156px;
            height: 13vh;
            width: 100%;
            z-index: 999;
            background-color: #fff;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            overflow-x: scroll;
            overflow-y: hidden;
            padding: 0 1rem;
            gap: 1%;
            &::-webkit-scrollbar {
                display: none;  /* Chrome, Safari, and Opera */
            }
            .mobile-vehicle-image-card {
                flex-basis: 49% ;
                flex-shrink: 0; /* Prevent items from shrinking */
                background-color: #F6F6F4;
                display: flex;
                align-items: center;
                img {
                    width: 100%;
                }
            }
        }
        .mobile-main-section-images-container.carousel {
            height: 26vh;
            .mobile-main-section-images-inner {
                position: relative;
                width: 100%;
                height: 100%;
                .carousel {
                    width: 100%;
                    height: 26vh;
                    position: relative;
                    .carousel-inner {
                        width: 100%;
                        height: 100%;
                        .carousel-item, .carousel-item.active {
                            width: 100%;
                            height: 100%;
                            overflow: hidden;
                            position: relative;
                            .image {
                                position: absolute;
                                width: 100%;
                                left: 0;
                                top: 50%;
                                transform: translateY(-50%);
                            }
                        }
                    }
                    .carousel-control-prev, .carousel-control-next {
                        height: 30%;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        img {
                            width: 30px;
                            height: 30px;
                        }
                    }
                }
                .close-icon {
                    position: absolute;
                    top: 1.5rem;
                    right: 1.5rem;
                    width: 18px;
                    height: 18px;
                }
            }
           
            .p-carousel {
                .p-carousel-content {
                    .p-carousel-container {
                        .p-carousel-items-content {
                            .p-carousel-item {
                                .product-item {
                                    .product-item-content {
                                        // max-width: 47%;
                                        img {
                                            width: 100%;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
           
        }
    }

    .total-amount-container {
        grid-column: span 2;
        position: fixed;
        bottom: 0;
        transition: all 1s ease-in-out;
    }   

    .total-amount-container.hide {
        display: none;
    }
}