.additional-container {
    // h3 {
    //     font-size: 24px;
    // }
    .main-page-section-title {
        padding-bottom: 0;
    }
    .additional-title {
        margin: 0;
    }
    .additional-desc {
        padding: 1.5rem 0;
        color: #3A3A3A;
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        word-wrap: break-word;
        margin: 0;
    }
    .p-card {
        margin-top: 1.5rem;
        color: #282828;
        box-shadow: none;
        padding: 1rem !important;
        // .p-card-header {
        //     img {
        //         aspect-ratio: 3500/900;
        //         object-fit: cover;
        //     }
        // }
       
        .card-inner {
            display: flex;
            flex-direction: column;
            .card-header-img {
                padding-bottom: 1.2rem;
                cursor: pointer;
                img {
                    aspect-ratio: 2700/900;
                    width: 100%;
                    object-fit: cover;
                }
            }
            .checkbox-panel {
                display: flex;
                flex-direction: row;
                width: 100%;
                label {
                    width: 100%;
                    cursor: pointer;
                }
            }
        }
        .card-inner.charging {
            display: flex;
            flex-direction: column;
            padding-bottom: 1rem;
            border-bottom: 1px solid #d2d2d2;
        }
        .card-details {
            display: flex;
            justify-content: flex-end;
            span {
                padding: 1rem 0 0 0;
                color: #282828;
                cursor: pointer;
            }
        }
        .p-card-body {
            padding: 0;
        }
        .p-card-content {
            font-family: 'CadillacGothic-Regular';
            padding: 0;
            // border-bottom: 1px solid #d2d2d2;
            .card-text-panel {
                margin-left: 1rem;
                p {
                    margin: 0; 
                    color: black;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 22px;
                    word-wrap: break-word
                }
                .card-info-selection {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    button {
                        img {
                            width: 20px;
                            height: 20px;
                            margin-right: 1rem;
                        }
                    }
                    .p-button {
                        padding: .5rem 1.5rem;
                        border: 1px solid #282828 !important;
                    }
                    span {
                        // font-family: 'CadillacGothic-Medium';
                        color: #707070;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 22px;
                        word-wrap: break-word
                    }
                }
            }
          
        }
    }
}

.charging-popup-container {
    display: flex;
    align-items: center;
    padding: 12px 0 0 0;
    cursor: pointer;
    span {
        padding: 0 !important; 
    }
    img {
        margin-left: 8px;
        width:100%;
    }
}

.charging-popup-dialog {
    width: 60%;
    .p-dialog-header {
        .p-dialog-title {
            color: black;
            font-size: 12px;
            font-weight: 400;
            font-family: 'CadillacGothic-Regular';
            text-transform: uppercase;
            line-height: 20px;
            letter-spacing: 2px;
            word-wrap: break-word;
        }
    }
    .charging-popup-content-container {
        .charging-popup-carousel-wrapper {
            aspect-ratio: 500/450;
            overflow: hidden;
            .carousel.slide {
                height: 100%;
                .carousel-inner {
                    height: 100%;
                    .carousel-item {
                        height: 100%;
                        img {
                            object-fit: cover;
                            width: 100%; 
                            height: 100%; 
                        }
                    }
                }
            }
            .img-item {
                height: 100%;
                img {
                    height: 100%;
                }
               
            }
        }
        .charging-popup-content-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .content-title-desc-container {
                h5 {
                    color: black;
                    font-size: 16px;
                    font-weight: 400;
                    text-transform: uppercase;
                    line-height: 24px;
                    letter-spacing: 2px;
                    word-wrap: break-word;
                    margin-bottom: 1rem;
                }
                p {
                    color: black;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 22px;
                    word-wrap: break-word;
                    margin-bottom: 0rem;
                }
            }
            .bottom-mgmt-container {
                p {
                    color: #707070;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 22px;
                    word-wrap: break-word;
                    margin-bottom: 1rem;
                }
                .price-panel {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    padding: 20px 0;
                    span {
                        color: #060606;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 22px;
                        word-wrap: break-word
                    }
                }
                button {
                    border: 1px solid #282828;
                }
            }
        }
    }

    .charging-disclaimer {
        margin-top: 1rem;
        padding-top: .5rem;
        border-top: 1px solid #d2d2d2;
        font-size: 14px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {


}
    
@media screen and (max-width: 767.98px) {  
    .charging-popup-dialog {
        width: 90%;
        .p-dialog-header {
            .p-dialog-title {
                color: black;
                font-size: 12px;
                font-weight: 400;
                font-family: 'CadillacGothic-Regular';
                text-transform: uppercase;
                line-height: 20px;
                letter-spacing: 2px;
                word-wrap: break-word;
            }
        }
        .charging-popup-content-container {
            .charging-popup-carousel-wrapper {
                
            }
            .charging-popup-content-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding-top: 1rem;
            }
        }
    }
    
}