.payment-options-dialog {
    width: 80%;
    height: 95vh;
    position: absolute;
    bottom: 0;
    margin-bottom: 0 !important;

    h1 {
        margin-bottom: 1.5rem;
    }

    .payment-options-scrollbar-container {
        overflow-y: scroll;
        height: 85vh;
        padding-left: 5%;
        padding-right: 5%;
    }

    .finance-desc {
        margin: 0;
        padding-bottom: .5rem;
        color: black;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        word-wrap: break-word
    }

    .p-dialog-header {
        border-radius: 1rem 1rem 0 0;
    }

    .p-dialog-content {
        padding: 0;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .payment-options-btn-panel {
        display: flex;
        flex-direction: row;
        margin: 1rem 0;
        width: 100%;
        // background-color: rgb(221, 221, 221);
        .payment-btn {
            flex-basis: 50%;
            box-shadow: none;
            background-color: transparent;
            color: #282828;
            // background-color: rgb(221, 221, 221);
            border: 1px #E9ECEF solid;
            height: 3rem;
            line-height: 1.375rem;
            min-width: 6rem;
            cursor: pointer;
            transition: all .25s linear;
            min-height: 3rem;
            max-height: 5rem;
            // text-transform: uppercase;
            letter-spacing: 1px;
            font-size: 1rem;
            font-weight: 400;
            padding-left: 16px;
            padding-right: 16px;

            &:hover {
                border: none;
                background-color: #ddd;
            }
        }

        .payment-btn.selected {
            background-color: #fff;
            color: #282828;
            border: 2px black solid;
        }
    }

    .dropdown-container {
        .dropdown-panel {
            position: relative;
        }

        .p-dropdown {
            margin-top: 8px;
        }

        label {
            position: absolute;
            top: 28px;
            left: 36px;
            height: 0;
            color: #a9a9a9;
            transform-origin: 0rem 1rem;
            transition: transform 0.2s ease-in-out;
            pointer-events: none;
        }

        .select-state-label.selected, .registration-type-label.selected, .term-label.selected, .balloon-payment-label.selected, .payment-frequency-label.selected {
            transform: translateY(-1em) scale(0.7);
        }

        .p-dropdown-label {
            padding-top: 1.5rem;
        }

        span {
            color: #060606;
        }
    }

    .cash-container, .finance-container {
        .row {
            justify-content: center;
            margin-bottom: 1rem;

            h6 {
                margin-bottom: 0;
                color: #060606;
            }
        }

        p {
            color: #505050;
        }

        .driveaway-price-card {
            margin-bottom: 1rem;
    
            .card-title {
                color: #707070;
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0.50px;
                word-wrap: break-word
            }
    
            .details-desc {
                display: flex;
                justify-content: space-between;
                flex-direction: row;
                span {
                    color: black;
                }
            }
    
            .driveaway-price-row-item {
                span {
                    color: black;
                    /*                font-size: 16px;
                    font-weight: 400;
                    line-height: 22px;
                    word-wrap: break-word*/
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 26px;
                    word-wrap: break-word
                }
    
                .price {
                    color: #505050;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 22px;
                    word-wrap: break-word
                }
    
                .total-price {
                    color: #060606;
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 26px;
                    word-wrap: break-word
                }
            }
    
            .driveaway-price-row-item.bold {
                font-weight: 600;
    
                span {
                    color: #060606;
                    font-family: 'CadillacGothic-Regular';
                    letter-spacing: 1px;
                    font-size: 16px;
                    line-height: 22px;
                    word-wrap: break-word
                }
            }
        }
    }

    .finance-container {
        .input-item {
            width: 100% !important;
            height: 3.8rem;
            margin: 0;

            .wrapper-box {
                position: relative;

                i {
                    position: absolute;
                    top: 2.55rem;
                    left: 1.5rem;
                }

                .p-inputtext {
                    padding-left: 1.5rem;
                    margin-top: 8px;
                    height: 3.8rem;
                }

                label {
                    left: 1.5rem;
                    top: 1.5rem;
                }

                input {
                    border: none;
                }
            }

            .wrapper-box.p-input-icon-left {
                i {
                    font-style: normal;
                }

                .p-inputtext {
                    padding-left: 2.5rem;
                }
            }

            .wrapper-box.disabled {
                .p-inputtext {
                    // background-color: rgb(179, 179, 179);
                    background-color: transparent;
                }
            }

            .nz-note-wrapper {
                color: #505050;
            }
        }
    }

    .payment-display-panel {
        height: fit-content;
        border-bottom: 1px solid #ddd;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 1rem;

        .accordion {
            .card {
                border: none;

                .card-header {
                    background-color: #fff;
                    position: relative;
                    border: none;
                    padding: 0;

                    button {
                        width: fit-content;
                        display: flex;
                        flex-direction: row;
                        border: none;
                        background-color: #fff;
                        padding: .5rem 0;
                        position: relative;

                        span {
                            position: absolute;
                            right: 0;
                            top: 0;
                        }
                    }
                }

                .card-body {
                    padding: .5rem 0;
                    border: none;

                    .driveaway-price-panel {
                        /*border-bottom: none !important;*/
                        margin-bottom: 0 !important;
                    }
                }
            }
        }

        .payment-row-item, .payment-row-item.bold {
            width: 100%;
            height: 2.8rem;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

        .payment-row-item {
            span {
                color: #505050;
            }
        }

        .payment-row-item.bold {
            font-weight: 600;

            span {
                color: #060606;
                font-family: 'CadillacGothic-Regular';
                letter-spacing: 1px;
                font-size: 16px;
                line-height: 22px;
                word-wrap: break-word
            }
        }
    }

    .payment-bottom-info {
        margin-top: 1.5rem;

        p {
            color: #505050;
        }

        a {
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .driveaway-dropdown-panel {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        button {
            color: #060606;
            font-family: 'CadillacGothic-Regular';
            letter-spacing: 1px;
            font-size: 16px;
            line-height: 22px;
            word-wrap: break-word;
            font-weight: 600;
            position: relative;

            sup {
                top: 0 !important;
            }

            img.image {
                height: 20px;
                width: 20px;
                transform: rotate(270deg);
                transition: transform 0.4s ease-in-out;
            }

            img.image.active {
                transform: rotate(90deg);
            }
        }

        span {
            color: #060606;
            font-size: 20px;
            font-weight: 600;
            line-height: 26px;
            word-wrap: break-word;
            display: flex;
            align-items: center;
        }
    }
}


@media screen and (min-width: 768px) and (max-width: 1024px) {

}

@media screen and (max-width: 767.98px) {
    .payment-options-dialog {
        width: 100%;
        height: 100vh;
        .p-dialog-header {
            border-radius: 0;
        }
        .payment-options-scrollbar-container {
            &::-webkit-scrollbar {
                display: none;
            }
            .finance-container {
                .dropdown-panel {
                    &:nth-child(1) {
                        margin-bottom: 1.5rem;
                    }
                }
            }
            
        }
        .payment-display-panel {
            .payment-row-item.bold {
                
                span {
                    &:nth-child(2) {
                        text-align: right;
                    }
                }
            }
            .details-desc {
                span {
                    max-width: 60%;
                    color: black;
                }
            }
        }
    }
}