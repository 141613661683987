.checkout-container {
    h3 {
        font-size: 24px;
    }

    .customer-type-container {
        padding-bottom: 24px;

        .main-page-section-title {
            padding: 0;
        }

        h5 {
            margin: 0;
        }

        .checkout-customer-title {
            margin: 0;
        }

        .checkout-title {
            color: black;
            font-size: 24px;
            font-weight: 400;
            text-transform: uppercase;
            line-height: 32px;
            letter-spacing: 3px;
            word-wrap: break-word;
            margin-bottom: 1rem;
        }

        .checkout-desc {
            padding: 1rem 0 1.5rem 0;
            color: #3A3A3A;
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            word-wrap: break-word;
            margin: 0;
        }
    }

    .payment-container {
        .payment-desc {
            padding: 1.5rem 0 .5rem 0;
            font-size: 14px;
            letter-spacing: 1px;
            margin-bottom: 0;
            color: var(--global-checkout-desc-font-color);
        }

        .payment-btn-panel {
            .payment-btn {
            }
        }
    }

    .checkout-subtitle {
        margin: 0;
        padding: 1.5rem 0;
        color: #3A3A3A;
        font-size: 16px;
        font-weight: 400;
        text-transform: uppercase;
        line-height: 24px;
        letter-spacing: 2px;
        word-wrap: break-word
    }

    .delivery-container {
        .main-page-section-title {
            padding: 0;
        }

        .delivery-title {
            margin: 0;
        }

        .delivery-desc {
            padding: 1rem 0 3rem 0;
            color: #3A3A3A;
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            word-wrap: break-word;
            margin: 0;
        }

        .fine-note {
            color: #707070;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0.50px;
            word-wrap: break-word;
            margin: 0;
        }

        .variant-btn-panel {
            button {
                span {
                    font-family: 'CadillacGothic-Regular';
                }
            }
        }

        .p-input-icon-left {
            position: relative;
            width: 100%;
            height: 3rem;

            img {
                width: 24px;
                height: 24px;
                position: absolute;
                top: 14px;
                left: 10px;
                z-index: 1;
            }

            .image-success {
                margin-left: auto;
                right: 10px;
            }

            .p-inputtext {
                width: 100%;
                height: 100%;
                padding: 1rem 42px;
            }

            .p-autocomplete {
                width: 100%;
                height: 50px;
            }


            .autocomplete {
                top: .9rem !important;
                left: 42px !important;
            }

            .autocomplete.focused {
                transform: translateY(-.8em) scale(0.7);
            }

            .input-item {
                margin: 0 !important;

                .wrapper-box {
                    .p-inputtext {
                        padding-top: 1.5rem;
                        padding-bottom: .5rem;
                        border-bottom: none;

                        &:hover {
                            border-bottom: none;
                        }
                    }
                }
            }
        }

        .p-input-icon-left.regional-delivery {
            padding-top: 2rem;

            img {
                top: 46px;
            }
        }

        .preferred-location-container {
            padding-top: 5.5rem;

            .card-info-selection {
                font-family: 'CadillacGothic-Regular';

                .card {
                    padding: 1rem !important;
                }

                label {
                    cursor: pointer;
                }

                .location-top {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    padding-bottom: .5rem;

                    .location-top-inner {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                    }

                    .location_name {
                        font-weight: 500;
                        font-family: 'CadillacGothic-Medium';
                        color: var(--global-exterior-interior-font-color);
                        padding-left: 8px;
                    }

                    .location_distance {
                        flex: 1;
                        display: flex;
                        justify-content: end;
                    }
                    // span {
                    //     &:first-child {
                    //         font-weight: 500;
                    //         font-family: 'CadillacGothic-Medium';
                    //         color: var(--global-exterior-interior-font-color);
                    //     }
                    // }
                }

                .location-address {
                }
            }

            h6 {
                color: var(--global-exterior-interior-font-color);
                margin-top: 1.5rem;
                margin-bottom: 1rem;
            }

            .variant-btn-panel {
                .variant-btn {
                    // margin-top: 0rem;
                    cursor: pointer;
                    // span {
                    //     cursor: pointer;
                    // }
                }

                .variant-btn.selected {
                    span {
                        color: #000
                    }
                }
            }
        }

        .delivery-address-wrapper {
            .different-delivery-address {
                display: flex;
                flex-direction: row;
                margin-top: 10px;

                label {
                    padding-left: 5px;
                }
            }

            .wrapper-box {

                input {
                    border: none;
                }

                .p-dropdown {
                    padding: 0px;
                }

                .p-inputtext {
                    border: none;
                }

                .p-placeholder {
                    color: #a9a9a9;
                }

                .p-inputtext:hover {
                    border: none;
                }
            }
        }

        .delivery-error {
            margin-top: 10px;
        }
    }

    .review-order-container {
        padding-bottom: 1rem;

        .review-order-title {
            margin: 0;
        }

        .drive-away-header-panel {
            padding-bottom: 1.5rem;

            .review-order-desc {
                color: #3A3A3A;
                font-size: 16px;
                font-weight: 400;
                line-height: 22px;
                word-wrap: break-word;
                padding-bottom: 1.5rem;
                margin: 0;
            }
        }


        .car-panel, .payment-panel, .delivery-panel {
            padding: 1rem 1rem .5rem 1rem;
            border: 1px solid #e9ecef;
            border-radius: none;
            margin-bottom: 1rem;

            h6 {
                margin: 0 0 1rem 0;
                color: black;
                font-size: 16px;
                font-weight: 400;
                text-transform: uppercase;
                line-height: 24px;
                letter-spacing: 2px;
                word-wrap: break-word
            }

            .inner-item {
                padding-bottom: 1rem;

                span {
                    color: #707070;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 18px;
                    letter-spacing: 0.50px;
                    word-wrap: break-word
                }

                p {
                    margin: 0;
                    color: black;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 22px;
                }
            }

            .adds-on-item-panel {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 4px;

                span {
                    &:nth-child(1) {
                        color: black;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 22px;
                        word-wrap: break-word
                    }

                    &:nth-child(2) {
                        color: #707070;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 22px;
                        word-wrap: break-word
                    }
                }
            }

            .edit-box {
                padding: 1rem 0;
                border-top: 1px solid #e9ecef;

                span {
                    color: black;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 22px;
                    word-wrap: break-word;
                    cursor: pointer;
                    width: fit-content;

                    img {
                        margin-left: 6px;
                    }
                }
            }


        }
    }

    .take-break-container {
        padding: 1.5rem 2rem;
        background-color: #F6F6F4;
        margin-bottom: 3rem;
        color: var(--global-exterior-interior-font-color);

        .take-break-title {
            margin-bottom: 12px;
            color: black;
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            word-wrap: break-word
        }

        .take-break-desc {
            margin-bottom: 1rem;
        }

        .take-break-btn-panel {
            display: flex;
            flex-direction: column;

            .share-box, .save-box {
                display: flex;
                flex-direction: row;
                align-items: center;
                cursor: pointer;

                img {
                    width: 20px;
                    height: 20px;
                    margin-right: .5rem;
                }

                span {
                    color: black;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 18px;
                    letter-spacing: 0.50px;
                    word-wrap: break-word
                }
            }

            .save-box {
                padding-bottom: 0.8rem;
            }
        }
    }

    .checkout-btn-panel {
        display: flex;
        flex-direction: column;
        // margin-bottom: 1.5rem;
        width: 100%;
        padding-top: 1rem;

        .checkout-reminder {
            font-size: 14px;
        }

        .customer-btn, .payment-btn, .preferred-btn {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            box-shadow: none;
            background-color: transparent;
            border: 1px solid #000;
            color: #282828;
            margin-top: 1rem;
            height: 4.25rem;
            border-radius: 4px;
            line-height: 1.375rem;
            min-width: 6rem;
            cursor: pointer;
            transition: all .25s linear;
            min-height: 3rem;
            max-height: 5rem;
            // text-transform: uppercase;
            letter-spacing: 1px;
            border-radius: 4px;
            font-size: 1rem;
            font-weight: 400;
            padding-left: 16px;
            padding-right: 16px;
            // &:hover {
            //     border: none;
            //     background-color: #000;
            // }
        }

        .preferred-btn {
            max-height: 3.5rem;
        }

        .customer-btn.selected, .payment-btn.selected, .preferred-btn.selected {
            background-color: #282828;
            border: 1px solid #282828;
            color: #fcfcfc;
        }

        img {
            width: 20px;
            height: 20px;
        }

        .checkout-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid black;
            border-radius: 4px;
            background-color: black;
            color: #fff;
            font-size: 15.6px;
            height: 3rem;
            // &:hover {
            //     background-color: #fff;
            //     color: #282828;
            // }

            h6 {
                margin: 0;
                color: white;
                font-size: 14px;
                font-weight: 400;
                text-transform: uppercase;
                line-height: 22px;
                letter-spacing: 2px;
                word-wrap: break-word
            }
        }

        &:last-child {
            .checkout-btn {
                padding: 1rem;
                font-size: 14px;
                line-height: 18px;
                box-shadow: none;
            }
        }
    }

    .cash-finance-btn-panel {
        display: flex;
        flex-direction: row;

        button {
            flex-basis: 50%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            box-shadow: none;
            color: black;
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            word-wrap: break-word;
            padding: 16px;
            background: white;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            gap: 24px;
        }

        .cash-finance-btn {
            border: 1px #E9ECEF solid
        }

        .cash-finance-btn.selected {
            border: 2px black solid;
        }
    }
}

.payment-error {
    margin-top: 30px;
    color: red;
}

.credit-card-input {
    height: 60px;
    border-bottom: 2px solid #d2d2d2 !important;
    background-color: #f6f6f6;
    margin-bottom: 16px;
    padding: 0px 16px;

    &:hover {
        border-bottom: 2px solid #282828 !important;
    }
    /*        &:placeholder-shown {
            font-weight: 200;
            color: #d8d8d8;
        }*/
}

.share-dialog {
    border-radius: 4px;
    width: 70%;
    .p-dialog-content {
        padding-left: 9%;
        padding-right: 9%;
        border-radius: 0 0 4px 4px;

        .title-container {
            margin-bottom: 2rem;
            span {
                color: #282828;
                font-size: 16px;
                font-weight: 400;
                line-height: 22px;
                word-wrap: break-word
            }
        }

        .share-content-container {
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            margin-bottom: 2rem;
            position: relative;

            &:nth-child(2) {
                border-bottom: 2px solid #282828;

                .or-panel {
                    position: absolute;
                    height: 1rem;
                    bottom: -0.5rem;
                    background-color: #fff;
                    left: 0;
                    right: 0;
                    margin-left: auto;
                    margin-right: auto;
                    width: 3rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .inner-content-container {
                    .input-item {
                        display: flex;
                        flex-direction: row;
                    }
                }
            }

            .inner-content-container {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
                background-color: #f6f6f4;
                height: 4rem;
                margin-bottom: 2rem;
                padding: 0 0 0 0rem;

                .share-content-subtitle {
                    color: #282828;
                    height: 1.5rem;
                    padding-left: 1rem;
                }

                .input-item {
                    width: calc(100% - 200px);
                    height: 64px;
                    margin-bottom: 0;
                    margin-top: 0;
                    padding-right: 1rem;

                    .wrapper-box {
                        input {
                            border: none;
                        }
                    }
                }

                .share-content {
                    white-space: nowrap;
                    text-wrap: nowrap;
                    overflow: auto;
                    padding-top: 1rem;
                    // height: 2.5rem;
                }
            }

            &:nth-child(3) {
                .inner-content-container {
                    padding: 0;
                }
            }

            .right-btn-panel {
                padding-top: 0;
                width: 200px;
                height: 4rem;
                text-align: right;
                // max-width: 200px ;
                button {
                    background-color: #060606;
                    border: 1px solid #060606;
                    border-radius: 4px;
                    color: #fcfcfc;
                    height: 100%;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        margin-right: .5rem;
                    }
                }

                .success-info {
                    left: auto !important;
                    right: .2rem;
                    color: green;
                }
            }
        }

        .lik-privacy {
            cursor: pointer;
            text-decoration: underline;
            color: #282828;
        }
    }
}


.save-dialog {
    border-radius: 0%;
    max-height: 100vh !important;
    margin: 0 !important;
    width: 500px;
    height: 100%;

    .p-dialog-content {
        padding-left: 7%;
        padding-right: 7%;


        .title-container {
            margin-bottom: 2rem;
            border-bottom: none;
            text-align: left;

            h1 {
                width: fit-content;
                margin: 0 auto;
                text-align: center;
            }

            h2 {
                color: black;
                font-size: 24px;
                font-weight: 400;
                text-transform: uppercase;
                line-height: 32px;
                letter-spacing: 3px;
                word-wrap: break-word;
                padding-bottom: 1rem;
            }

            span {
                color: #3A3A3A;
                font-size: 16px;
                font-weight: 400;
                line-height: 22px;
                word-wrap: break-word
            }
        }

        .input-container {
            padding-bottom: 1rem;
        }

        .input-item {
            width: 100%;
            margin-top: .5rem;

            &:nth-child(1) {
                margin-top: 0;
            }

            &.textarea {
                height: auto;

                .wrapper-box {
                    label {
                    }
                }
            }

            .wrapper-box {
                input, .p-inputtextarea {
                    border-bottom: none;
                }

                .p-inputtextarea {
                    padding-top: 2rem !important;
                    box-shadow: none;
                }
            }
        }
        /*        textarea {
            height: 200px !important;
        }*/


        .checkbox-panel {
            padding: 0rem 0 1rem 0;

            .section-save-ack {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                padding: .5rem 0;

                .p-checkbox-label {
                    margin-left: 1rem;
                }

                a {
                    cursor: pointer;
                    text-decoration: underline;
                }
            }
        }

        .btn-panel {
            display: flex;
            align-items: center;
            justify-content: center;

            button {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                box-shadow: none;
                background-color: transparent;
                border: 1px solid #000;
                color: #fff;
                background-color: #282828;
                margin-top: 1rem;
                line-height: 1.375rem;
                min-width: 20rem;
                cursor: pointer;
                transition: all .25s linear;
                width: 100%;
                height: 3.2rem;
                // max-height: 5rem;
                // text-transform: uppercase;
                letter-spacing: 1px;
                font-size: 1rem;
                font-weight: 400;
                padding-left: 16px;
                padding-right: 16px;
                // &:hover {
                //     border: 1px solid #282828;
                //     background-color: #fff;
                //     color: #282828;
                // }
            }
        }

        .success-message {
            color: green;
        }
    }
}

.save-dialog-btn {
    .variant-btn-panel {

        .variant-btn {
            background-color: #282828;
            font-size: 14px;
            font-weight: 400;
            text-transform: uppercase;
            line-height: 22px;
            letter-spacing: 2px;
            word-wrap: break-word;
            padding: 1rem 1.5rem;
            color: #fff;
            display: flex;
            justify-content: center;
            font-family: 'CadillacGothic-Regular';
            border-radius: 4px !important;
            border: none;
        }
    }
}

.terms-conditions-dialog, .checkout-privacy-dialog, .account-guidelines-dialog {
    width: 80%;
    height: 95vh;
    position: absolute;
    bottom: 0;
    margin-bottom: 0 !important;

    .p-dialog-header {
        border-radius: 1rem 1rem 0 0;
    }

    .terms-conditions-container, .checkout-privacy-container, .account-guidelines-container {
        padding-left: 2rem;
        padding-right: 2rem;

        h6 {
            color: #3A3A3A;
            font-size: 16px;
            font-weight: 400;
            text-transform: uppercase;
            line-height: 24px;
            letter-spacing: 2px;
            word-wrap: break-word;
            margin-bottom: 1.5rem;
        }

        a {
            text-decoration: underline;
            cursor: pointer;
        }

        .terms-conditions-title, .checkout-privacy-title, .account-guidelines-title {
            padding: 2rem 0 2rem 0;

            h1 {
                color: black;
                font-size: 40px;
                font-weight: 400;
                text-transform: uppercase;
                line-height: 48px;
                letter-spacing: 3px;
                word-wrap: break-word;
                margin: 0;

                &:nth-child(2) {
                    padding-bottom: 1rem;
                }
            }
        }

        .terms-conditions-content, .checkout-privacy-content, .account-guidelines-content {
            .terms-conditions-section {
                h5 {
                    padding-top: 1.5rem;
                    font-weight: 600;
                    padding-bottom: 6px;
                }

                ul {
                    li {
                        list-style-type: none;
                    }
                }
                .sub-section-box {
                    padding-bottom: 1rem;
                    .sub-section-title {
                        text-decoration: underline;
                    }
                    // .list-number {
                    //     list-style-type: none;
                    //     counter-reset: list-counter;
                    // }

                    // .list-number li {
                    //     counter-increment: list-counter;
                    // }

                    // .list-number li::before {
                    //     content: counter(list-counter) ". "; 
                    // }
                }
                
            }

            .account-guidelines-section {
                h5 {
                    padding-top: 1.5rem;
                    font-weight: 600;
                    padding-bottom: 6px;
                }
            }

            p {
                color: #282828;
                font-size: 16px;
                font-weight: 400;
                line-height: 22px;
                letter-spacing: 1px;
                word-wrap: break-word
            }
        }
    }
}


.account-details-container {
    position: relative;
    width: 100%;

    &.submitting-to-yana {
        opacity: 0.5;

        input {
            pointer-events: none;
        }
    }


    .generic-button {
        border: 1px solid #282828;
        background-color: #282828;
        color: #fff;
        // &:hover {
        //     background-color: #fff;
        //     color: #282828;
        // }
    }

    .back-to-configuration {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        padding-bottom: 1.5rem;

        img {
            transform: scaleX(-1);
            height: 1.5rem;
            margin-right: 12px;
        }

        h6 {
            margin: 0;
            font-size: 14px;
            font-style: normal;
            line-height: 22px; /* 157.143% */
            text-transform: uppercase;
        }
    }


    .checkout-btn-panel {
        height: 48px !important;
        margin-top: 1.5rem;

        button {
            margin: 0;
            height: 100%;
            color: white;
            font-size: 14px;
            font-weight: 400;
            text-transform: uppercase;
            line-height: 22px;
            letter-spacing: 2px;
            word-wrap: break-word;
            display: flex;
            align-items: center;
            justify-content: center;

            span {
                font-family: "CadillacGothic-Regular";
            }
        }
    }

    .checkbox-checkout-container {
        padding-top: 2rem;

        .checkbox-container {
            .checkbox-item {
                .checkbox-content {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                }
            }
        }
    }

    .account-details, .information-details {
        // margin: 1.5rem 0;
        h2 {
            font-size: 24px;
            font-style: normal;
            line-height: 32px; /* 133.333% */
            margin: 0;
        }
    }

    .account-details {
        margin: 0 0 1.5rem 0;
    }

    .information-details {
        margin: 3rem 0 1.5rem 0;
    }

    .dollars-deposit {
        margin: 3rem 0 1.5rem 0;
        border: 1px solid #E9ECEF;
        padding: 1.5rem 2rem;
        border-radius: 4px;

        .price-line {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            font-family: "CadillacGothic-Regular";
            padding-bottom: 12px;

            span {

                &:nth-child(1) {
                    color: black;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 22px;
                    word-wrap: break-word;
                    padding-bottom: 0;
                }

                &:nth-child(2) {
                    color: #707070;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 22px;
                    word-wrap: break-word;
                    padding-bottom: 0;
                }
            }
        }

        .deposit-line {
            border-bottom: 1px solid #CCCCCC;
            padding-bottom: 15px;
        }

        p {
            color: #747373;
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 1px;
            padding-top: 12px;
            margin: 0;

            li {
                color: #707070;
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0.50px;
                word-wrap: break-word
            }
        }
    }

    .collecting-data-container {
        padding: 1.5rem 0 .5rem 0;

        .collecting-data-panel {
            display: flex;
            flex-direction: row;
            align-items: center;
            background-color: #f6f6f4;
            padding: 1rem 1.5rem;
            border-radius: 4px;

            .vehicle-features-panel {
            }

            p {
                margin: 0;
            }

            img {
                margin-right: 1rem;
            }

            .learn-more {
                color: #282828;
                cursor: pointer;
            }
        }
    }


    .customer-type-checkbox {

        .section-agree-ack {
            display: flex;
            flex-direction: row;
            margin-top: 20px;

            .p-checkbox-label {

                p {
                    margin-bottom: 0;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 18px;
                    letter-spacing: 0.50px;
                    word-wrap: break-word;
                    cursor: pointer;
                }

                a {
                    color: var(--Black-Font---Default, #282828);
                    cursor: pointer;
                }

                h6 {
                    font-size: 14px;
                }
            }
        }

        .p-error {
            padding-left: 2rem;
        }

        .checkbox-bottom-content {
            padding-top: 8px;
            padding-left: 2rem;

            .checkbox-privacy-btn-box {
                padding-bottom: 1.5rem;

                .checkbox-privacy-btn {
                    width: 100%;
                    height: 3rem;
                    background-color: #fff;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    color: #282828;
                    border: none;
                    border-bottom: 1px solid #d2d2d2;
                    cursor: pointer;

                    span {
                        color: black;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 18px;
                        letter-spacing: 0.50px;
                        word-wrap: break-word
                    }

                    img {
                        width: 12px;
                        height: 12px;
                    }
                }
            }

            h6 {
                color: #3A3A3A;
                font-size: 14px;
                font-family: 'CadillacGothic-NarrowRegular';
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0.50px;
                word-wrap: break-word;
                margin-bottom: 6px;
            }
        }
    }

    .customer-info-container {
        padding: .5rem 0 0 0;

        .input-item {
            height: 56px !important;
            width: 100%;
            background-color: #707070;

            .wrapper-box {
                background-color: #fff;

                label {
                    top: 1.1rem;
                }

                input {
                    padding: 1rem 1rem 0 1rem;
                    border: none;
                }
            }
        }
    }

    .payment-panel {
        position: relative;
        overflow-y: hidden;

        .payment-panel-inner {
            position: sticky;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 0%;
            background-color: #fff;
            // border-top: 1px solid #ccc;
            transform: translateY(100%);
            transition: transform 0.4s ease-in-out;
        }

        .payment-panel-inner.show {
            transform: translateY(0%);
            height: 100%;
        }

        .retry-payment-button-wrapper {
            display: flex;
            justify-content: end;
            align-items: center;
        }

        .reservation-count-down-wrapper {
            border-radius: 4px;
            padding: 24px 32px;
            background-color: black;
            color: white;
            display: flex;
            flex-direction: row;

            .count-down-icon{
                .icon{
                    padding-right:1.5rem;
                }
            }

            .count-down-text {
                display: flex;
                flex-direction: column;
                color: white;
                font-size: 14px;
                font-family: Cadillac Gothic;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0.50px;
                word-wrap: break-word;

                .time-text {
                    font-weight: 700;
                }
            }
        }
    }


    .dropdown-container {
        height: 3.8rem;
        margin-top: 1rem;

        .p-dropdown-label {
            padding-left: 0;
            padding-right: 0;
        }

        .global-location-dropdown {
            span {
                color: #a9a9a9;
            }
        }

        .global-location-dropdown.active {
            span {
                color: #282828;
            }
        }
    }

    .submitting-to-yana-loading {
        position: absolute;
        left: 50%;
        top: 50%;
    }
}

.test-drive-privacy {
    text-decoration: underline;
    cursor: pointer;
}

.customer-type-selection {
    .variant-btn-panel {
        margin-bottom: 0;

        .variant-btn {
            background-color: #fff;
            border: 1px solid #d2d2d2;
            border-radius: .5rem;
            color: #282828;
        }

        .variant-btn.selected {
            border: 2px solid #282828;
        }

        button {
            &:nth-child(1) {
                margin-top: 0;
            }

            span {
                color: black;
                font-size: 16px;
                font-weight: 400;
                line-height: 22px;
                word-wrap: break-word;
            }
        }
    }
}

.cash-finance-desc-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    margin-top: 1.5rem;
    padding: 16px;
    background: #F6F6F4;
    border-radius: 4px;
    grid-gap: 8px;
    gap: 8px;
    cursor: pointer;

    span {
        display: flex;
        align-items: center;

        img {
            width: 15px;
            height: 18px;
        }
    }

    img {
        &:nth-child(1) {
            margin-right: .6rem;
        }
    }
}

.more-about-price {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 1.5rem;
    color: black;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.50px;
    word-wrap: break-word;
    cursor: pointer;

    img {
        width: 16px;
        height: 16px;
        margin-left: .5rem;
    }
}

.tooltip {
    /* Your custom styles for the tooltip */
    /* For example, you might adjust the z-index to ensure it's above other elements */
    z-index: 3000 !important;
}

.checkout-confirm-dialog {
    .button-wrapper {
        padding-top: 20px;
        display: flex;
        justify-content: space-evenly;
    }
}


#customCard-container {
    margin-top: 20px;
    /*padding: 10px;*/
}


.adyen-checkout__card__brands__brand-wrapper {
    height: 24px !important;
}

.adyen-checkout__card__brands {
    height: 32px !important;
}

#customCard-container {
    font-family: 'CadillacGothic-Regular' !important;

    input {
        &::placeholder {
            font-family: 'CadillacGothic-Regular' !important;
            color: #b3b3b3;
            font-size: 16px;
        }
    }
}

.third-party-reminder {
    padding-top: 1rem;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.50px;
    word-wrap: break-word;
    color: #282828;
    .third-party-link {
        color: #282828;
        text-decoration: underline;
        cursor: pointer;
    }
}

.adyen-checkout__label {
    font-family: 'CadillacGothic-Regular' !important;
}

.adyen-checkout__input {
    font-family: 'CadillacGothic-Regular' !important;
}

.adyen-checkout__label__text {
    font-family: 'CadillacGothic-Regular' !important;
}

.adyen-checkout__card__holderName {
    font-family: 'CadillacGothic-Regular' !important;
}

//below code is working

//hide address 2
.adyen-checkout__field--houseNumberOrName {
    display: none !important;
}

.adyen-checkout__card__brands {
    display: none !important;
}

.adyen-checkout__field-wrapper {
    flex-direction: column !important;
}

.adyen-checkout__field-wrapper > .adyen-checkout__field:nth-child(2) {
    margin-left: 0px !important;
}

.adyen-checkout__field {
    width: 100% !important;
    margin: 0px !important;
}

//above code is working

/*


.adyen-checkout__field__cvc {
    font-family: 'CadillacGothic-Regular';
}

.adyen-checkout__input--error {

}


.adyen-checkout__field--expiryDate {

}*/


.submitting-to-yana-loading {
    circle {
        stroke: green;
        animation: none;
    }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {


}

@media screen and (max-width: 767.98px) {  
    .share-dialog {
        border-radius: 4px;
        width: 90%;
        .p-dialog-content {
            .share-panel {
                height: fit-content;
                .share-content-container {
                    &:nth-child(3) {
                        margin-bottom: 0;
                    }
                    .inner-content-container {
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: flex-start;
                        height: auto;
                        background-color: #fff;
                        .input-item {
                            width: 100% ;
                            height: 64px ;
                            padding: 0;
                            margin-bottom: 1rem;
                        }
                        .right-btn-panel {
                            position: relative;
                            width: 100%;
                            button {
                                width: 100%;
                                height: fit-content;
                                padding: 1rem 1.5rem;
                            }
                        }
                    }
                }
              
            }
          
        }
       
    }
    .terms-conditions-dialog, .checkout-privacy-dialog, .account-guidelines-dialog {
        width: 100%;
        height: 100vh;
        .p-dialog-header {
            border-radius: 0;
        }
        .p-dialog-content {
            padding-left: 1rem;
            padding-right: 1rem;
            .terms-conditions-container, .checkout-privacy-container, .account-guidelines-container {
                padding: 0;
            }
        }
    }
}


.checkout-countdown-dialog {
    .checkout-countdown-content {
        padding-top: 40px;
        h5{
            padding-bottom:15px;
        }
    }
}