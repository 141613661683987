.sign-in-up-container, .password-issue-container  {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    .user-left-panel {
        flex-basis: 45%;
        overflow: hidden;
        display: flex;
        align-content: center;
        justify-content: center;
        img {
            object-fit: contain;
            width: 100%;
        }
    }

    .user-right-panel {
        flex-basis: 55%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 8rem;
        .right-inner-container {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .logo-image {
            width: 6rem;
            cursor: pointer;
        }
        .sign-in-title {
            font-size: 26px;
            padding: 2rem 0;
        }
        .input-item {
            width: 30.75rem !important;
        }
        button {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            box-shadow: none;
            background-color: transparent;
            border: 1px solid #000;
            color: #fff;
            background-color: #282828;
            margin-top: 1rem;
            height: 4.25rem;
            border-radius: 4px;
            line-height: 1.375rem;
            min-width: 20rem;
            cursor: pointer;
            transition: all .25s linear;
            height: 3.2rem;
            // max-height: 5rem;
            // text-transform: uppercase;
            letter-spacing: 1px;
            border-radius: 4px;
            font-size: 1rem;
            font-weight: 400;
            padding-left: 16px;
            padding-right: 16px;
            &:hover {
                border: 1px solid #282828;
                background-color: #fff;
                color: #282828;
            }
        }

        .bottom-btn-panel {
            display: flex;
            flex-direction: row;
            // justify-content: space-between;
            justify-content: center;
            width: 22rem;
            padding: 2rem 0 36px 0;
            .btn-card {
                display: flex;
                flex-direction: column;
                align-items: center;
                cursor: pointer;
                .btn-image {
                    width: 30px;
                    height: 30px;
                }
                a {
                    padding-top: 8px;
                    color: #282828;
                }
            }
        }
      
        .privacy-panel {
            display: flex;
            flex-direction: column;
            align-items: center;
            a {
                color: #282828;
                cursor: pointer;
            }
        }

        .redirect-panel {
            margin-top: 3rem;
            font-size: 14px;
        }
    }

    .p-input-icon-right {
        i {
            margin-right: 1rem;
            font-style: normal;   
        }
    }
    .p-password {
        margin: 0 !important;
    }

    .input-item, .p-password {
        margin-bottom: 1.5rem;
    }
}


.user-dialog {
    // background-color: #fcfcfc;
    // display: grid;
    // margin: 0;
    // padding: 0 20px 20px 24px;
    // position: relative;
    width: 340px;
    height: fit-content;
    .p-dialog-header {
        // background-color: #fcfcfc;
        background-color: #fcfcfc;
        display: grid;
        margin: 0;
        padding: 0 0rem 1.5rem 1.5rem;
        position: relative;
        border-radius: 4px 4px 0 0;
    }
    .pop-up-dialog-header {
        border-bottom: 1px solid #d2d2d2;
    }
    .dialog-btn-panel {
        height: 3rem;
        button {
            border-radius: 4px;
            &:hover {
                background-color: #282828;
            }
        }
    }
    .p-dialog-titlebar-close {
        position: absolute;
        top: 0;
        right: 0;
        background-color: #282828;
        border: 0;
        display: block;
        height: 48px;
        padding: 18.5px 16px;
        width: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            color: #fff;
            width: 16px;
            height: 16px;
        }
    }
    .account-main {
        display: flex;
        flex-direction: row;
        height: fit-content;
        margin: 1.5rem 0;
        position: relative;
        width: 100%;
        img {
            padding-right: 20px;
        }
        span {
            color: black;
            font-size: 14px;
            font-weight: 400;
            word-wrap: break-word
        }
    }
    .p-dialog-content {
        padding-bottom: 1.5rem;
        padding-right: .5rem;
        border-radius: 0 0 0px 4px;
    }
    .sign-in-off-panel {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .sign-in-off-options-panel {

        }
        .sign-in-off-quit-panel {
            padding-top: 1rem;
            border-top: 1px solid #d2d2d2;
        }
    }
    .sign-in-off-card {
        width: fit-content;
        color: black;
        font-size: 14px;
        cursor: pointer;
        display: flex;
        align-items: center;
        margin-bottom: 2rem;
        padding-top: 18px;
        img {
            width: 25px;
            height: 25px;
        }
        span {
            margin-left: .5rem;
            font-family: 'CadillacGothic-Regular';
        }
        .icon-user-unsigned {
            transform: rotate(180deg);
        }
    }
}

.user-dialog.logged-in-height {
    height: 100vh; /* Adjust the height as needed */
}


// .modal {
//     transform: translateX(100%);
//     transition: transform 0.5s ease-in-out;
//     margin: 0;
// }
  
// .modal.show {
//     transform: translateX(0);
//     .modal-dialog {
//         margin: 0;
//         position: absolute;
//         top: 0;
//         right: 0;
//     }
// }

.password-issue-container {
    height: 100%;
    .user-right-panel {
        padding-top: 0;
        .right-inner-container {
            
            // padding: 44px 119px;
            padding: 3rem 0;
            width: 30.75rem;
            .logo-title-container {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
                border-bottom: 1px solid #282828;
                .logo-image {
                    max-width: 70px;
                }
                .sign-in-title, .sign-in-link {
                    color: #282828;
                    font-size: 1rem;
                    letter-spacing: .65px;
                    line-height: 1.8;
                    margin: 0;
                }
                .sign-in-link {
                    cursor: pointer;
                    text-decoration: underline;
                }
            }

            .input-item {
                width: 100% !important;
                .p-inputtext {
                    padding: 1rem 1rem 0 1rem;
                }
            }

            .handle-password-container {
                margin: 40px 0 3.5% 0;
                width: 100%;
                .handle-password-title {
                    font-size: 24px;
                }
                .handle-password-title.new-password {
                    margin-bottom: 2rem;
                }
                .handle-password-subtitle {
                    margin: 1rem 0 2rem 0;
                }
                .handle-password-desc {
                    margin-top: 2rem;
                }
                .insert-rules {
                    padding: 16px 0 8px 0;
                    .insert-item {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        img {
                            width: 20px;
                            height: 20px;
                            margin-right: .5rem;
                        }
                        // .round {
                        //     width: 20px;
                        //     height: 20px;
                        //     border: 3px solid #282828;
                        //     border-radius: 50%;
                        //     margin-right: .5rem;
                        // }
                        span {
                            padding-top: 2px;
                        }
                    }
                   
                }
            }
            .handle-password-btn-panel {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
                height: 44px;
                flex-grow: 0;
                margin: 0 48px;
                button {
                    min-width: 45% !important;
                    &:nth-child(2) {
                        color: #282828;
                        background-color: #fff;
                    }
                }
            }
            .handle-password-btn-panel.reset, .handle-password-btn-panel.reset-complete {
                justify-content: center;
            }
        }
    }
}


@media screen and (max-width: 767.98px) {
    .user-dialog {
        width: 100% !important;
        height: 100% !important;
        .p-dialog-header {
            border-bottom: 1px solid #d2d2d2;
            padding-bottom: 0.5rem;
            
            .p-dialog-title {
         
            }
            .pop-up-dialog-header {
                border: none;
               
                .account-main {
                    margin-top: 2rem;
                    span {
                        color: #282828;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 20px;
                        letter-spacing: 0.55px;
                        word-wrap: break-word
                    }
                }
            }
        }

        .p-dialog-content {
            padding-top: 1.5rem;
            .sign-in-off-card {
                color: #282828;
                font-size: 13px;
                font-weight: 400;
                line-height: 25px;
                letter-spacing: 0.60px;
                word-wrap: break-word
            }
        }
    }
}