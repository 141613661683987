.footer-container {
    display: flex;
    background-color: #fff;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    width: 100%;
    z-index: 200;
    text-align: center;
    padding: 3rem 0rem 0 0rem;
    // border-top: 1px solid #D2D2D2;
    .footer {
        // padding: 30px 0 30px 0;
        // max-width: 1440px;
        padding: 0 3rem;
        width: 100%;
        max-width: 1920px;
        box-sizing: border-box;
        color: #282828;
        // background-color: #f6f6f6;

        background-color: #fff;   //New project, former demo need to comment this one
    }

    .footer-logo-container {
        display: flex;
        justify-content: flex-start;
        margin-bottom: calc(1.25* 16px + .625rem);
        img {
            max-width: 194px;
        }
    }

    .footer-content-container {
        display: flex;
        flex-direction: row;
        padding-bottom: 2.5rem;
        .footer-get-updates-container {
            flex-basis: 50%;
            .checkout-btn-panel {
                
                button {
                    border: 2px solid #282828;
                    background: #fff;
                    color: #282828;
                    font-size: 14px;
                    font-family: 'CadillacGothic-Medium';
                    letter-spacing: 1.6px;
                    line-height: 22px;
                    padding: 12px 26px;
                    max-width: 370px;
                    &:hover {
                       background: #282828;
                       color: #fff;
                    }
                }
            }
        }
        .footer-links-icons-container {
            display: flex;
            flex-direction: column;
            .footer-links-container {
                flex-basis: 50%;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                padding-bottom: 1rem;
                .link-item {
                    width: fit-content;
                    flex-basis: 50%;
                    font-size: calc(.875* 16px);
                    letter-spacing: 1.6px;
                    line-height: 22px;
                    font-family: 'CadillacGothic-Medium';
                    text-decoration: none;
                    color: #282828;
                    margin-bottom: calc(1.25* 16px) !important;
                    cursor: pointer;
                    text-align: left;
                }
            }
            .footer-icons-container {
                display: flex;
                flex-direction: column;
                text-align: left;
                p {
                    display: block;
                    margin-block-start: 1em;
                    margin-block-end: 1em;
                    margin-inline-start: 0px;
                    margin-inline-end: 0px;
                    unicode-bidi: isolate;
                    font-size: .75rem;
                    letter-spacing: .6px;
                    line-height: 1.825;
                    font-family: 'CadillacGothic-NarrowRegular';
                    color: #282828;
                }
                .footer-icons-panel {
                    display: flex;
                    flex-direction: row !important;
                    // max-width: 260px;
                    // margin: 0 auto;
                    // justify-content: space-between;
                    flex-wrap: nowrap;
                    .icon-item {
                        width: fit-content;
                        padding-right: 2.5rem;
                        img {
                            cursor: pointer;
                            width: 32px;
                            height: 32px;
                        }
                    }
                }
               
            }
        }
       
    }
    
    
   

    // .footer-items-container {
    //     padding: 6rem 1% 5rem 1%;
    //     justify-content: center;
    //     .footer-items-img-box {
    //         img {
    //             width: 100%;
    //         }
    //     }
    //     .footer-item-card {
    //         display: flex;
    //         flex-direction: column;
    //         align-items: flex-start;
    //         h5 {
    //             padding-bottom: 3.6rem;
    //             font-family: var(--footer-menu-uppercase-font-family);
    //             letter-spacing: 1px;
    //         }
    //         p {
    //             color: #fff;
    //             font-family: 'CadillacGothic-Regular';
    //             padding-left: 1vw;
    //         }
    //     }
    // }

    .footer-copyright-container {

    }

    .footer-disclaimers-container {

    }

    .disclaimer-container {
        padding: 80px 3rem;
        .disclaimer-content-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            font-size: .75rem;
            font-family: 'CadillacGothic-NarrowRegular';
            letter-spacing: .6px;
            line-height: 1.825;
            .disclaimer-title {
                flex-basis: 25%;
                text-align: left;
            }
            .disclaimer-card-container {
                flex-basis: 70%;
                .disclaimer-card {
                    display: flex;
                    flex-direction: row;
                    padding-bottom: 1.5rem;
                    .disclaimer-card-title {
                        flex-basis: 33%;
                        text-align: left;
                        padding-bottom: .5rem;
                    }
                    .disclaimer-content {
                        flex-basis: 66%;
                        text-align: left;
                    }
                }
            }
        }
    }

    .copyright-container {
        align-self: stretch;
        align-items: flex-start;
        gap: var(--global-spacing-padding-size-spacing-XS);
        // flex-direction: column;
        flex-direction: row;
        flex: none;
        // justify-content: center;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        display: flex;
        position: relative;
        border-top: 1px solid #282828;
        // padding: 0rem 2.6rem 0rem 2.6rem;
        padding: 1.875rem 3rem 1.5rem 3rem;
        .copyright {
            text-align: center;
            color: #282828;
            font-size: 14px;
            font-family: 'CadillacGothic-Regular';
            font-weight: 400;
            text-transform: uppercase;
            word-wrap: break-word;
            margin: 0;
            .copyright-link {
                cursor: pointer;
                font-family: 'CadillacGothic-NarrowRegular';
                font-size: .75rem;
                letter-spacing: .6px;
                line-height: 1.825;
                color: #282828;
            }
        }
    }


}


@media screen and (min-width: 768px) and (max-width: 1200px) {
    .footer-container {

        .footer-content-container {
            .footer-get-updates-container { 
                flex-basis: 60%;
                .checkout-btn-panel {
                    max-width: 340px;
                }
            }
            .footer-links-icons-container {
                flex-basis: 40%;
                .footer-links-container {
                    flex-direction: column;
                    flex-wrap: nowrap;
                }
            }
        }

        .disclaimer-container {
            padding: 80px 3rem;
            .disclaimer-content-container {
                .disclaimer-title {
                   
                }
                .disclaimer-card-container { 
                    .disclaimer-card {
                        display: flex;
                        flex-direction: column;
                    }
                }
            }
        }
    }
}
    

@media screen and (max-width: 767.98px) {
    .footer-container {

        .footer-logo-container {
            width: 100%;
            display: flex;
            justify-content: center;
            // width: 300px;
        }

        .footer-content-container {
            flex-direction: column;
            align-items: center;
            .footer-get-updates-container {
                flex-basis: 100%;
                padding-bottom: 2rem;
                width: 100%;
                .checkout-btn-panel {
                    width: 100%;
                    button {
                        max-width: 100% !important;
                    }
                }
            }
            .footer-links-icons-container {
                .footer-links-container {
                    flex-direction: column;
                    flex-wrap: nowrap;
                    align-items: center;
                }
                .footer-icons-container {
                    align-items: center;
                    .footer-icons-panel {
                        .icon-item {
                            padding: 0 1rem;
                        }
                    }
                }
            }
        }

        .disclaimer-container {
            padding: 3rem 1.5rem;
            .disclaimer-content-container {
                flex-direction: column;
                .disclaimer-title {
                   padding-bottom: 2rem;
                }
                .disclaimer-card-container { 
                    .disclaimer-card {
                        display: flex;
                        flex-direction: column;
                    }
                }
            }
        }
    }
}