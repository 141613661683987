$blueColor: #0071CD;
$grayColor: #e1dede;
$darkGrayColor: #9f9d9d;

:root {
    --global-checkout-title-font-size: #282828;
    --global-checkout-desc-font-size: 16px;
    --global-checkout-desc-font-color: #505050;
    --global-exterior-interior-font-color: #060606;
    --global-font-family-medium: 'CadillacGothic-Medium';

    --global-dark-red-color: #bf0000;

    //Page Row Column Percentage
    --row-0-flex-basis: 60%;
    --row-1-flex-basis: 40%;
    --tile-0-flex-basis: 60%;
    --tile-1-flex-basis: 40%;
    --tile-2-flex-basis: 50%;
    --tile-3-flex-basis: 50%;
}
  
