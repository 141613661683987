.variant-container {
    color: #282828;
    text-align: left;
    letter-spacing: 1px;
    // margin-top: 1.6rem;
    // padding-bottom: 2rem;

    .link-inventory-wrapper {
        margin-bottom: 20px;
        cursor: pointer;
        span{
            padding-left:5px;
        }
    }


    .brand-name {
        padding-bottom: 1.5rem;

        h1 {
            font-weight: 600;
        }

        h6 {
            margin-bottom: 0.5rem;
        }
    }

    .electric-panel {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-bottom: 1.5rem;

        .electric-image-panel {
            width: 32px;
            height: 32px;
            border-radius: 1rem;
            background-color: #F6F6F4;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: .5rem;
        }

        span {
            color: black;
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            word-wrap: break-word
        }
    }

    .main-page-section-title {
        padding-bottom: 1.5rem !important;
    }

    P {
        font-size: 14px;
        letter-spacing: 1px;

        span {
            font-size: 14px;
            letter-spacing: 1px;
            font-weight: bold;
        }
    }

    span {
        font-size: 16px;
        letter-spacing: 1px;
        // font-weight: bold;
    }

    .compare-variants {
        color: black;
        display: flex;
        align-items: center;
        text-decoration: none;
        cursor: pointer;
        width: fit-content;

        img {
            width: 18px;
            height: 18px;
            margin-right: .5rem;
        }

        span {
            padding-top: .1rem;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0.50px;
            word-wrap: break-word;
        }
    }
}

.variant-btn-panel {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;
    width: 100%;
    .variant-btn {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        box-shadow: none;
        background-color: transparent;
        border: 2px solid #E9ECEF;
        border-radius: 4px !important;
        color: #282828;
        margin-top: 1.5rem;
        height: 3.375rem;
        line-height: 1.375rem;
        min-width: 6rem;
        cursor: pointer;
        transition: all .25s linear;
        min-height: 3rem;
        max-height: 5rem;
        // text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 1rem;
        font-weight: 400;
        span {
            // &:nth-child(1) {
            //     color: black;
            //     font-size: 16px;
            //     font-weight: 400;
            //     // text-transform: uppercase;
            //     line-height: 24px;
            //     letter-spacing: 1px;
            //     word-wrap: break-word;
            // }
            // &:nth-child(2) {
                color: #707070;
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0.50px;
                word-wrap: break-word
            // }
        }
        h6 {
            margin: 0;
            color: black;
            font-size: 16px;
            font-weight: 400;
            text-transform: uppercase;
            line-height: 24px;
            letter-spacing: 1px;
            word-wrap: break-word;
        }
        // &:hover {
        //     border: none;
        //     background-color: #ddd;
        // }

        // &:nth-child(1) {
        //     background-color: #282828;
        //     border: 1px solid #282828;
        //     color: #fcfcfc;
        // }
        // &:nth-child(2) {
          
          
        // }
    }
    .variant-btn.selected {
        // background-color: #282828;
        border: 2px solid #282828;
        color: #282828;
    }
}

.equipments-accordion {
    &:nth-child(1) {
        border-top: 1px #E9ECEF solid;
    }
    .accordion-item {
        height: fit-content;
        border-bottom: 1px #E9ECEF solid !important;
        .accordion-header {
            .accordion-button {
                color: black;
                font-size: 20px;
                font-weight: 400;
                line-height: 26px;
                word-wrap: break-word;
                padding: 1.5rem 0;
            }
        }
        .accordion-body {
            padding: 0 0 2rem 0 !important;
            .differences-title {
                margin: 0;  
            }
            .differences-desc {
                color: #3A3A3A;
                font-size: 16px;
                font-weight: 400;
                line-height: 22px;
                word-wrap: break-word
            }
            .comparison-img-container {
                display: flex;
                // flex-direction: column;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                width: 100%;
                // padding-bottom: 3rem;
                .comparison-detail-container {
                    width: 100%;
                    height: fit-content !important;
                    display: flex;
                    flex-direction: column;
                    .variant-name-price-section {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        .left-container, .right-container {
                            max-width: 47%;
                            flex-basis: 47%;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            .exterior-options-panel {
                                width: 350px;
                                display: flex;
                                flex-direction: row;
                                justify-content: flex-start;
                                flex-wrap: wrap;
                                margin-bottom: 2rem;
                                .color-options-item {
                                    margin: 0 0rem 1rem 0;
                                    flex-basis: 16.6%;
                                    height: 2.6rem ;
                                    width: 2.6rem ;
                                    .color-options-round {
                                        display: flex;
                                        flex-direction: row;
                                        
                                        justify-content: center;
                                        align-items: center;
                                       
                                       
                                    }
                                }
                            }
                            .img-left, .img-right {
                                width: 100%;
                                height: auto;
                                img {
                                    width: 60%;
                                    height: 100%;
                                }
                            }
                            .img-container {
                                width: 100%;
                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                        }
                        .left-detail-name-price-section, .right-detail-name-price-section {
                            flex-basis: 50%;
                            align-self: flex-start;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            h6 {
                                font-size: 14px;
                                margin-bottom: 0;
                                letter-spacing: 1.2px;
                            }
                            h4 {
                                color: #3A3A3A;
                                font-size: 16px;
                                font-weight: 400;
                                text-transform: uppercase;
                                line-height: 24px;
                                letter-spacing: 2px;
                                word-wrap: break-word
                            }
                            h5 {
                                color: #3A3A3A;
                                font-size: 16px;
                                font-weight: 400;
                                line-height: 22px;
                                word-wrap: break-word
                            }
                            p {
                                color: #707070;
                                font-size: 14px;
                                font-weight: 400;
                                line-height: 18px;
                                letter-spacing: 0.50px;
                                word-wrap: break-word;
                                margin: 0;
                                padding: .5rem 0;
                            }
                            span {
                                font-size: 14px;
                                color: #505050;
                            }
                        }
                    }
                    .variant-desc-section, .exterior-front-section, .exterior-side-section, .wheel-section {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: start;
                        padding-top: 3rem;
                        h6 {
                            color: #707070;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 18px;
                            letter-spacing: 0.50px;
                            word-wrap: break-word;
                        }
                        p {
                            margin: 0;
                            color: #3A3A3A !important; 
                        }
                        .img-left, .img-right {
                            align-self: center;
                            padding: .5rem 0;
                            img {
                                width: 60% !important;
                            }
                        }
                    }
                    .variant-desc-section {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        padding-top: 2rem;
                        .variant-left-desc, .variant-right-desc {
                            flex-basis: 47%;
                        }
                    }
                    .exterior-front-section {
                        .exterior-front-left-panel, .exterior-front-right-panel {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            flex-basis: 47%;
                            .img-left, .img-right {
                                width: 100%;
                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                        }
                    }
                    .exterior-side-section {
                        .exterior-side-left-panel, .exterior-side-right-panel {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            flex-basis: 47%;
                            .img-left, .img-right {
                                width: 100%;
                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                        }
                    }
                    .wheel-section {
                        .wheel-left-panel, .wheel-right-panel {
                            flex-basis: 47%;
                            .img-left, .img-right {
                                width: 100%;
                                height: fit-content;
                            }
                            img {
                                align-self: center;
                                width: 60% !important;
                            }
                        }
                    }
                    
                    .img-left, .img-right {
                        width: 60%;
                        padding: 1rem 0;
                        img {
                            width: 100%;
                        }
                    }
                    .mobile-car-details {
                        flex-basis: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                    }
                }
                .img-pc-container {
                    width: 100%;
                    height: 200px;
                    margin: 2rem 0 1rem 0;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    flex-basis: 50%;
                    
                }
                .img-left, .img-right {
                    height: 100%;
                    width: 400px;
                    display: flex;
                    align-items: center;
                    justify-content: center;                
                    position: relative;
                    .image {
                        width: 90%;
                        height: 200px;
                        object-fit: contain;
                    }
                    > img + img {
                        width: 90%;
                        height: 100%;
                        object-fit: contain;
                        cursor: pointer;
                        position: absolute;
                        // left: 0;
                        opacity: 0;
                        z-index: 0;
                        transition: opacity .75s ease-in;
                        left: 50%;
                        transform: translateX(-50%);
                    }
            
                    &.active > img + img {
                        opacity: 1;
                        transition: opacity .75s ease-out;
                        z-index: 9;
                    }
                }
                .img-comp-container {
                    margin: 3rem 0;
                    position: relative;
                    height: 360px; /*should be the same height as the images*/
                    width: 600px;
                    .img-comp-img {
                        position: absolute;
                        width: auto;
                        height: auto;
                        overflow: hidden;
                    }
                    
                    .img-comp-img img {
                        display: block;
                        vertical-align: middle;
                        width: 600px;
                        height: 360px;
                    }
                    
                    .img-comp-slider {
                        position: absolute;
                        z-index: 9;
                        cursor: ew-resize;
                        /*set the appearance of the slider:*/
                        width: 40px;
                        height: 40px;
                        background-color: #2196F3;
                        opacity: 0.7;
                        border-radius: 50%;
                    }
                }

                .img-selection-container {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    width: 100%;
                    .img-control-panel-left, .img-control-panel-right {
                        display: flex;
                        flex-direction: column;
                        align-items: center;   
                        span {

                        }
                    }
                }

                .variant-selection-container {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    width: 100%;
                    
                }
            }
            
            
            .comparison-highlights-container {
                margin-bottom: 3rem;
                .comparison-card {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 1.5rem;
                    .image {
                        height: 120px;
                        
                    }
                    .comparison-card-desc {

                    }
                }
                p {
                    margin: 0;
                }
            }

            .parameter-item {
                padding-bottom: 2rem;
                flex: 1;
                h6 {
                    margin-bottom: .5rem;
                    color: #707070;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 18px;
                    letter-spacing: 0.50px;
                    word-wrap: break-word
                }
                p {
                    margin-bottom: .5rem;
                    color: #3A3A3A;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 22px;
                    word-wrap: break-word
                }
            }
            .parameter-item.last-line {
                padding-bottom: 0rem;
            }

            .drivetrain-section, .chassis-section, .exterior-section, .infotainment-section, .interior-section, .safety-driver-section, 
            .packages-section, .efficiency-section, .battery-system-section, .electric-drive-section, .battery-charging-section, .chassis-suspension-section,
            .exterior-dimensions-section, .interior-dimensions-section, .weights-capacities-section, .warranty-section {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                p {
                    sup {
                        font-size: 9px !important;
                    }
                }
                
            }
            
            .drivetrain-section {
                .drivetrain-left-panel, .drivetrain-right-panel {
                    flex-basis: 47%;
                }
            }

            .chassis-section {
                .chassis-left-panel, .chassis-right-panel {
                    flex-basis: 47%;
                }
            }
          
            .exterior-section {
                .exterior-left-panel, .exterior-right-panel {
                    flex-basis: 47%;
                }
            }

            .infotainment-section {
                .infotainment-left-panel, .infotainment-right-panel {
                    flex-basis: 47%;
                }
            }
            .interior-section {
                .interior-left-panel, .interior-right-panel {
                    flex-basis: 47%;
                }
            }
            
            // .interior-comfort-section {
            //     .interior-comfort-left-panel, .interior-comfort-right-panel {
            //         flex-basis: 47%;
            //     }
            // }
            .safety-driver-section {
                .safety-driver-left-panel, .safety-driver-right-panel {
                    flex-basis: 47%;
                }
            }
            .packages-section {
                .packages-left-panel, .packages-right-panel {
                    flex-basis: 47%;
                }
            }
            
            .efficiency-section {
                .efficiency-left-panel, .efficiency-right-panel {
                    flex-basis: 47%;
                }
            }

            .battery-system-section {
                .battery-system-left-panel, .battery-system-right-panel {
                    flex-basis: 47%;
                }
            }
           
            .electric-drive-section {
                .electric-drive-left-panel, .electric-drive-right-panel {
                    flex-basis: 47%;
                }
            }

            .battery-charging-section {
                .battery-charging-left-panel, .battery-charging-right-panel {
                    flex-basis: 47%;
                }
            }
            .chassis-suspension-section {
                .chassis-suspension-left-panel, .chassis-suspension-right-panel {
                    flex-basis: 47%;
                }
            }
            
            .exterior-dimensions-section {
                flex-wrap: wrap;
                .exterior-dimensions-left-panel, .exterior-dimensions-right-panel {
                    flex-basis: 47%;
                } 
                img {
                    flex-basis: 100%;
                }
            }
            .interior-dimensions-section {
                .interior-dimensions-left-panel, .interior-dimensions-right-panel {
                    flex-basis: 47%;
                }
            }
            .weights-capacities-section {
                .weights-capacities-left-panel, .weights-capacities-right-panel {
                    flex-basis: 47%;
                }
            }
            .warranty-section {
                .warranty-left-panel, .warranty-right-panel {
                    flex-basis: 47%;
                }
            }  
        }
    }
}

.disclaimer-container {
    padding-top: 2rem; 
    padding-bottom: 2rem;    
    .disclaimer-title {
       
    }
}

.comparison-dialog {
    height: 95vh !important;
    .p-dialog-header-icons {
        padding-right: 3%;
    }
    .p-dialog-header {
        border-radius: 4px 4px 0 0;
    }
    .comaprison-scroll-container {
        overflow-y: scroll !important;
        max-height: 85vh !important;
        padding: 0 3rem;
    }
    .p-dialog-content {
        color: #282828;
        padding: 0;
        border-radius: 0 0 4px 4px;
       
        .comparison-container {
            padding: 0 10%;
            .comparison-title-box {
                width: 100%;
                padding: 0rem;
                // border-bottom: 1px solid #D2D2D2;
                .comparison-title {
                    display: flex;
                    flex-direction: row;
                    align-items: baseline;
                    justify-content: space-between;
                    width: 100%;
                    h1, p{
                        flex-basis: 47%;
                    }
                }
                h1, h5 {
                    font-family: 'CadillacGothic-Regular';
                    letter-spacing: 1px;
                    margin: 0;
                    // display: flex;
                    // align-items: center;
                    // justify-content: center;
                }
                h1 {
                    color: black;
                    font-size: 40px;
                    font-weight: 400;
                    text-transform: uppercase;
                    line-height: 48px;
                    letter-spacing: 3px;
                    word-wrap: break-word;
                    padding-bottom: 1.5rem;
                }
                h5 {
                    color: #3A3A3A;
                    font-size: 16px;
                    font-weight: 400;
                    text-transform: uppercase;
                    line-height: 24px;
                    letter-spacing: 2px;
                    padding-bottom: 1.5rem;
                }
                p {
                    color: #3A3A3A;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 22px;
                    word-wrap: break-word;
                    margin: 10px 0;
                }
            }
         
            .comparison-highlights-container {
                margin-bottom: 3rem;
                .comparison-card {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 1.5rem;
                    .image {
                        height: 120px;
                        
                    }
                    .comparison-card-desc {

                    }
                }
                p {
                    margin: 0;
                }
            }
            .comparison-highlights-header, .standard-equipment-header {
                width: 100%;
                font-size: 18px;
                border-bottom: 1px solid #D2D2D2;
                margin-bottom: 2rem;
                font-weight: bold;
                color: #282828;
            }

            .variant-selection-left-container {
                width: 300px;
            }
            .variant-selection-right-container {
                width: 300px;
            }
            .checkout-btn-panel {
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                // margin-bottom: 1.5rem;
                width: 100%;
                .preferred-btn {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    box-shadow: none;
                    background-color: transparent;
                    border: 1px solid #000;
                    color: #282828;
                    margin-top: 2rem;
                    height: 4.25rem;
                    line-height: 1.375rem;
                    min-width: 7rem;
                    cursor: pointer;
                    transition: all .25s linear;
                    min-height: 3rem;
                    max-height: 5rem;
                    // text-transform: uppercase;
                    letter-spacing: 1px;
                    font-size: 1rem;
                    font-weight: 400;
                    padding-left: 16px;
                    padding-right: 16px;
                    &:hover {
                        border: none;
                        background-color: #ddd;
                    }
                }
                .preferred-btn {
                    max-height: 3.5rem;
                }
                .customer-btn.selected, .payment-btn.selected, .preferred-btn.selected {
                    background-color: #282828;
                    border: 1px solid #282828;
                    color: #fcfcfc;
                }
                img {
                    width: 20px;
                    height: 20px;
                }
        
                .checkout-btn {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 1px solid #282828;
                    border-radius: 0%;
                    background-color: #282828;
                    color: #fff;
                    font-size: 15.6px;
                    height: 3rem;
                    &:hover {
                        background-color: #fff;
                        color: #282828;
                    }
                }
                &:last-child {
                    .checkout-btn {
                        padding: 1rem;
                        font-size: 14px;
                        line-height: 18px;
                        box-shadow: none;
                    }
                }
            }         
        }

        .standard-equipment-container {
            padding: 0 10%;
            .accordion-title {
                color: black;
                font-size: 20px;
                font-weight: 400;
                line-height: 26px;
                word-wrap: break-word;
                padding: 1.5rem 0;
            }
            li {
                color: #505050;
                padding-bottom: .2rem;
            }
            .bottom-desc {
                padding-top: 3rem;
            }
        }
    }
}

.confidence-container {
    border-radius: 4px;
    background: #202223; 
    margin-bottom: 4rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    // justify-content: space-between;
    padding: 1.5rem 2rem;
    cursor: pointer;
    span {
        max-width: 220px;
        color: white;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.50px;
        word-wrap: break-word;
        padding-left: 12px;
    }
    img {
        // width: 16px; 
        // height: 11px; 
        width: 57.05px;
        height: 21.47px;
    }
    .arrow-confidence {
        width: fit-content;
        margin-left: auto;
    }
}


.private-link-expiry-container {
    border-radius: 4px;
    background: #202223;
    margin-bottom: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: space-between;
    padding: 1.5rem 2rem;
    /*cursor: pointer;*/

    .text1 {
        color: white;
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0.50px;
        word-wrap: break-word;
        padding-left: 12px;
        padding-bottom:10px;
    }

    .text2 {
        color: white;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0.50px;
        word-wrap: break-word;
        padding-left: 12px;
    }
}

.cash-finance-desc-dialog {
    width: 70%;
}

.global-popup-dialog, .confidence-dialog, .cash-finance-desc-dialog {
    // , .comparison-dialog
    min-width: 80%;
    height: 95vh;
    position: absolute;
    bottom: 0 !important;
    margin-bottom: 0 !important;
    border-radius: 1rem !important;
    .p-dialog-header {
        padding: 2rem 2rem 1.5rem 2rem;
        border-radius: 1rem 1rem 0 0;
    }
    .confidence-inner, .cash-finance-desc-inner {
        padding: 0 4rem 4rem 4rem;
        h3 {
            color: black;
            font-size: 18px;
            font-weight: 400;
            text-transform: uppercase;
            line-height: 24px;
            letter-spacing: 2px;
            word-wrap: break-word;
            margin-bottom: 2rem;
        }
        .title-panel {
            h1 {
                color: black;
                font-size: 40px;
                font-weight: 400;
                text-transform: uppercase;
                line-height: 48px;
                letter-spacing: 3px;
                word-wrap: break-word;
                margin-bottom: 2rem;
            }
            h6, p {
                color: #3A3A3A;
                font-size: 16px;
                font-weight: 400;
                line-height: 22px;
                word-wrap: break-word;
            }
            h6 {
                margin-bottom: .5rem;
            }
            p {
                margin-bottom: 2rem;
            }
        }
        .features-panel {
            .feature-item {
                margin-bottom: 2rem;
                display: flex;
                flex-direction: row;
                align-items: center;
                span {
                    color: #060606;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 22px;
                    word-wrap: break-word;
                    margin-left: 1.5rem;
                }
                img {
                    margin-left: 1.5rem;
                    align-self: flex-start;
                }
                ul {
                    padding-top: 2rem;
                    padding-left: 20px;
                    li {
                        padding-bottom: 1rem;
                    }
                }
            }
        }

        .brand-logos-panel {
            display: flex;
            justify-content: center;
            padding-top: 3rem;
            img {
                width: 60%;
            }
        }
    }
}   

.global-popup-dialog {
    .confidence-inner {
        .title-panel {
            h1, h6 {
                margin-bottom: 3rem;
            }
        }
    }
}


@media screen and (min-width: 768px) and (max-width: 1024px) {
    .comparison-dialog {
        height: 100vh !important;
        width: 100% !important;

        .p-dialog-content {
            color: #282828;
            padding: 0 1.5rem 2rem 1.5rem;
            border: none;
            .comparison-container {
                padding: 0 3%;
                .comparison-title-box {
                   
                }
                .comparison-highlights-header, .standard-equipment-header {
                    
                }
    
                .exterior-options-panel {
                   
                    .color-options-item {
                        padding: 0 .2rem;
                    }
                }
  
            }
            
            .standard-equipment-container {
                padding: 0 3%;
            }

        }
    }

   

   
}

@media screen and (max-width: 767.98px) {
    .comparison-dialog {
        height: 100vh !important;
        width: 100% !important;
        .p-dialog-header {
            padding: 2rem 1.5rem 1.5rem 1.5rem;
        }
        .p-dialog-header-icons {
            padding-right: 3%;
        }
        .comaprison-scroll-container {
            overflow-y: scroll !important;
            max-height: 80vh !important;
        }
        .p-dialog-content {
            color: #282828;
            padding: 0 1.5rem 2rem 1.5rem;
            border: none;
            .comparison-container {
                padding: 0;
                .comparison-title-box {
                    width: 100%;
                    padding: 0rem;
                    // border-bottom: 1px solid #D2D2D2;
                    .comparison-title {
                        display: flex;
                        flex-direction: column;
                        h1, p{
                            flex-basis: 47%;
                        }
                    }
                    h1, h5 {
                        font-family: 'CadillacGothic-Regular';
                        letter-spacing: 1px;
                        margin: 0;
                        // display: flex;
                        // align-items: center;
                        // justify-content: center;
                    }
                    h1 {
                        color: black;
                        font-size: 24px;
                        font-weight: 400;
                        text-transform: uppercase;
                        line-height: 32px;
                        letter-spacing: 3px;
                        word-wrap: break-word;
                    }
                    h5 {
                        color: #282828;
                        font-size: 16px;
                        font-weight: 400;
                        text-transform: uppercase;
                        line-height: 24px;
                        letter-spacing: 2px;
                        word-wrap: break-word
                    }
                    p {
                        color: #3A3A3A;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 22px;
                        word-wrap: break-word;
                        margin: 0 0 1.5rem 0;
                    }
                }
                .comparison-img-container {
                   
                    .comparison-detail-container {
                        display: flex;
                        flex-direction: row;
                        .left-container, .right-container {
                           
                        }
                    }
                    .img-pc-container {
                        width: 100%;
                        height: 200px;
                        margin: 2rem 0 1rem 0;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        
                        
                    }
                    .img-left, .img-right {
                        height: auto;
                        width: 400px;
                        display: flex;
                        align-items: center;
                        justify-content: center;                
                        position: relative;
                        padding: 1.5rem 0;
                        .image {
                            width: 90%;
                            height: 200px;
                            object-fit: contain;
                        }
                        > img + img {
                            width: 90%;
                            height: 100%;
                            object-fit: contain;
                            cursor: pointer;
                            position: absolute;
                            // left: 0;
                            opacity: 0;
                            z-index: 0;
                            transition: opacity .75s ease-in;
                            left: 50%;
                            transform: translateX(-50%);
                        }
                
                        &.active > img + img {
                            opacity: 1;
                            transition: opacity .75s ease-out;
                            z-index: 9;
                        }
                    }
                    .img-comp-container {
                        margin: 3rem 0;
                        position: relative;
                        height: auto;
                        width: 100%;
                        .img-comp-img {
                            position: absolute;
                            width: auto;
                            height: auto;
                            overflow: hidden;
                        }
                        
                        .img-comp-img img {
                            display: block;
                            vertical-align: middle;
                            width: 600px;
                            height: 360px;
                        }
                        
                        .img-comp-slider {
                            position: absolute;
                            z-index: 9;
                            cursor: ew-resize;
                            /*set the appearance of the slider:*/
                            width: 40px;
                            height: 40px;
                            background-color: #2196F3;
                            opacity: 0.7;
                            border-radius: 50%;
                        }
                    }
    
                    .img-selection-container {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        width: 100%;
                        .img-control-panel-left, .img-control-panel-right {
                            display: flex;
                            flex-direction: column;
                            align-items: center;   
                            span {
    
                            }
                        }
                    }
    
                    .variant-selection-container {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        width: 100%;
                        
                    }
                }
                .comparison-highlights-container {
                    margin-bottom: 3rem;
                    .comparison-card {
                       
                        .image {
                            height: 120px;
                            
                        }
                        .comparison-card-desc {
    
                        }
                    }
                    p {
                        margin: 0;
                    }
                }
                .comparison-highlights-header, .standard-equipment-header {
                    
                }
    
                .exterior-options-panel {
                   
                    .color-options-item {
                        padding: 0 .2rem;
                    }
                }
    
                .variant-selection-left-container,  .variant-selection-right-container {
                   
                }
                .checkout-btn-panel {
                   
                
                    .preferred-btn {
                       
                        &:hover {
                            
                        }
                    }
                    .preferred-btn {
                        max-height: 3.5rem;
                    }
                    .customer-btn.selected, .payment-btn.selected, .preferred-btn.selected {
                       
                    }
                    img {
                        width: 20px;
                        height: 20px;
                    }
            
                    .checkout-btn {
                       
                        &:hover {
                            
                        }
                    }
                    &:last-child {
                        .checkout-btn {
                           
                        }
                    }
                }         
            }
    
            .standard-equipment-container {
                padding: 0;
                .accordion-title {
                    color: black;
                    font-size: 20px;
                    font-family: 'CadillacGothic-Regular';
                    font-weight: 400;
                    line-height: 26px;
                    word-wrap: break-word
                }
                li {
                    color: #505050;
                    padding-bottom: .2rem;
                }
                .bottom-desc {
                    padding-top: 3rem;
                }
            }
        }
    }

    .equipments-accordion {
        &:nth-child(1) {
            border-top: 1px #E9ECEF solid;
        }
        .accordion-item {
            height: fit-content;
            border-bottom: 1px #E9ECEF solid !important;
            .accordion-header {
                .accordion-button {
                    color: black;
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 26px;
                    word-wrap: break-word;
                    padding: 1.5rem 0;
                }
            }
            .accordion-body {
                padding: 0 0 2rem 0 !important;
                .differences-title {
                    margin: 0;  
                }
                .differences-desc {
                    color: #3A3A3A;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 22px;
                    word-wrap: break-word
                }
                .comparison-img-container {
                    display: flex;
                    // flex-direction: column;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    // padding-bottom: 3rem;
                    .comparison-detail-container {
                        width: 100%;
                        height: fit-content !important;
                        display: flex;
                        flex-direction: column;
                        .variant-name-price-section {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            .left-container, .right-container {
                                max-width: 47%;
                                flex-basis: 47%;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                height: fit-content;
                                .img-left, .img-right {
                                    width: 100%;
                                    img {
                                        width: 100%;
                                    }
                                }
                                .img-container {
                                    width: 100%;
                                    img {
                                        width: 100%;
                                        height: 100%;
                                    }
                                }
                            }
                            .left-detail-name-price-section, .right-detail-name-price-section {
                                flex-basis: 50%;
                                align-self: flex-start;
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                h6 {
                                    font-size: 14px;
                                    margin-bottom: 0;
                                    letter-spacing: 1.2px;
                                }
                                h4 {
                                    color: #3A3A3A;
                                    font-size: 16px;
                                    font-weight: 400;
                                    text-transform: uppercase;
                                    line-height: 24px;
                                    letter-spacing: 2px;
                                    word-wrap: break-word
                                }
                                h5 {
                                    color: #3A3A3A;
                                    font-size: 16px;
                                    font-weight: 400;
                                    line-height: 22px;
                                    word-wrap: break-word
                                }
                                p {
                                    color: #707070;
                                    font-size: 14px;
                                    font-weight: 400;
                                    line-height: 18px;
                                    letter-spacing: 0.50px;
                                    word-wrap: break-word;
                                    margin: 0;
                                    padding: .5rem 0;
                                }
                                span {
                                    font-size: 14px;
                                    color: #505050;
                                }
                            }
                        }
                        .variant-desc-section, .exterior-front-section, .exterior-side-section, .wheel-section {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            padding-top: 3rem;
                            h6 {
                                color: #707070;
                                font-size: 14px;
                                font-weight: 400;
                                line-height: 18px;
                                letter-spacing: 0.50px;
                                word-wrap: break-word;
                            }
                            p {
                                margin: 0;
                                color: #3A3A3A !important; 
                            }
                            .img-left, .img-right {
                                align-self: center;
                                padding: .5rem 0;
                            }
                        }
                        .variant-desc-section {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            padding-top: 2rem;
                            .variant-left-desc, .variant-right-desc {
                                flex-basis: 47%;
                            }
                        }
                        .exterior-front-section {
                            .exterior-front-left-panel, .exterior-front-right-panel {
                                display: flex;
                                flex-direction: column;
                                justify-content: flex-start;
                                align-items: flex-start;
                                flex-basis: 47%;
                                height: fit-content;
                                .img-left, .img-right {
                                    width: 100%;
                                    display: flex;
                                    align-items: center;
                                    img {
                                        width: 100%;
                                    }
                                }
                            }
                        }
                        .exterior-side-section {
                            .exterior-side-left-panel, .exterior-side-right-panel {
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                flex-basis: 47%;
                                height: fit-content;
                                .img-left, .img-right {
                                    width: 100%;
                                    img {
                                        width: 100%;
                                        height: 100%;
                                    }
                                }
                            }
                        }
                        .wheel-section {
                            .wheel-left-panel, .wheel-right-panel {
                                flex-basis: 47%;
                                .img-left, .img-right {
                                    width: 100%;
                                    height: fit-content;
                                }
                                img {
                                    align-self: center;
                                }
                            }
                        }
                        
                        .img-left, .img-right {
                            width: 60%;
                            padding: 1rem 0;
                            img {
                                width: 100%;
                            }
                        }
                        .mobile-car-details {
                            flex-basis: 100%;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                    .img-pc-container {
                        width: 100%;
                        height: 200px;
                        margin: 2rem 0 1rem 0;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        flex-basis: 50%;
                        
                    }
                    .img-left, .img-right {
                        height: 100%;
                        width: 400px;
                        display: flex;
                        align-items: center;
                        justify-content: center;                
                        position: relative;
                        .image {
                            width: 90%;
                            height: 200px;
                            object-fit: contain;
                        }
                        > img + img {
                            width: 90%;
                            height: 100%;
                            object-fit: contain;
                            cursor: pointer;
                            position: absolute;
                            // left: 0;
                            opacity: 0;
                            z-index: 0;
                            transition: opacity .75s ease-in;
                            left: 50%;
                            transform: translateX(-50%);
                        }
                
                        &.active > img + img {
                            opacity: 1;
                            transition: opacity .75s ease-out;
                            z-index: 9;
                        }
                    }
                    .img-comp-container {
                        margin: 3rem 0;
                        position: relative;
                        height: 360px; /*should be the same height as the images*/
                        width: 600px;
                        .img-comp-img {
                            position: absolute;
                            width: auto;
                            height: auto;
                            overflow: hidden;
                        }
                        
                        .img-comp-img img {
                            display: block;
                            vertical-align: middle;
                            width: 600px;
                            height: 360px;
                        }
                        
                        .img-comp-slider {
                            position: absolute;
                            z-index: 9;
                            cursor: ew-resize;
                            /*set the appearance of the slider:*/
                            width: 40px;
                            height: 40px;
                            background-color: #2196F3;
                            opacity: 0.7;
                            border-radius: 50%;
                        }
                    }
    
                    .img-selection-container {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        width: 100%;
                        .img-control-panel-left, .img-control-panel-right {
                            display: flex;
                            flex-direction: column;
                            align-items: center;   
                            span {
    
                            }
                        }
                    }
    
                    .variant-selection-container {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        width: 100%;
                        
                    }
                }
                
                .exterior-options-panel {
                    // width: 90%;
                    width: 100% !important;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    margin-bottom: 1rem;
                    .color-options-item {
                        padding: 0 .2rem .5rem 0;
                        flex-basis: 20%;
                    }
                    .p-dropdown {
                        width: 70%;
                        height: 2rem;
                        padding: 0;
                        margin: 0 auto;
                        .p-inputtext {
                            padding: 0;
                            .p-dropdown-item {
                                width: 100%;
                                img {
                                   width: 100% !important;
                                   margin: 0 !important;
                                }
                            }
                        }
                       
                    }
                }
                .comparison-highlights-container {
                    margin-bottom: 3rem;
                    .comparison-card {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        margin-bottom: 1.5rem;
                        .image {
                            height: 120px;
                            
                        }
                        .comparison-card-desc {
    
                        }
                    }
                    p {
                        margin: 0;
                    }
                }
    
                .parameter-item {
                    padding-bottom: 2rem;
                    flex: 1;
                    h6 {
                        margin-bottom: .5rem;
                        color: #707070;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 18px;
                        letter-spacing: 0.50px;
                        word-wrap: break-word
                    }
                    p {
                        margin-bottom: .5rem;
                        color: #3A3A3A;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 22px;
                        word-wrap: break-word
                    }
                }
                .parameter-item.last-line {
                    padding-bottom: 0rem;
                }
    
                .drivetrain-section, .chassis-section, .exterior-section, .infotainment-section, .interior-section, .safety-driver-section, 
                .packages-section, .efficiency-section, .battery-system-section, .electric-drive-section, .battery-charging-section, .chassis-suspension-section,
                .exterior-dimensions-section, .interior-dimensions-section, .weights-capacities-section, .warranty-section {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    p {
                        sup {
                            font-size: 9px !important;
                        }
                    }
                    
                }
                
                .drivetrain-section {
                    .drivetrain-left-panel, .drivetrain-right-panel {
                        flex-basis: 100%;
                    }
                }
    
                .chassis-section {
                    .chassis-left-panel, .chassis-right-panel {
                        flex-basis: 100%;
                    }
                }
              
                .exterior-section {
                    .exterior-left-panel, .exterior-right-panel {
                        flex-basis: 100%;
                    }
                }
    
                .infotainment-section {
                    .infotainment-left-panel, .infotainment-right-panel {
                        flex-basis: 100%;
                    }
                }
                .interior-section {
                    .interior-left-panel, .interior-right-panel {
                        flex-basis: 100%;
                    }
                }
                
                // .interior-comfort-section {
                //     .interior-comfort-left-panel, .interior-comfort-right-panel {
                //         flex-basis: 47%;
                //     }
                // }
                .safety-driver-section {
                    .safety-driver-left-panel, .safety-driver-right-panel {
                        flex-basis: 100%;
                    }
                }
                .packages-section {
                    .packages-left-panel, .packages-right-panel {
                        flex-basis: 100%;
                    }
                }
                
                .efficiency-section {
                    .efficiency-left-panel, .efficiency-right-panel {
                        flex-basis: 100%;
                    }
                }
    
                .battery-system-section {
                    .battery-system-left-panel, .battery-system-right-panel {
                        flex-basis: 100%;
                    }
                }
               
                .electric-drive-section {
                    .electric-drive-left-panel, .electric-drive-right-panel {
                        flex-basis: 100%;
                    }
                }
    
                .battery-charging-section {
                    .battery-charging-left-panel, .battery-charging-right-panel {
                        flex-basis: 100%;
                    }
                }
                .chassis-suspension-section {
                    .chassis-suspension-left-panel, .chassis-suspension-right-panel {
                        flex-basis: 100%;
                    }
                }
                
                .exterior-dimensions-section {
                    flex-wrap: wrap;
                    .exterior-dimensions-left-panel, .exterior-dimensions-right-panel {
                        flex-basis: 100%;
                    } 
                    img {
                        flex-basis: 100%;
                    }
                }
                .interior-dimensions-section {
                    .interior-dimensions-left-panel, .interior-dimensions-right-panel {
                        flex-basis: 100%;
                    }
                }
                .weights-capacities-section {
                    .weights-capacities-left-panel, .weights-capacities-right-panel {
                        flex-basis: 100%;
                    }
                }
                .warranty-section {
                    .warranty-left-panel, .warranty-right-panel {
                        flex-basis: 100%;
                    }
                }  
            }
        }
    }

    .cash-finance-desc-dialog {
        width: 100%;
        height: 100vh;
        .p-dialog-header {
            border-radius: 0;
        }
        .p-dialog-content {
            .cash-finance-desc-inner {
                padding: 0;
            }
            .brand-logos-panel {
                padding-bottom: 3rem;
                img {
                    width: 80%;
                }
            }
        }
    }
}




