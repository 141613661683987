.global-select-vehicle {
    position: fixed;
    top: calc(106px - 30vw);
    width: 100%;
    // height: 100vh;
    height: 30vw;
    // height: fit-content;
    z-index: 999;
    transition: all .4s ease-in-out;
    background-color: #F2F2F2;
}
.global-select-vehicle.active {
    width: 100%;
    transform: translateY(100%)
}

.global-select-vehicle-container {
    // height: calc(100vh - 106px);
    height: calc(50vh - 106px);
    width: 100% !important;
    height: 100%;
    overflow-y: hidden;
    // overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
    .global-select-header-bg-box {
        background: linear-gradient(to right, #3070C8, #B8B9EC);
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        h6 {
            color: #FCFCFC;
            font-size: 22px;
            font-weight: 400;
            font-family: 'CadillacGothic-WideRegular';
            line-height: 32px;
            letter-spacing: 3.85px;
            word-wrap: break-word;
            margin: 0;
            height: 32px;
        }
    }

    .global-vehicles-inner-container {
        position: absolute;
        top: 70px;
        left: 0;
    }
    .global-vehicles-left-sidebar-container {
        padding-top: 2rem;
        .current-vehicles-options-card {
            
        }
        .vehicle-type-category {
            margin-left: 1.25rem;
            cursor: pointer;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            .left-blue-bar {
                width: 2px;
                height: 28px;
                background-color: #171473;
                opacity: 0;
                transition: all 0.4s ease-in-out; 
            }

            h6 {
                color: #171473;
                font-size: 18px;
                font-weight: 400;
                line-height: 28px;
                letter-spacing: 3.50px;
                word-wrap: break-word;
                transition: transform 0.4s ease-in-out;
            }
            &:hover {
                .left-blue-bar {
                    transform: translateX(-0.5rem);
                    opacity: 1;
                }
                h6 {
                    transform: translateX(.6rem);
                }
            }
        }
        .vehicle-type-category.selected {
            .left-blue-bar {
                transform: translateX(-0.5rem);
                opacity: 1;
            }
            h6 {
                transform: translateX(.6rem);
            }
        }
        .other-options-panel {
            padding: 1.875rem 0 2.5rem 1.25rem;
            .other-options-item {
                cursor: pointer;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;

                .left-blue-bar {
                    width: 2px;
                    height: 27px;
                    background-color: #0D0D0D;
                    opacity: 0;
                    transition: all 0.4s ease-in-out; 
                }

                h6 {
                    color: #0D0D0D;
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 27px;
                    letter-spacing: 0.65px;
                    word-wrap: break-word;
                    transition: transform 0.4s ease-in-out;
                }

                &:hover {
                    .left-blue-bar {
                        transform: translateX(-0.5rem);
                        opacity: 1;
                    }
                    h6 {
                        transform: translateX(.6rem);
                    }
                }
            }
        }
    }

    .global-vehicles-selection-right-grid-container {
        padding-top: 2rem;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .global-vehicle-card {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-bottom: 2rem;
            cursor: pointer;
            .vehicle-img-box {
                width: 100%;
                position: relative;
                display: flex;
                justify-content: center;
                img {
                    width: 120%;
                    // transform: translateX(-10%);
                }
            }
            p {
                font-family: 'CadillacGothic-Regular' !important;
            }
            .vehicle-brand, .vehicle-price, .vehicle-bottom-status {
                h6 {
                    font-family: 'CadillacGothic-Regular' !important;
                    margin: 0;
                }
            }
            .vehicle-brand {
                margin: 0;
                color: #282828;
                font-size: 12px;
                font-weight: 400;
                line-height: 22px;
                letter-spacing: 0.60px;
                word-wrap: break-word;
                
            }
            .vehicle-price {
                margin-bottom: calc(.625* 16px) !important;
                color: #282828;
                font-size: .75rem;
                letter-spacing: .6px;
                line-height: 1.825;
            }
            .vehicle-card-divider {
                width: 70%;
                height: 1px; 
                border-top: 2px solid #A0A0A0;
                margin-top: .5rem;
            }
            .vehicle-bottom-status {
                width: 100%;
                padding: 0 1rem;
                .vehicle-status-box {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 26px;
                    height: 48px;
                    text-decoration: none;
                    h6 {
                        color: #282828;
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 22px;
                        letter-spacing: 1.60px;
                        word-wrap: break-word;
                        margin: 0;
                    }
                }
            }
        }
    }

    .close-panel-btn-container {
        margin-top: auto;
        margin-bottom: 0;
        display: flex;
        justify-content: center;
        .close-panel-btn {
            background-color: transparent;
            border-color: transparent;
            box-shadow: none;
            h6 {
                text-decoration: underline;
                color: #282828;
                font-size: 15px;
                font-weight: 400;
                text-decoration: underline;
                line-height: 27px;
                letter-spacing: 0.65px;
                word-wrap: break-word
            }
        }
    }
}


.global-shop {
    position: fixed;
    // width: calc(100% - 15px);
    width: 100%;
    height: fit-content;
    z-index: 999;
    transition: all .4s ease-in-out;
    background-color: #F2F2F2;
}
.global-shop.active {
    transform: translateY(100%)
}

.global-shop-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 3rem;
    .shop-left-selection-panel, .shop-right-selection-panel {
        flex-basis: 50%;
        .shop-select-title {
            color: #282828;
            font-size: 14px;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: 1.60px;
            word-wrap: break-word;
            padding-bottom: 1.6rem;
            h6 {
                margin: 0;
            }
        }
        .shop-select-item {
            cursor: pointer;
            display: flex;
            flex-direction: row;
            align-items: center;
            flex-wrap: nowrap;
            padding-bottom: 1.5rem;
            .left-blue-bar {
                width: 2px;
                background-color: #282828;
                opacity: 0;
                transition: all 0.4s ease-in-out; 
            }
            h6 {
                color: #282828;
                font-weight: 400;
                letter-spacing: 3.50px;
                word-wrap: break-word;
                transition: transform 0.4s ease-in-out;
                margin: 0;
            }
            &:hover {
                .left-blue-bar {
                    transform: translateX(-0.5rem);
                    opacity: 1;
                }
                h6 {
                    transform: translateX(.6rem);
                }
            }
        }
    }
    .shop-left-selection-panel {
        .shop-select-item {
            line-height: 28px;
            .left-blue-bar {
                height: 28px;
            }
            h6 {
                font-size: 18px;
            }
        }
    }
    .shop-right-selection-panel {
        .shop-select-item {
            line-height: 22px;
            .left-blue-bar {
                height: 22px;
            }
            h6 {
                color: #282828;
                font-size: 15px;
                font-weight: 400;
                line-height: 27px;
                letter-spacing: 0.65px;
                word-wrap: break-word
            }
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {

    .global-select-vehicle {
        height: calc(50vh - 140px);
        top: calc(280px - 50vh);
    }

    .global-vehicle-card {
        .vehicle-bottom-status {
            padding: 0 !important;
            .vehicle-status-box {
                padding: .5rem !important;
            }
        }
    }

    .global-select-vehicle-container {
        // height: calc(100vh - 106px);
        height: calc(50vh - 140px);
        .global-select-header-bg-box {
            height: 50px;
        }
        .global-vehicles-inner-container {
            position: absolute;
            top: 50px;
            left: 0;
        }
        .global-select-header-bg-box {
            h6 {
                font-size: 18px;
            }
        }
    }
}

@media screen and (max-width: 767.98px) {

    .global-select-vehicle {
        position: fixed;
        top: calc(106px - 50vh);
        height: 50vh;
    }

    .global-select-vehicle-container {

        .global-vehicles-inner-container {
            position: relative;
            top: 0;
        }
    }

	.global-select-vehicle {
        width: 100% !important;
    }

    .global-shop {
        width: 100% !important;
        height: fit-content;
    }

    .global-shop-container {
        flex-direction: column;
        height: auto !important;
        .shop-right-selection-panel {
            .shop-select-title {
                padding-top: 2rem;
            }
            .shop-select-item {
                &:last-child {
                    padding-bottom: 0;
                }
            }
        }
    }

    .global-vehicles-left-sidebar-container {
        .current-vehicles-options-card {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 1rem;
            .vehicle-type-category {
                h6 {
                    margin: 0 ;
                }
            }
            .other-options-panel {
                padding-top: 1.25rem !important;
            }
            img {
                margin-right: 1.25rem;
            }
        }
        
    }

    .row {
        position: relative;
    }

    .global-vehicles-selection-right-grid-container {
        position: absolute;
        top: 0;
        right: -100%;
        padding-top: 0rem !important;
        background: #F2F2F2;
        // min-height: calc(100vh - 106px);
        transition: right .4s ease-in-out;
        .global-vehicle-card {
            padding: 8% 12%;
            margin: 0 !important;
        }
        .mobile-back-to-panel {
            padding: 1rem;
            background-color: #fff;
            border-top: 1px solid #A0A0A0;
            font-size: 18px;
            width: 100%;
            display: flex;
            align-content: center;
            font-family: "CadillacGothic-Regular";
            max-height: 3.5rem !important;
            .arrow-icon {
                rotate: 180deg;
                margin-right: 1rem;
                width: 28px;
                height: 28px;
            }
        }
       
    }

    .global-vehicles-selection-right-grid-container.active {
        right: 0;
        display: block;
    }
}